import { format, parse } from 'date-fns';
import { formatMoney3 } from '../../../../../../utils/validators';
import { getVSMFormat } from '../../../../utils';

export const CODE_SUCCESS = '0000';

export enum TYPE_MONEY {
	VSM = 'VSM',
	PESOS = 'PESOS',
}

export const OPTIONS_ORIGINS = [
	{ value: '1', label: 'Mes actual' },
	{ value: '2', label: 'Mes anterior' },
	{ value: '3', label: 'Tres meses' },
];

export const OPTIONS_TYPES = [
	{ value: TYPE_MONEY.PESOS, label: 'PESOS' },
	{ value: TYPE_MONEY.VSM, label: 'VSM' },
];

export const OPTIONS_MOVEMENTS = [
	{ value: 'pagos', label: 'Pagos' },
	{ value: 'abonos', label: 'Otros abonos y beneficios' },
	{ value: 'pagos-parciales', label: 'Pagos Parciales' },
];

export type OrderFilter = 'asc' | 'desc';

export const descendingComparator = <T>(
	a: T,
	b: T,
	orderBy: keyof T
): number => {
	if (b[orderBy] < a[orderBy]) return -1;
	if (b[orderBy] > a[orderBy]) return 1;
	return 0;
};

export const getComparator = <T>(
	order: OrderFilter,
	orderBy: keyof T
): ((a: T, b: T) => number) => {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
};

export const getSortData = <T>(
	array: T[],
	comparator: (a: T, b: T) => number
): T[] => {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
};

export const getPesoOrVSM = (value?: string, tipoMoneda?: string) => {
	try {
		const isVSM = tipoMoneda === TYPE_MONEY.VSM;

		if (isVSM) {
			return getVSMFormat(value || '0');
		}
		return formatMoney3(value || '0');
	} catch (error) {
		return '0';
	}
};

export const getDateFormat = (value?: string) => {
	try {
		if (value) {
			return format(parse(value, 'yyyyMMdd', new Date()), 'dd/MM/yyyy');
		}
		return '';
	} catch (error) {
		return value;
	}
};
