import { Divider } from '@mui/material';
import theme from '../../../../config/theme';
import { formatMoney3 } from '../../../../utils/validators';
import { useStyles } from './styles';

interface ILabelCardBalanceValues {
	mxnValue: number;
	vsmValue?: number | null;
	variant: 'primary' | 'info' | 'secondary';
}
export const LabelCardBalanceValues = ({
	mxnValue,
	vsmValue,
	variant,
}: ILabelCardBalanceValues) => {
	const classes = useStyles();
	return (
		<div className={classes.container}>
			{vsmValue && (
				<>
					<div>
						<label
							style={{ color: theme.palette[variant].main }}
							className={classes.labelCount}
						>
							{vsmValue.toFixed(3)}
						</label>
						<label className={classes.labelCountMXN}> VSM</label>
					</div>
					<Divider
						orientation="vertical"
						flexItem
						className={classes.divider}
					/>
				</>
			)}
			<div>
				<label
					style={{ color: theme.palette[variant].main }}
					className={classes.labelCount}
				>
					{formatMoney3(String(mxnValue))}
				</label>
				<label className={classes.labelCountMXN}> MXN</label>
			</div>
		</div>
	);
};
