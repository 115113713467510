import { Paper } from '@mui/material';
import { useStyles } from './styles';
import { useState } from 'react';
import { MOVEMENTS_ACCOUNT_PAGE } from '../../utils';
import StatusAccount from './components/StatusAccount';
import MomentsAccount from './components/MomentsAccount';

const MovementsAccount = () => {
	const [view, setView] = useState(MOVEMENTS_ACCOUNT_PAGE.MOVEMENTS);
	const styles = useStyles({ view });

	return (
		<Paper>
			<div className={styles.containerMain}>
				<div className={styles.containerButton}>
					<span
						className={styles.spanButtonL}
						onClick={() =>
							setView(MOVEMENTS_ACCOUNT_PAGE.MOVEMENTS)
						}
					>
						Movimientos
					</span>
					<span
						className={styles.spanButtonR}
						onClick={() =>
							setView(MOVEMENTS_ACCOUNT_PAGE.STATUS_ACCOUNT)
						}
					>
						Estado de Cuenta
					</span>
				</div>

				{view === MOVEMENTS_ACCOUNT_PAGE.MOVEMENTS && (
					<MomentsAccount />
				)}
				{view === MOVEMENTS_ACCOUNT_PAGE.STATUS_ACCOUNT && (
					<StatusAccount />
				)}
			</div>
		</Paper>
	);
};

export default MovementsAccount;
