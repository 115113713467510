import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
	containerMain: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'center',
		marginBottom: 10,

		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
            marginBottom: 30,
		},
	},
	iconCheck: {
		width: 20,
		height: 20,
		color: '#00C951',
		marginLeft: 10,
	},
	containerText: {
		display: 'flex',
		flexDirection: 'column',

		'& > h1': {
			margin: 0,
			color: '#293990',
		},
		'& > h3': {
			margin: 0,
			fontWeight: 400,
		},
	},
	linkText: {
		marginTop: '10px !important',
		color: '#293990 !important',
		fontWeight: 500,
		display: 'flex !important',
		alignItems: 'center !important',
	},
	iconArrow: {
		width: '16px !important',
		height: '16px !important',
	},
	iconoFamilia: {
		marginRight: 20,

		'& > img': {
			width: '100%',
		},
	},
}));
