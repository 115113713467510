import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		display: 'flex',
		justifyContent: 'center',
	},
	labelCount: {
		fontSize: 22,
		fontWeight: '500',
	},
	labelCountMXN: {
		fontSize: 18,
		color: theme.palette.grey[600],
	},
	divider: {
		margin: '0px 10px !important'
	},
}));
