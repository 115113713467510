import CustomAlert from '../../../../../components/CustomAlert';
import { useContextMoment } from '../../../context';
import { CODES } from '../../../utils';

const MessageComponent = () => {
	const { balance } = useContextMoment();
	return (
		<>
			{[
				CODES.CASOS_ESPECIALES_PRORROGAS_ESPECIALES_3_4_7_8,
				CODES.CASOS_ESPECIALES_RENEWAL_NO_DEMANDA,
			].includes(balance.propiedades?.tipoCaso) && (
				<CustomAlert
					message={
						balance.propiedades.mensajes.find(
							(f) => f.key === '05' || f.key === '06'
						)?.value || ''
					}
					show
					severity="warning"
				/>
			)}

			{[
				CODES.RECUPERACION_DEMANDADO_SIN_CONVENIO_JUDICIAL,
				CODES.RECUPERACION_SENTENCIA,
			].includes(balance.propiedades?.tipoCaso) && (
				<CustomAlert
					message={
						balance.propiedades.mensajes
							.find((f) => f.key === '07')
							?.value.replace(
								'%s',
								balance.montoEsperado?.despachoCobranza || ''
							) || ''
					}
					show
					severity="warning"
				/>
			)}
		</>
	);
};

export default MessageComponent;
