import API from '../';

export const authAPI = new API('/login');

export const validateNss = new API('/login-confirmacion');

class LoginRequestAPI extends API {
	logout(data: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: '/logout',
				method: 'POST',
				data: { data: data },
			})
		);
	}

	tokenRefresh(): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: '/token-refresh-v2',
				method: 'POST',
				data: {},
			})
		);
	}
}

export const loginAPI = new LoginRequestAPI('');
