import { useContextMoment } from '../../context';
import { CODES } from '../../utils';
import CasosEspecialesNpEfDa from './components/CasosEspecialesNpEfDa';
import CasosEspecialesProrrogas from './components/CasosEspecialesProrrogas';
import CasosEspecialesRenewal from './components/CasosEspecialesRenewal';
import LiquidadosOtros from './components/LiquidadosOtros';
import LiquidadosPorPagos from './components/LiquidadosPorPagos';
import RecuperacionDemandadoSinConvenio from './components/RecuperacionDemandadoSinConvenio';
import RecuperacionSentencia from './components/RecuperacionSentencia';

interface IProps {
	pageShow?: Number;
}

const SolitaryModule = ({ pageShow }: IProps) => {
	const { balance } = useContextMoment();

	return (
		<>
			{balance.propiedades?.tipoCaso === CODES.LIQUIDADOS_POR_PAGO && (
				<LiquidadosPorPagos pageShow={pageShow} />
			)}

			{balance.propiedades?.tipoCaso === CODES.LIQUIDADOS_OTROS && (
				<LiquidadosOtros />
			)}

			{balance.propiedades?.tipoCaso ===
				CODES.CASOS_ESPECIALES_NP_EF_DA && <CasosEspecialesNpEfDa />}

			{balance.propiedades?.tipoCaso ===
				CODES.CASOS_ESPECIALES_PRORROGAS_ESPECIALES_3_4_7_8 && (
				<CasosEspecialesProrrogas />
			)}

			{balance.propiedades?.tipoCaso ===
				CODES.CASOS_ESPECIALES_RENEWAL_NO_DEMANDA && (
				<CasosEspecialesRenewal />
			)}

			{balance.propiedades?.tipoCaso ===
				CODES.RECUPERACION_DEMANDADO_SIN_CONVENIO_JUDICIAL && (
				<RecuperacionDemandadoSinConvenio />
			)}
			
			{balance.propiedades?.tipoCaso === CODES.RECUPERACION_SENTENCIA && (
				<RecuperacionSentencia pageShow={pageShow} />
			)}
		</>
	);
};

export default SolitaryModule;
