import { useEffect, useState } from 'react';
import { Grid, Paper } from '@mui/material';
import { useStyles } from './styles/ihave-credit.styles';
import MiniMediaCard from './mini-media-card.component';
import useTallerMas from '../hooks/useElements';
import { getResponse, WORKSHOP_VIEWS } from '../utils';
import CardViewContent from './cardViewContent';
import { Elemento } from '../../../../../interfaces/tallerSaberMas';
import CarouselCustom from './CarouselCustom';
import Carousel from 'react-multi-carousel';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import RenderHTML from '../../../../../components/RenderHTML';

export default function IHaveAcredit() {
	const styles = useStyles();
	const { elementos, matches } = useTallerMas();
	const I_HAVE_A_CREDIT = elementos[WORKSHOP_VIEWS.I_HAVE_A_CREDIT.code];
	const [elemento, setElemento] = useState<Elemento>({} as Elemento);

	const elementosJSX =
		I_HAVE_A_CREDIT?.secciones[0]?.elementos.map((el, k) => (
			<MiniMediaCard
				variant="orange"
				variantLabel={el.estatus === 0 ? 'pending' : 'completed'}
				label={el.nombre}
				onClick={() => setElemento(el)}
				isPlaying={el.idElemento === elemento.idElemento}
				isComplete={el.estatus === 1}
				key={k}
			/>
		)) || [];

	useEffect(() => {
		if (
			I_HAVE_A_CREDIT &&
			I_HAVE_A_CREDIT.secciones[0].elementos.length > 0
		) {
			setElemento(I_HAVE_A_CREDIT.secciones[0].elementos[0]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Paper elevation={2} className={styles.container}>
			<Grid container columns={16} spacing={2}>
				<Grid item md={16}>
					<div className={styles.containerHeader}>
						<label className={styles.labelHeaderTitle}>
							Conoce los diferentes apoyos que tenemos para ti,
							para que mantengas al corriente el pago de tu
							crédito.
						</label>
					</div>
				</Grid>
				<Grid item sm={16} md={10} lg={10}>
					<CardViewContent
						elemento={elemento}
						setElemento={setElemento}
					/>
					<RenderHTML
						className={styles.labelLegend}
						htmlContent={elemento?.descripcion}
					/>
				</Grid>
				<Grid item sm={16} md={6} lg={6}>
					{!matches && <CarouselCustom items={elementosJSX} />}
				</Grid>
			</Grid>
			{matches && (
				<Carousel
					customLeftArrow={
						<div className={styles.arrowVideoL}>
							<div>
								<ChevronLeftIcon />
							</div>
						</div>
					}
					customRightArrow={
						<div className={styles.arrowVideoR}>
							<div>
								<ChevronRightIcon />
							</div>
						</div>
					}
					responsive={getResponse(
						I_HAVE_A_CREDIT?.secciones[0]?.elementos.length
					)}
				>
					{elementosJSX.map((el) => el)}
				</Carousel>
			)}
		</Paper>
	);
}
