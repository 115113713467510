import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		gap: 2,
	},
	containerItem: {
		display: 'flex',
		gap: 2,
		margin: '10px 0px',
		alignItems: 'baseline',
	},
	containerSubItem: {
		display: 'flex',
		flexDirection: 'column',
		gap: 2,

		'& > h3': {
			margin: 0,
		},
		'& > p': {
			display: 'flex',
			alignItems: 'center',
			margin: 0,
			color: '#283990',
		},
	},
	containerButtons: {
		display: 'flex',
		flexDirection: 'column',
		gap: 2,
        margin: '20px'
	},
	button: {
		width: 250,

		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
    select: {
		width: 600,
		display: 'block',
		margin: '0px 0px 20px 0px',

		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
}));
