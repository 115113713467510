import { InputAdornment, Link, Radio } from '@mui/material';
import CustomButton from '../../../../../components/Button';
import ModalOptionsPay from '../../../components/ModalOptionsPay';
import HeaderTotalMoney from './HeaderTotalMoney';
import { BALANCES_MAIN_PAGE } from '../../../utils';
import { PAYMENT_TYPES, PAYMENTS } from '../../../utils/PaymentTypes';
import TextInput from '../../../../../components/TextInput';
import { usePayMyCredit } from '../hook/usePayMyCredit';
import { useStyles } from '../styles';
import CodiIcon from '../../../../../assets/img/codi-icon.png';
import MasterCard from '../../../../../assets/img/master_card.png';
import VisaCard from '../../../../../assets/img/visa_card.png';
import { PAGE_PAY_CREDIT } from '../utils';
import MessagePayment from './MessagePayment';
import { formatMoney3 } from '../../../../../utils/validators';

const PayCreditForm = () => {
	const {
		valuePayment,
		valuePaymentType,
		openPanel,
		balance,
		valuePaymentOther,
		handleChangeValuePayment,
		handleChangeValuePaymentType,
		setOpenPanel,
		setPageMain,
		setValuePaymentOther,
		setPagePay,
		getValidButton,
	} = usePayMyCredit();
	const classes = useStyles();
	return (
		<>
			<HeaderTotalMoney />
			<h3 className={classes.labelTitle}>Elige una de las opciones</h3>
			<div className={classes.containerRadios}>
				{/* pagoCorriente */}
				{Number(balance?.pagos?.opciones?.pagoCorriente || '0') > 0 && (
					<div className={classes.subContainerRadios}>
						<div className={classes.subContainerRadiosInfo}>
							<Radio
								checked={
									valuePayment ===
									PAYMENTS.TO_BE_CURRENT.value
								}
								onChange={handleChangeValuePayment}
								value={PAYMENTS.TO_BE_CURRENT.value}
								id={`radio-option-${PAYMENTS.TO_BE_CURRENT.value}`}
							/>
							<label
								htmlFor={`radio-option-${PAYMENTS.TO_BE_CURRENT.value}`}
								className={classes.labelRadio}
							>
								{PAYMENTS.TO_BE_CURRENT.label}
							</label>
						</div>
						<div className={classes.subContainerRadiosInfo}>
							<label
								htmlFor={`radio-option-${PAYMENTS.TO_BE_CURRENT.value}`}
								className={classes.labelRadioQTY}
							>
								{formatMoney3(
									balance.pagos?.opciones.pagoCorriente || '0'
								)}
							</label>
						</div>
					</div>
				)}

				{/* pagoMontoEsperado */}
				{Number(balance?.pagos?.opciones?.pagoMontoEsperado || '0') >
					0 && (
					<div className={classes.subContainerRadios}>
						<div className={classes.subContainerRadiosInfo}>
							<Radio
								checked={
									valuePayment ===
									PAYMENTS.EXPECTED_AMOUNT.value
								}
								onChange={handleChangeValuePayment}
								value={PAYMENTS.EXPECTED_AMOUNT.value}
								id={`radio-option-${PAYMENTS.EXPECTED_AMOUNT.value}`}
							/>
							<label
								htmlFor={`radio-option-${PAYMENTS.EXPECTED_AMOUNT.value}`}
								className={classes.labelRadio}
							>
								{PAYMENTS.EXPECTED_AMOUNT.label}
							</label>
						</div>
						<div className={classes.subContainerRadiosInfo}>
							<label
								htmlFor={`radio-option-${PAYMENTS.EXPECTED_AMOUNT.value}`}
								className={classes.labelRadioQTY}
							>
								{formatMoney3(
									balance.pagos?.opciones.pagoMontoEsperado ||
										'0'
								)}
							</label>
						</div>
					</div>
				)}

				{/* pagoMontoMensualidadesVencidas */}
				{Number(
					balance?.pagos?.opciones?.pagoMontoMensualidadesVencidas ||
						'0'
				) > 0 && (
					<>
						<div className={classes.subContainerRadios}>
							<div className={classes.subContainerRadiosInfo}>
								<Radio
									checked={
										valuePayment ===
										PAYMENTS.OVERDUE_MONTHLY_INSTALLMENTS
											.value
									}
									onChange={handleChangeValuePayment}
									value={
										PAYMENTS.OVERDUE_MONTHLY_INSTALLMENTS
											.value
									}
									id={`radio-option-${PAYMENTS.OVERDUE_MONTHLY_INSTALLMENTS.value}`}
								/>
								<label
									htmlFor={`radio-option-${PAYMENTS.OVERDUE_MONTHLY_INSTALLMENTS.value}`}
									className={classes.labelRadio}
								>
									{
										PAYMENTS.OVERDUE_MONTHLY_INSTALLMENTS
											.label
									}
								</label>
							</div>
							<div className={classes.subContainerRadiosInfo}>
								<label
									htmlFor={`radio-option-${PAYMENTS.OVERDUE_MONTHLY_INSTALLMENTS.value}`}
									className={classes.labelRadioQTY}
								>
									{formatMoney3(
										balance.pagos?.opciones
											.pagoMontoMensualidadesVencidas ||
											'0'
									)}
								</label>
							</div>
						</div>
					</>
				)}

				{/* Otros pagos */}
				<div className={classes.subContainerRadios}>
					<div className={classes.subContainerRadiosInfo}>
						<Radio
							checked={valuePayment === PAYMENTS.ANOTHER.value}
							onChange={handleChangeValuePayment}
							value={PAYMENTS.ANOTHER.value}
							id={`radio-option-${PAYMENTS.ANOTHER.value}`}
						/>
						<label
							htmlFor={`radio-option-${PAYMENTS.ANOTHER.value}`}
							className={classes.labelRadio}
						>
							{PAYMENTS.ANOTHER.label}
						</label>
					</div>
					<div className={classes.subContainerRadiosInfo}>
						<TextInput
							id="other-value-input"
							name="other-value"
							value={valuePaymentOther}
							onChange={({ target }) =>
								!isNaN(Number(target.value)) &&
								setValuePaymentOther(target.value)
							}
							disabled={valuePayment !== PAYMENTS.ANOTHER.value}
							placeholder="0.00"
							onBlur={() => {
								if (valuePaymentOther) {
									if (valuePaymentOther === '.') {
										setValuePaymentOther('');
									} else {
										setValuePaymentOther(
											formatMoney3(
												valuePaymentOther
											).substring(1)
										);
									}
								}
							}}
							onFocus={() => {
								if (valuePaymentOther) {
									setValuePaymentOther(
										valuePaymentOther
											.toString()
											.replaceAll(',', '')
											.replaceAll(' ', '')
									);
								}
							}}
							startAdornment={
								<InputAdornment position="start">
									$
								</InputAdornment>
							}
						/>
					</div>
				</div>
			</div>
			<div className={classes.containerLink}>
				<Link
					className={classes.linkToPlaces}
					onClick={() => setOpenPanel(true)}
				>
					Consulta en qué lugares puedes pagar
				</Link>
			</div>
			<div className={classes.divider} />
			<h3 className={classes.labelTitle}>Formas de pago</h3>
			<div className={classes.containerRadios}>
				<div className={classes.subContainerRadiosPayments}>
					<div className={classes.subContainerRadiosInfo}>
						<Radio
							checked={
								valuePaymentType === PAYMENT_TYPES.CODI.value
							}
							onChange={handleChangeValuePaymentType}
							value={PAYMENT_TYPES.CODI.value}
							id={`radio-type-${PAYMENT_TYPES.CODI.value}`}
						/>
						<label
							htmlFor={`radio-type-${PAYMENT_TYPES.CODI.value}`}
							className={classes.labelRadio}
						>
							{PAYMENT_TYPES.CODI.label}
						</label>
					</div>
					<div className={classes.subContainerRadiosInfo}>
						<img width={40} src={CodiIcon} alt="codi-logo" />
					</div>
				</div>
				<div className={classes.subContainerRadiosPayments}>
					<div className={classes.subContainerRadiosInfo}>
						<Radio
							checked={
								valuePaymentType === PAYMENT_TYPES.CARD.value
							}
							onChange={handleChangeValuePaymentType}
							value={PAYMENT_TYPES.CARD.value}
							id={`radio-type-${PAYMENT_TYPES.CARD.value}`}
						/>
						<label
							htmlFor={`radio-type-${PAYMENT_TYPES.CARD.value}`}
							className={classes.labelRadio}
						>
							{PAYMENT_TYPES.CARD.label}
						</label>
					</div>
					<div className={classes.subContainerRadiosInfo}>
						<img
							width={45}
							className={classes.imgVisa}
							src={VisaCard}
							alt="visa-card-logo"
						/>
						<img
							width={45}
							src={MasterCard}
							alt="master-card-logo"
						/>
					</div>
				</div>
			</div>
			<div className={classes.containerRadios}>
				<MessagePayment />
			</div>
			<div className={classes.containerRadios}>
				<div className={classes.subContainerButtons}>
					<CustomButton
						styles={{
							width: 220,
							marginRight: 5,
						}}
						variant="outlined"
						label="Regresar"
						onClick={() => setPageMain(BALANCES_MAIN_PAGE.CREDIT)}
					/>
					<CustomButton
						styles={{
							width: 220,
						}}
						label="Continuar"
						disabled={getValidButton()}
						onClick={() =>
							setPagePay(PAGE_PAY_CREDIT.CONFIRM_PAYMENT)
						}
					/>
				</div>
			</div>
			<div className={classes.containerRadios}>
				<div className={classes.subContainerFooter}>
					<p className={classes.labelFooter}>
						Tienes 90 días naturales para aclarar pagos, Si tienes
						dudas, llámanos al 559 171 5050 en la Ciudad de México o
						al 800 008 3900 desde el interior de país.
					</p>
				</div>
			</div>

			<ModalOptionsPay
				openModal={openPanel}
				setOpenModal={setOpenPanel}
			/>
		</>
	);
};

export default PayCreditForm;
