import { Box } from '@mui/material';
import CustomButton from '../../../../../../../components/Button';
import { CardBalance } from '../../../../../components/CardBalance';
import { LabelCardBalance } from '../../../../../components/LabelCardBalance';
import { SectionBalance } from '../../../../../components/SectionBalance';
import { useContextMoment } from '../../../../../context';
import { BALANCES_MAIN_PAGE } from '../../../../../utils';

const RecuperacionSentencia = () => {
	const { balance, setPageMain } = useContextMoment();
	return (
		<>
			<SectionBalance>
				<CardBalance title="Situación">
					<LabelCardBalance
						label={
							balance.propiedades.mensajes
								.find((f) => f.key === '07')
								?.value.replace(
									'%s',
									balance.montoEsperado?.despachoCobranza ||
										''
								) || ''
						}
					/>
				</CardBalance>
			</SectionBalance>

			<Box width={200} margin={'30px auto'}>
				<CustomButton
					variant="solid"
					label="Regresar"
					onClick={() => setPageMain(BALANCES_MAIN_PAGE.CREDIT)}
				/>
			</Box>
		</>
	);
};

export default RecuperacionSentencia;
