import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	titleContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		'&>h2': {
			color: theme.palette.info.dark,
			marginBottom: 5,
		},
	},
    titleContainerOnly: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',

		'&>h2': {
			color: theme.palette.info.dark,
			marginBottom: 30,
		},
	},
	titleDate: {
		fontWeight: 500,
		fontSize: 16,
	},
	containerTooltipTitle: {
		marginBottom: 30,
	},
	tooltipIcon: {
		fontSize: 18,
		marginRight: 5,
	},
	labelTooltipTitle: {
		color: theme.palette.info.dark,
		fontSize: 16,
	},
	listItem: {
		[theme.breakpoints.up('xs')]: {
			display: 'flex',
			flexDirection: 'row',
			fontSize: 15,
			margin: 0,
		},
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			flexDirection: 'row',
			fontSize: 16,
			margin: 0,
		},
	},
	containerInfoHeader: {
		backgroundColor: '#FFFFFF',
		borderRadius: 5,
		width: '100%',
		padding: 10,
	},
	labelInfoHeaderTitle: {
		fontSize: 15,
	},
	labelInfoHeaderInfo: {
		fontSize: 14,
		fontWeight: '500',
		textAlign: 'right',

		[theme.breakpoints.down('sm')]: {
			textAlign: 'left',
		},
	},
	subContainerInfoHeader: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: 3,
		gap: 4,

		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	listItemCustom: {
		display: 'flex',
		flexDirection: 'row',
		margin: 0,
	},
	listItemChild: {
		fontSize: 14,
		margin: 0,
		marginLeft: 15,
	},
	containerDivider: {
		borderRightColor: theme.palette.grey[400],
		borderRightWidth: 1,
		borderRightStyle: 'solid',
		paddingRight: 20,
		[theme.breakpoints.down('md')]: {
			borderRightWidth: 0,
		},
	},
	containerButtons: {
		marginTop: 10,
		display: 'flex',
		gap: 10,
	},
	containerPaper: {
		borderRadius: '0px !important',
	},
	labelTitle: {
		fontSize: 22,
		fontWeight: '500',
	},
	containerRadios: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
	},
	subContainerRadios: {
		width: '70%',
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: 20,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			flexDirection: 'column',
		},
	},
	subContainerRadiosPayments: {
		width: '70%',
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: 20,
		borderColor: theme.palette.grey[400],
		borderWidth: 1,
		borderStyle: 'solid',
		borderRadius: 5,
		padding: 20,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	subContainerButtons: {
		width: '70%',
		display: 'flex',
		justifyContent: 'center',
	},
	subContainerFooter: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		marginTop: 25,
	},
	subContainerRadiosInfo: {
		display: 'flex',
		alignItems: 'center',
	},
	labelRadio: {
		fontSize: 19,
		marginTop: 3,
		cursor: 'pointer',
	},
	labelRadioQTY: {
		fontSize: 19,
		marginTop: 3,
		fontWeight: '500',
		cursor: 'pointer',
	},
	containerLink: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
	},
	linkToPlaces: {
		textAlign: 'center',
		color: theme.palette.primary.main,
		fontSize: 18,
	},
	divider: {
		width: '100%',
		height: 1,
		backgroundColor: theme.palette.grey[400],
		marginTop: 40,
		marginBottom: 40,
	},
	imgVisa: {
		marginRight: 10,
	},
	labelFooter: {
		textAlign: 'center',
	},
	cardButton: {
		display: 'flex',
		width: '100%',
		borderRadius: 5,
		height: 50,
		background: '#00C951',
		color: '#fff',
		justifyContent: 'center',
		alignItems: 'center',
        fontSize: 14
	},
}));
