import { useState } from 'react';
import jwt from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import CustomSelectInput from '../components/SelectInput';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { useDispatch } from 'react-redux';
import { actions } from '../../../../store/modules/credit';
import { actions as actionsAuth } from '../../../../store/modules/session';
import ModalLoading from '../../../ModalLoading';
import { useStyles } from './styles';
import { creditAPI } from '../../../../api/modules/credit';
import { keyAPI } from '../../../../api/modules/key';
import { encrypt } from '../../../../utils/encrypt';

export default function Credits() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const classes = useStyles();
	const { availableCredits } = useSelector(
		(state: RootState) => state.credit
	);
	const formatedCredits = (availableCredits || []).map((credit) => ({
		name: credit.num_credito,
		value: credit.num_credito,
	}));

	const handleChangeCredit = async (e: any) => {
		if (e && Number(e) > 0) {
			try {
				setLoading(true);
				const keyRes = await keyAPI.get<'', { k: string }>();
				const key = keyRes['k'].toString();
				const credit_base64 = await encrypt(e, key);
				const { token } = await creditAPI.post<
					{ credit: string },
					{ token: string }
				>('', {
					credit: credit_base64,
				});
				if (token) {
					const user = jwt(token) as any;
					await dispatch(actions.setCredit(e));
					navigate('/inicio');
					dispatch(
						actionsAuth.setUser({
							...user,
							k: key,
							token: token,
						})
					);
				}
			} finally {
				setLoading(false);
			}
		}
	};

	return (
		<Box className={classes.container}>
			<ModalLoading loading={loading} />
			<Typography sx={{ fontSize: 14, color: 'secondary.light' }}>
				Crédito
			</Typography>
			{formatedCredits.length > 0 ? (
				<CustomSelectInput
					data={formatedCredits}
					onChange={handleChangeCredit}
				/>
			) : (
				<p className={classes.noCreditLabel}>Sin crédito vigente</p>
			)}
		</Box>
	);
}
