// ---------------------- Constantes ---------------------------------------
export const CODES = {
	LIQUIDADOS_POR_PAGO: '00010',
	LIQUIDADOS_OTROS: '00020',
	CASOS_ESPECIALES_NP_EF_DA: '00030',
	CASOS_ESPECIALES_PRORROGAS_ESPECIALES_3_4_7_8: '00040',
	CASOS_ESPECIALES_PRORROGAS_ESPECIALES_5_6_9: '00045',
	CASOS_ESPECIALES_RENEWAL_NO_DEMANDA: '00050',
	RECUPERACION_DEMANDADO_CONVENIO_JUDICIAL_VENCIDO: '00100',
	RECUPERACION_DEMANDADO_CONVENIO_JUDICIAL_VIGENTE_1: '00150',
	RECUPERACION_DEMANDADO_CONVENIO_JUDICIAL_VIGENTE_2: '00160',
	RECUPERACION_DEMANDADO_SIN_CONVENIO_JUDICIAL: '00200',
	RECUPERACION_SENTENCIA: '00220',
	RECUPERACION_BUSQUEDA_REESTRUCTURA_PRORROGA_1_2: '00310',
	RECUPERACION_BUSQUEDA_REESTRUCTURA_SIN_PRORROGA: '00350',
	RECUPERACION_BUSQUEDA_SIN_REESTRUCTURA_CON_FPP_PRORROGA_1_2: '00450',
	RECUPERACION_BUSQUEDA_SIN_REESTRUCTURA_CON_FPP_SIN_PRORROGA: '00550',
	RECUPERACION_BUSQUEDA_SIN_REESTRUCTURA_SIN_FPP_PRORROGA_1_2: '00600',
	RECUPERACION_BUSQUEDA_SIN_REESTRUCTURA_SIN_FPP_SIN_PRORROGA: '00650',
};

export const CODE_SOLITARY_MODULE = [
	CODES.LIQUIDADOS_POR_PAGO,
	CODES.LIQUIDADOS_OTROS,
	CODES.CASOS_ESPECIALES_NP_EF_DA,
	CODES.CASOS_ESPECIALES_PRORROGAS_ESPECIALES_3_4_7_8,
	CODES.CASOS_ESPECIALES_RENEWAL_NO_DEMANDA,
	CODES.RECUPERACION_DEMANDADO_SIN_CONVENIO_JUDICIAL,
	CODES.RECUPERACION_SENTENCIA,
];

export const BALANCES_MAIN_PAGE = {
	CREDIT: 0,
	PAYMENT: 1,
};

export const BALANCES_MOMENTS_PAGE = {
	HOME: 0,
	MOMENT_ACCOUNT: 1,
	CREDIT_ORIGIN: 2,
};

export const MOVEMENTS_ACCOUNT_PAGE = {
	MOVEMENTS: 0,
	STATUS_ACCOUNT: 1,
};

export const MESSAGES = {
	MAIN: ['13', '14', '06', '12', '15', '04', '01', '05'],
};

export const LIMIT_ROWS_PER_PAGE = 5;

export const CODE_MONEY = {
	PESOS: 'PESOS',
	VSM: 'VSM',
};

export const CODE_TYPE_MOMENT = {
	PAGOS: 'pagos',
	ABONOS: 'abonos',
	PAGOS_PARCIALES: 'pagos-parciales',
};
// ---------------------- Funciones ---------------------------------------
export const getValNumberOrNull = (
	valor?: string
): number | null | undefined => {
	try {
		if (valor) {
			const numero = Number(valor.replaceAll(',', ''));
			if (numero > 0) {
				return numero;
			}
		}
		return null;
	} catch (error) {
		return null;
	}
};

export const getVSMFormat = (valor?: string): string => {
	try {
		if (valor) {
			const numero = Number(valor.replaceAll(',', ''));
			if (numero > 0) {
				return numero.toFixed(3) + " VSM";
			}
		}
		return '0.000 VSM';
	} catch (error) {
		return '0.000 VSM';
	}
};

export const dateFormat = (fecha: string): string => {
	if (fecha) {
		const year = fecha.slice(0, 4);
		const month = fecha.slice(4, 6);
		const day = fecha.slice(6, 8);
		return day + '/' + month + '/' + year;
	}
	return '---';
};
