import { Divider } from '@mui/material';
import Modal from '../../../../components/Modal';
import CustomButton from '../../../../components/Button';
import { useStyles } from './styles';

interface Props {
	showModal: boolean;
	setShowModal: (avg: boolean) => void;
	setAccept: () => void;
}

const Paperless = ({ showModal, setShowModal, setAccept }: Props) => {
	const styles = useStyles();

	return (
		<Modal
			onClose={() => setShowModal(false)}
			open={showModal}
			width={'sm'}
			children={
				<div className={styles.container}>
					<h2 className={styles.title}>¡Más árboles, más vida!</h2>
					<p>
						Al registrarse en Mi cuenta Infonavit ya no recibirás de
						forma impresa el estado de cuenta en tu domicilio, y te
						enviaremos mensualmente un correo electrónico para
						avisarte que ya está disponible en el portal.
					</p>
					<p className={styles.legends}>¡Práctico y seguro!</p>
					<p className={styles.legends}>
						¡Ayúdanos a cuidar el medio ambiente al reducir el uso
						de papel!
					</p>
					<Divider className={styles.divider} />
					<div className={styles.containerButton}>
						<div className={styles.buttons}>
							<CustomButton
								label="No acepto"
								variant="outlined"
								onClick={() => setShowModal(false)}
							/>
						</div>
						<div className={styles.buttons}>
							<CustomButton
								label="Acepto"
								variant="solid"
								onClick={() => setAccept()}
							/>
						</div>
					</div>
					<p>
						Al no recibir respuesta se da por hecho que estás de
						acuerdo en recibir por correo electrónico el aviso de
						que tu estado de cuenta está disponible en el portal.
					</p>
				</div>
			}
		/>
	);
};

export default Paperless;
