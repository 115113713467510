import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import { SelectChangeEvent } from '@mui/material';
import { balancesMovementsAPI } from '../../../../../../api/modules/BalancesMovements';
import { encodeAllJSONData } from '../../../../../../utils/encrypt';
import { downloadPDF } from '../../../../../../utils/downloadPDF';
import { INTERNAL_MESSAGES } from '../../../../../../config/messageCatalog';
import {
	CODE_MONEY,
	CODE_TYPE_MOMENT,
	LIMIT_ROWS_PER_PAGE,
} from '../../../../utils';
import {
	getComparator,
	OrderFilter,
	getSortData,
	CODE_SUCCESS,
} from '../utils';
import { IMovimiento } from '../../../../../../interfaces/balanceMoment';

export const useMomentsAccount = () => {
	const { credit } = useSelector((state: RootState) => state.credit);
	const { user } = useSelector((state: RootState) => state.session);
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState('');
	const [rowsMoment, setRowsMoment] = useState<IMovimiento[]>([]);
	const [rowsMomentShow, setRowsMomentShow] = useState<IMovimiento[]>([]);
	const [countRowsMoment, setCountRowsMoment] = useState(0);
	const [pageShow, setPageShow] = useState(1);
	const [disabledInputs, setDisabledInputs] = useState(false);
	const [disabledColumns, setDisabledColumns] = useState(false);
	const [orderMoments, setOrderMoments] = useState<OrderFilter>('asc');
	const [orderByMoments, setOrderByMoments] = useState<any>('');
	const [diasTrabajados, setDiasTrabajados] = useState<any>('');
	const [filter, setFilter] = useState({
		periodo: '1',
		tipoMoneda: CODE_MONEY.PESOS,
		tipoMovimiento: CODE_TYPE_MOMENT.PAGOS,
	});

	const getReporteMovimientos = async () => {
		try {
			setLoading(true);
			setMessage('');
			const dataOrigin = {
				credito: credit,
				periodo: filter.periodo,
				tipoMovimiento: filter.tipoMovimiento,
				tipoMoneda: filter.tipoMoneda,
			};
			const dataEncripted = await encodeAllJSONData(
				{ data: JSON.stringify(dataOrigin) },
				user?.k || ''
			);
			const { data, message } =
				await balancesMovementsAPI.getMovimientosPDF(dataEncripted);
			if (data?.pdfBase64) {
				downloadPDF({
					payload: data?.pdfBase64,
					name: 'movimientos',
				});
				return;
			}
			setMessage(message || INTERNAL_MESSAGES.ERROR_MESSAGE);
		} catch (error) {
			setMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);
		} finally {
			setLoading(false);
		}
	};

	const resetFilter = () => {
		setRowsMoment([]);
		setRowsMomentShow([]);
		setCountRowsMoment(0);
		setPageShow(1);
		setOrderMoments('asc');
		setOrderByMoments('');
		setMessage('');
	};

	const handleChangeFilter = ({
		target,
	}: SelectChangeEvent<string | number>) => {
		const { name, value } = target;
		resetFilter();
		if (name === 'tipoMovimiento') {
			setDisabledColumns(
				[
					CODE_TYPE_MOMENT.PAGOS_PARCIALES,
					CODE_TYPE_MOMENT.ABONOS,
				].includes(String(value))
			);
			setDisabledInputs(value === CODE_TYPE_MOMENT.PAGOS_PARCIALES);
			if (value === CODE_TYPE_MOMENT.PAGOS_PARCIALES) {
				setFilter({
					...filter,
					tipoMoneda: CODE_MONEY.PESOS,
					[name]: String(value),
				});
				return;
			}
		}
		setFilter({
			...filter,
			[name]: value,
		});
	};

	const handleSearchMoments = async () => {
		try {
			setLoading(true);
			resetFilter();
			const dataOrigin = {
				credito: credit,
				periodo: filter.periodo,
				tipoMovimiento: filter.tipoMovimiento,
				tipoMoneda: filter.tipoMoneda,
			};
			const dataEncripted = await encodeAllJSONData(
				{ data: JSON.stringify(dataOrigin) },
				user?.k || ''
			);
			const { data, message, code } =
				await balancesMovementsAPI.getMovimientos(dataEncripted);
			if (code === CODE_SUCCESS && data) {
				const movimientos = data.movimientos || [];
				if (movimientos.length > 0) {
					setDiasTrabajados(data.diasTrabajados);
					setRowsMoment(movimientos);
					setRowsMomentShow(
						movimientos.slice(0, LIMIT_ROWS_PER_PAGE)
					);
					setCountRowsMoment(
						Math.ceil(movimientos.length / LIMIT_ROWS_PER_PAGE)
					);
				}
				return;
			}
			setMessage(message || INTERNAL_MESSAGES.ERROR_MESSAGE);
		} catch (error) {
			setMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);
		} finally {
			setLoading(false);
		}
	};

	const handleSortMoments = (orderBy: any) => {
		const isAsc = orderByMoments === orderBy && orderMoments === 'asc';
		const order = isAsc ? 'desc' : 'asc';
		setOrderMoments(order);
		setOrderByMoments(orderBy);

		const momentsSort = getSortData(
			rowsMoment,
			getComparator(order, orderBy)
		);
		setRowsMoment(momentsSort);
		const paginatedData = momentsSort.slice(
			(pageShow - 1) * LIMIT_ROWS_PER_PAGE,
			pageShow * LIMIT_ROWS_PER_PAGE
		);
		setRowsMomentShow(paginatedData);
	};

	const handleChangePage = (page: number) => {
		setPageShow(page);
		const paginatedData = rowsMoment.slice(
			(page - 1) * LIMIT_ROWS_PER_PAGE,
			page * LIMIT_ROWS_PER_PAGE
		);
		setRowsMomentShow(paginatedData);
	};

	useEffect(() => {
		handleSearchMoments();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		loading,
		message,
		disabledInputs,
		disabledColumns,
		rowsMomentShow,
		filter,
		countRowsMoment,
		orderByMoments,
		orderMoments,
		pageShow,
		diasTrabajados,
		getReporteMovimientos,
		handleSearchMoments,
		handleChangeFilter,
		handleSortMoments,
		handleChangePage,
	};
};
