export const TABS_BALANCE_MOVEMENTS = [
	{
		id: 1,
		title: 'Crédito',
		disabled: false,
		checked: false,
		columns: 4,
		orderMD: 0,
		orderSM: 0,
	},
	{
		id: 2,
		title: 'Movimientos y estado de cuenta',
		disabled: false,
		checked: false,
		columns: 4,
		orderMD: 1,
		orderSM: 2,
	},
	{
		id: 3,
		title: 'Datos de originación de mi crédito',
		disabled: false,
		checked: false,
		columns: 4,
		orderMD: 2,
		orderSM: 3,
	},
];
