import CustomButton from '../../../../../components/Button';
import ModalLoading from '../../../../../components/ModalLoading';
import { PAYMENT_TYPES } from '../../../utils/PaymentTypes';
import { usePayMyCredit } from '../hook/usePayMyCredit';
import { PAGE_PAY_CREDIT } from '../utils';
import { useStyles } from './styles/ProceedPayment.style';

const ProceedPayment = () => {
	const { handleExecutePay, loading, valuePaymentType, setPagePay } =
		usePayMyCredit();
	const classes = useStyles();
	return (
		<>
			<ModalLoading loading={loading} />
			<div className={classes.container}>
				<h3 className={classes.subTitle}>
					Para continuar con tu proceso de pago, al presionar el botón
					“Continuar”{' '}
				</h3>
				Se te redireccionará a un portal operado por{' '}
				{valuePaymentType === PAYMENT_TYPES.CODI.value
					? 'CoDi'
					: 'BBVA'}{' '}
				fuera del sitio oficial de Mi Cuenta Infonavit, cuentas con
				total seguridad en la protección de la información que
				proporcionarás para realizar el pago. En caso de que se
				interrumpa la comunicación, inicia nuevamente la sesión en Mi
				Cuenta Infonavit.
				<h3>¿Deseas continuar?</h3>
			</div>
			<div className={classes.containerButton}>
				<CustomButton
					label={'Regresar'}
					variant="outlined"
					onClick={() => setPagePay(PAGE_PAY_CREDIT.HOME)}
					classNames={classes.button}
				/>
				<CustomButton
					label={'Continuar'}
					variant="solid"
					onClick={handleExecutePay}
					classNames={classes.button}
				/>
			</div>
		</>
	);
};

export default ProceedPayment;
