import { Box, Paper } from '@mui/material';
import PayCreditMain from './components/PayCreditMain';
import { useStyles } from './styles';
import HeaderPayCredit from './components/HeaderPayCredit';
import { CODES } from '../../utils';
import SolitaryModule from './components/SolitaryModule';
import { useContextMoment } from '../../context';
import MessagesCode from './components/MessagesCode';
import { PayProvider } from './context/PayProvider';

const PayMyCredit = () => {
	const { balance } = useContextMoment();
	const classes = useStyles();
	return (
		<PayProvider>
			<HeaderPayCredit />
			<Paper className={classes.containerPaper}>
				<Box sx={{ padding: 3 }}>
					{[
						CODES.CASOS_ESPECIALES_NP_EF_DA,
						CODES.RECUPERACION_SENTENCIA,
					].includes(balance.propiedades?.tipoCaso) ? (
						<SolitaryModule />
					) : (
						<>
							{[
								CODES.CASOS_ESPECIALES_PRORROGAS_ESPECIALES_3_4_7_8,
								CODES.CASOS_ESPECIALES_RENEWAL_NO_DEMANDA,
							].includes(balance.propiedades?.tipoCaso) ? (
								<MessagesCode />
							) : (
								<PayCreditMain />
							)}
						</>
					)}
				</Box>
			</Paper>
		</PayProvider>
	);
};

export default PayMyCredit;
