import { Grid, Link } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useContextMoment } from '../../../context';
import { SectionBalance } from '../../SectionBalance';
import { CardBalance } from '../../CardBalance';
import { LabelCardBalance } from '../../LabelCardBalance';
import { formatStringToDate } from '../../../../../utils/dates';
import iconoFamilia from '../../../../../assets/img/SaldosYmovimientos/familiar.png';
import { BALANCES_MOMENTS_PAGE } from '../../../utils';
import { useStyles } from './styles/LiquidadosPorPagos.style';

interface IProps {
	pageShow?: Number;
}

const LiquidadosPorPagos = ({ pageShow }: IProps) => {
	const { balance } = useContextMoment();
	const styles = useStyles();

	return (
		<>
			{pageShow === BALANCES_MOMENTS_PAGE.HOME && (
				<div className={styles.containerMain}>
					<div className={styles.iconoFamilia}>
						<img src={iconoFamilia} alt="iconoFamilia" />
					</div>
					<div className={styles.containerText}>
						<h1>¡Felicidades!</h1>
						<h3>
							Gracias a tu constancia, has liquidado exitosamente
							tu crédito.
						</h3>
					</div>
				</div>
			)}
			<SectionBalance
				title="Crédito Liquidado"
				icon={<CheckCircleRoundedIcon className={styles.iconCheck} />}
			>
				<Grid container columns={12} spacing={2}>
					<Grid item xs={12} md={4}>
						<CardBalance title="Tipo de Crédito">
							<LabelCardBalance
								label={balance.liquidado?.tipoCredito || ''}
							/>
						</CardBalance>
					</Grid>
					<Grid item xs={12} md={4}>
						<CardBalance title="Fecha de Liquidación">
							<LabelCardBalance
								label={formatStringToDate(
									balance.liquidado?.fechaLiquidacion || ''
								)}
							/>
						</CardBalance>
					</Grid>
					<Grid item xs={12} md={4}>
						<CardBalance title="Tipo de Liquidación">
							<LabelCardBalance
								label={balance.liquidado?.tipoLiquidacion || ''}
							/>
						</CardBalance>
					</Grid>
				</Grid>
				<Link
					href="/mi-credito/devolucion-pagos-exceso"
					underline="none"
					className={styles.linkText}
				>
					Si quieres saber si tu crédito tiene pagos en exceso
					consulta la información aquí
					<ArrowForwardIosRoundedIcon className={styles.iconArrow} />
				</Link>
			</SectionBalance>
		</>
	);
};

export default LiquidadosPorPagos;
