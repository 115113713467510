import { useEffect, useState } from 'react';
import { Divider, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// COMPONENTS
import CustomAlert from '../../../components/CustomAlert';
import CustomButton from '../../../components/Button';
//ASSETS
import iconDownload from '../../../assets/img/icono-descarga.png';
// RESOURCES
import { IAlert } from '../../../interfaces/alert';
import { downloadPDF } from '../../../utils/downloadPDF';
import { useStyles } from './styles';
import { apiCancelledPayments } from '../../../api/modules/CancelledPayments';
import ModalLoading from '../../../components/ModalLoading';
import { encrypt } from '../../../utils/encrypt';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';

interface IRequestData {
	caso?: string;
	estatus?: string;
	descripcionEstatus?: string;
	claveJustificacion?: string;
	descripcionJustificacion?: string;
	proccessType?: string;
	credito?: string;
	fechaAbierta?: string;
	fechaCierre?: string;
}

const ResumeView = () => {
	const classes = useStyles();
	const { user } = useSelector((state: RootState) => state.session);
	const navigate = useNavigate();
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [dataCaso, setDataCaso] = useState<IRequestData>({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const req = async () => {
			try {
				setLoading(true);
				const { result } = await apiCancelledPayments.getCaso();

				if (result.code !== '0000') {
					setAlert({
						show: true,
						message:
							result.message ||
							'Ha ocurrido un error al obtener los datos, intente nuevamente',
						severity: 'error',
					});
					return;
				}

				setDataCaso(result.data);
			} catch (error) {
				setAlert({
					show: true,
					message:
						'Ha ocurrido un error al obtener los datos, intente nuevamente',
					severity: 'error',
				});
			} finally {
				setLoading(false);
			}
		};

		req();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getDownloadPDF = async () => {
		try {
			setLoading(true);
			setAlert({
				show: false,
				message: '',
				severity: 'error',
			});
			const data_encript: string = await encrypt(
				JSON.stringify({
					idTramite: dataCaso.caso || '',
				}),
				user?.k || ''
			);
			const response = await apiCancelledPayments.descargarArchivo(data_encript);
			const { result } = response;
			if (result.code === '0000' && result.data.base64 !== '') {
				downloadPDF({
					payload: result.data.base64,
					name: 'devolucion_de_pagos_por_creditos_cancelados',
				});
				return;
			}
			setAlert({
				show: true,
				message:
					result.message ||
					'Ha ocurrido un error al obtener los datos, intente nuevamente',
				severity: 'error',
			});
		} catch (error) {
			setAlert({
				show: true,
				message:
					'Ha ocurrido un error al obtener los datos, intente nuevamente',
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	const getDate = (arg?: string) => {
		if(!arg || arg === '') return "";
		const stringDay = arg.toString();
		const anio = stringDay.slice(0, 4);
		const mes = stringDay.slice(4, 6);
		const dia = stringDay.slice(6, 8);
		return dia + "/" + mes + "/" + anio;
	}

	return (
		<>
			<ModalLoading loading={loading} />
			<div className={classes.containerResume}>
				<div>
					<div style={{ textAlign: 'center' }}>
						<h3 style={{ margin: '0px', color: '#283990' }}>
							Gracias por utilizar los servicios electrónicos del
							Infonavit
						</h3>
					</div>
					<div>
						<div className={classes.contentFile}>
							<div className={classes.contentFileData}>
								<h4 className={classes.contentFileDataTitle} style={{ margin: '10px' }}>
									Número de caso
								</h4>
							</div>
							<div>
								<p className={classes.contentFileDataLink2}>
									{dataCaso?.caso}
								</p>
							</div>
						</div>
					</div>
					<Divider style={{ marginBlock: 10 }} />
					<div>
						<div className={classes.contentFile}>
							<div className={classes.contentFileData}>
								<h4 className={classes.contentFileDataTitle} style={{ margin: '10px' }}>
									Fecha de solicitud
								</h4>
							</div>
							<div>
								<p className={classes.contentFileDataLink2}>
									{getDate(dataCaso?.fechaAbierta)}
								</p>
							</div>
						</div>
					</div>
					<Divider style={{ marginBlock: 10 }} />
					<div>
						<div className={classes.contentFile}>
							<div className={classes.contentFileData}>
								<h4 className={classes.contentFileDataTitle} style={{ margin: '10px' }}>
									Servicio
								</h4>
							</div>
							<div>
								<p className={classes.contentFileDataLink2}>
									Devolución de pagos por créditos cancelados
								</p>
							</div>
						</div>
					</div>
					<Divider style={{ marginBlock: 10 }} />
					<div>
						<div className={classes.contentFile}>
							<div className={classes.contentFileData}>
								<h4 className={classes.contentFileDataTitle} style={{ margin: '10px' }}>
									Estatus
								</h4>
							</div>
							<div>
								<p className={classes.contentFileDataLink2}>
									{dataCaso?.descripcionEstatus}
								</p>
							</div>
						</div>
					</div>
					<Divider style={{ marginBlock: 10 }} />
					<div>
						<div className={classes.contentFile}>
							<div className={classes.contentFileData}>
								<h4 className={classes.contentFileDataTitle} style={{ margin: '10px' }}>
									Fecha de estatus
								</h4>
							</div>
							<div>
								<p className={classes.contentFileDataLink2}>
									{getDate(dataCaso?.fechaCierre)}
								</p>
							</div>
						</div>
					</div>
					<Divider style={{ marginBlock: 10 }} />
					<div className={classes.contentFooterData}>
						<p>
							Tu trámite en línea de pagos por trámite de crédito
							cancelado está en proceso. Tus recursos serán
							depositados en un lapso no mayor a 10 días hábiles.
						</p>
						<p
							className={classes.contentFooterDataLink}
							onClick={getDownloadPDF}
						>
							Descargar comprobante electrónico.
							<Link underline="hover">
								<img
									style={{ marginLeft: 5, width: 24 }}
									src={iconDownload}
									alt="download"
								></img>
							</Link>
						</p>
					</div>
				</div>
				<CustomAlert
					message={alert.message}
					severity={alert.severity}
					show={alert.show}
				/>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<CustomButton
						onClick={() => {
							navigate('/inicio');
						}}
						variant="solid"
						disabled={false}
						styles={{
							margin: '10px 0px',
							width: 200,
						}}
						label="Finalizar"
					/>
				</div>
			</div>
		</>
	);
};

export default ResumeView;
