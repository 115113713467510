import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
	listCard: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: '#fff',
		borderRadius: '10px',
		marginTop: '20px',
		padding: '0px 10px',
	},
	listCardContent: {
		display: 'block',
		padding: '10px 0px',
		margin: '0px 10px',
		userSelect: 'none',
	},
	subPanel: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: '10px',
	},
	subPanelContent: {
		display: 'grid',
		gridTemplateColumns: 'repeat(3, 1fr)',
		margin: '5px 0px',
		color: 'gray',
	},
	numberText: {
		textAlign: 'right',
	},
	arrowIconPanel: {
		display: 'flex',
		alignItems: 'center',
	},
	arrowIconSpacing: {
		paddingLeft: '25px',
	},
}));
