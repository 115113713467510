import { Info } from '@mui/icons-material';
import TooltipCustom from '../../../../components/TooltipCustom';
import { useStyles } from './styles';

interface ISectionBalance {
	title?: string;
	children: JSX.Element[] | JSX.Element;
	tooltip?: string;
	icon?: JSX.Element;
}
export const SectionBalance = ({
	children,
	title,
	tooltip,
	icon,
}: ISectionBalance) => {
	const classes = useStyles();
	return (
		<div className={classes.container}>
			{(title || tooltip || icon) && (
				<div className={classes.containerTitle}>
					<label className={classes.labelTitle}>
						{title}
						{tooltip && (
							<TooltipCustom title={tooltip}>
								<Info
									color="primary"
									className={classes.tooltipIcon}
								/>
							</TooltipCustom>
						)}
						{icon && icon}
					</label>
				</div>
			)}
			{children}
		</div>
	);
};
