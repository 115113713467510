import { Info } from '@mui/icons-material';
import TooltipCustom from '../../../../components/TooltipCustom';
import { useStyles } from './styles';

interface ICardBalance {
	title: string;
	children: JSX.Element;
	tooltip?: string;
}
export const CardBalance = ({ children, title, tooltip }: ICardBalance) => {
	const classes = useStyles();
	return (
		<div className={classes.container}>
			<label className={classes.labelTitle}>
				{title}
				{tooltip && (
					<TooltipCustom title={tooltip}>
						<Info color="primary" className={classes.tooltipIcon} />
					</TooltipCustom>
				)}
			</label>
			{children}
		</div>
	);
};
