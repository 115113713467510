import { useContext } from 'react';
import PayCreditForm from './PayCreditForm';
import ProceedPayment from './ProceedPayment';
import { PayContext } from '../context/PayProvider';
import { PAGE_PAY_CREDIT } from '../utils';
import PaymentRejected from './PaymentRejected';

const PayCreditMain = () => {
	const { pagePay } = useContext(PayContext);
	return (
		<>
			{pagePay === PAGE_PAY_CREDIT.HOME && <PayCreditForm />}
			{pagePay === PAGE_PAY_CREDIT.CONFIRM_PAYMENT && <ProceedPayment />}
			{pagePay === PAGE_PAY_CREDIT.REJECTED_PAYMENT && (
				<PaymentRejected />
			)}
		</>
	);
};

export default PayCreditMain;
