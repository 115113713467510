import { IPropsRenderHTML } from './interfaces';
import { renderHTML } from './utils';

const RenderHTML = ({ htmlContent, className, styles }: IPropsRenderHTML) => {
	return (
		<div className={className} style={styles}>
			{renderHTML(htmlContent)}
		</div>
	);
};

export default RenderHTML;
