import { useContextMoment } from '../../../context';
import { CardBalance } from '../../CardBalance';
import { LabelCardBalance } from '../../LabelCardBalance';
import { SectionBalance } from '../../SectionBalance';

const CasosEspecialesNpEfDa = () => {
	const { balance } = useContextMoment();

	return (
		<SectionBalance>
			<CardBalance title="Situación">
				<LabelCardBalance
					label={`Tu crédito ${
						balance?.datosGenerales?.tipoCredito
					} tiene la situación de ${
						balance?.montoEsperado?.estatusLegal || ''
					}`}
				/>
			</CardBalance>
		</SectionBalance>
	);
};

export default CasosEspecialesNpEfDa;
