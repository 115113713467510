import { IDataBalanceMoment } from '../../../interfaces/balanceMoment';

export interface IContextPropsMoments {
	balance: IDataBalanceMoment;
    certificado: string;
    pageMain: number;
    setPageMain: React.Dispatch<React.SetStateAction<number>>;
}

export const defaultDataContext: IDataBalanceMoment = {} as IDataBalanceMoment;
