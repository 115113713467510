import { useContextMoment } from '../../../../context';
import { CODES } from '../../../../utils';
import CasosEspecialesNpEfDa from './components/CasosEspecialesNpEfDa';
import RecuperacionSentencia from './components/RecuperacionSentencia';

const SolitaryModule = () => {
	const { balance } = useContextMoment();
	return (
		<>
			{balance.propiedades?.tipoCaso ===
				CODES.CASOS_ESPECIALES_NP_EF_DA && <CasosEspecialesNpEfDa />}

			{balance.propiedades?.tipoCaso ===
				CODES.RECUPERACION_SENTENCIA && <RecuperacionSentencia />}
		</>
	);
};

export default SolitaryModule;
