import { Divider, Grid, Link } from '@mui/material';
import CustomButton from '../../../../../components/Button';

interface Props {
	setOpenModal: (avg: boolean) => void;
}

const PayStateUnite = ({ setOpenModal }: Props) => {
	return (
		<Grid container columns={12} spacing={1} style={{ marginTop: 50 }}>
			<Grid item xs={12} md={3}>
				<label style={{ fontWeight: 500 }}>Paga desde U.S.A</label>
			</Grid>
			<Grid item xs={12} md={9}>
				<Grid container columns={12}>
					<Grid item xs={12}>
						<strong>Dolex</strong>
						<p>
							Para ubicar una sucursal:{' '}
							<Link
								href="https://www.dolex.com/es/inicio/"
								target={'_blank'}
								underline={'none'}
							>
								https://www.dolex.com/es/inicio/
							</Link>
							, o llama por teléfono al 1 800 892 0210,
							proporciona tu código postal y te indicarán la
							sucursal Dolex más cercana a tu domicilio para que
							puedas realizar tu pago.
						</p>
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<p style={{ fontWeight: 700 }}>InterCambio Express</p>
						<Link
							href="https://www.intercambioexpress.com/encuentre-una-ubicacion/"
							target={'_blank'}
							underline={'none'}
						>
							https://www.intercambioexpress.com/encuentre-una-ubicacion/
						</Link>
					</Grid>
					<Grid item xs={12}>
						<Divider style={{ marginTop: 20 }} />
					</Grid>
					<Grid item xs={12}>
						<p style={{ fontWeight: 700 }}>MaxiTransfers</p>
						Ingresa a{' '}
						<Link
							href="https://maxillc.com/billpayment"
							target={'_blank'}
							underline={'none'}
						>
							https://maxillc.com/billpayment
						</Link>
						, o llama por teléfono al 1 888 995 0310 y te indicarán
						la sucursal Maxitransfers más cercana a tu domicilio
						para que puedas hacer tu pago.
					</Grid>
					<Grid item xs={12}>
						<Divider style={{ marginTop: 20, marginBottom: 10 }} />
					</Grid>
					<Grid item xs={12}>
						<strong>Barri</strong>
						<p>
							Para ubicar una sucursal:{' '}
							<Link
								target="_blank"
								underline="none"
								href="https://www.barri.com/locations"
							>
								https://www.barri.com/locations
							</Link>
						</p>
						<p>
							Para realizar el pago por internet:{' '}
							<Link
								target="_blank"
								underline="none"
								href="https://www.barrimoneytransfer.com/mb/login"
							>
								https://www.barrimoneytransfer.com/mb/login
							</Link>
						</p>
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<p>
							Con respecto al proceso de pago, el usuario llega a
							cualquier sucursal, pide realizar el pago de su
							crédito Infonavit, proporciona los 10 dígitos del
							crédito y la cantidad a pagar/abonar, la cadena hace
							el cobro.
						</p>
					</Grid>
				</Grid>
			</Grid>

			<Grid item xs={12} md={12}>
				<Divider style={{ margin: '20px 0px' }} />
			</Grid>

			<Grid item xs={12} md={3}>
				<label style={{ fontWeight: 500 }}>Paga en línea</label>
			</Grid>
			<Grid item xs={12} md={9}>
				<Grid container columns={12}>
					<Grid item xs={12}>
						<p style={{ margin: '0px 0px 10px 0px' }}>
							Entra a{' '}
							<Link
								href="https://micuenta.infonavit.org.mx/wps/portal/mci2/login/"
								target={'_blank'}
								underline="none"
							>
								Mi Cuenta Infonavit
							</Link>{' '}
							y haz tu pago con tu tarjeta de crédito o débito de
							cualquier banco, excepto American Express.
						</p>
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<p>
							La cantidad máxima por operación al pagar con
							tarjeta es de $30,000.00 y puedes hacer hasta 3
							pagos en el mes, los cuales no deben exceder un
							total de $90,000.00, con cualquier tarjeta Visa o
							MasterCard.
						</p>
					</Grid>
				</Grid>
			</Grid>

			<Grid item xs={12} md={12}>
				<Divider style={{ margin: '20px 0px' }} />
			</Grid>

			<Grid item xs={12} md={12}>
				<div
					style={{ maxWidth: 250, margin: 'auto', marginBottom: 40 }}
				>
					<CustomButton
						variant="solid"
						onClick={() => setOpenModal(false)}
						label="Regresar"
					/>
				</div>
			</Grid>
		</Grid>
	);
};
export default PayStateUnite;
