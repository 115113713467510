import { createContext, useState, ReactNode } from 'react';
import { PAGE_PAY_CREDIT } from '../utils';

interface PayContextType {
	valuePayment: string;
	setValuePayment: React.Dispatch<React.SetStateAction<string>>;
	valuePaymentType: string;
	setValuePaymentType: React.Dispatch<React.SetStateAction<string>>;
    rejected: string | JSX.Element | null;
	setRejected: React.Dispatch<React.SetStateAction<string | JSX.Element | null>>;
	valuePaymentOther: string;
	setValuePaymentOther: React.Dispatch<React.SetStateAction<string>>;
	pagePay: number;
	setPagePay: React.Dispatch<React.SetStateAction<number>>;
}

const PayContext = createContext<PayContextType>({} as PayContextType);

interface UserProviderProps {
	children: ReactNode;
}

const PayProvider = ({ children }: UserProviderProps) => {
	const [valuePayment, setValuePayment] = useState('');
	const [valuePaymentType, setValuePaymentType] = useState('');
	const [valuePaymentOther, setValuePaymentOther] = useState('');
    const [rejected, setRejected] = useState<string | JSX.Element | null>(null);
	const [pagePay, setPagePay] = useState(PAGE_PAY_CREDIT.HOME);

	return (
		<PayContext.Provider
			value={{
				valuePayment,
				setValuePayment,
				valuePaymentType,
				setValuePaymentType,
                rejected,
                setRejected,
				valuePaymentOther,
				setValuePaymentOther,
				pagePay,
				setPagePay
			}}
		>
			{children}
		</PayContext.Provider>
	);
};

export { PayContext, PayProvider };
