import { useState } from 'react';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import { SectionBalance } from '../../../components/SectionBalance';
import { TOOLTIPS } from '../../../utils/TooltipsUtils';
import { CardBalance } from '../../../components/CardBalance';
import { LabelCardBalanceValues } from '../../../components/LabelCardBalanceValues';
import { getValNumberOrNull } from '../../../utils';
import ListMonMyCredit from '../../../components/ListMonMyCredit';
import { useContextMoment } from '../../../context';
import { useStyles } from '../styles';
import {
	getSaldoAlDia,
	getSaldoInicioMes,
	TYPES_BREAKDOWNS,
} from '../../../components/ListMonMyCredit/utils';

const SaldoCredito = () => {
	const classes = useStyles();
	const { balance } = useContextMoment();
	const [showDesglose, setShowDesglose] = useState<number>(0);
	const handleShowList = (arg: number) => {
		if (showDesglose === arg) {
			setShowDesglose(0);
			return;
		}
		setShowDesglose(arg);
	};

	if (
		balance?.saldoCredito?.saldoInicioMes ||
		balance?.saldoCredito?.saldoDia
	)
		return (
			<SectionBalance
				title="Saldo de mi crédito"
				tooltip={TOOLTIPS.SALDO_DE_CREDITO}
			>
				<Grid container spacing={2}>
					{/* Saldo al inicio del mes  */}
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<CardBalance title="Saldo al inicio del mes">
							<LabelCardBalanceValues
								variant="secondary"
								vsmValue={getValNumberOrNull(
									balance?.saldoCredito?.saldoInicioMesVsm
								)}
								mxnValue={Number(
									balance?.saldoCredito?.saldoInicioMes || '0'
								)}
							/>
						</CardBalance>
					</Grid>
					
					{/* Saldo al día */}
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<CardBalance title="Saldo al día">
							<LabelCardBalanceValues
								variant="info"
								vsmValue={getValNumberOrNull(
									balance?.saldoCredito?.saldoDiaVsm
								)}
								mxnValue={Number(
									balance?.saldoCredito?.saldoDia || '0'
								)}
							/>
						</CardBalance>
					</Grid>

					{/* Desglose */}
					<Grid item xs={12}>
						<Box className={classes.labelList}>
							{/* Primer desglose */}
							<div
								className={
									showDesglose === TYPES_BREAKDOWNS.MONTH
										? classes.diaSeleccionadoActivo
										: classes.diaSeleccionado
								}
								onClick={() =>
									handleShowList(TYPES_BREAKDOWNS.MONTH)
								}
							>
								Desglose del saldo al inicio del mes{' '}
								<KeyboardArrowDown
									style={{
										transition: '0.2s',
										transform:
											showDesglose ===
											TYPES_BREAKDOWNS.MONTH
												? 'rotate(180deg)'
												: 'rotate(0deg)',
									}}
								/>
							</div>

							{/* Segundo desglose */}
							<div
								className={
									showDesglose === TYPES_BREAKDOWNS.DAY
										? classes.diaSeleccionadoActivo
										: classes.diaSeleccionado
								}
								onClick={() =>
									handleShowList(TYPES_BREAKDOWNS.DAY)
								}
							>
								Desglose de saldo al dia{' '}
								<KeyboardArrowDown
									style={{
										transition: '0.2s',
										transform:
											showDesglose ===
											TYPES_BREAKDOWNS.DAY
												? 'rotate(180deg)'
												: 'rotate(0deg)',
									}}
								/>
							</div>
						</Box>
						<ListMonMyCredit
							show={showDesglose === TYPES_BREAKDOWNS.MONTH}
							items={getSaldoInicioMes(balance)}
						/>
						<ListMonMyCredit
							show={showDesglose === TYPES_BREAKDOWNS.DAY}
							items={getSaldoAlDia(balance)}
						/>
					</Grid>
				</Grid>
			</SectionBalance>
		);
	else return null;
};

export default SaldoCredito;
