import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { colors } from '@mui/material';
import { MOVEMENTS_ACCOUNT_PAGE } from '../../utils';

interface IProps {
	view: number;
}

export const useStyles = makeStyles<Theme, IProps>((theme: Theme) => ({
	containerMain: {
		padding: 30,
		minHeight: 300,
	},
	containerButton: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		gap: 2,
		marginBottom: 30,

		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	spanButtonL: {
		background: ({ view }) =>
			view === MOVEMENTS_ACCOUNT_PAGE.MOVEMENTS
				? '#DD0528'
				: colors.grey[400],
		color: '#fff',
		padding: '10px 0px 10px 0px',
		borderTopLeftRadius: '20px',
		borderBottomLeftRadius: '20px',
		fontWeight: 500,
		width: 200,
		textAlign: 'center',
		cursor: 'pointer',

		[theme.breakpoints.down('sm')]: {
			borderRadius: '20px',
			width: '100%',
		},
	},
	spanButtonR: {
		background: ({ view }) =>
			view === MOVEMENTS_ACCOUNT_PAGE.STATUS_ACCOUNT
				? '#DD0528'
				: colors.grey[400],
		color: '#fff',
		padding: '10px 0px 10px 0px',
		borderTopRightRadius: '20px',
		borderBottomRightRadius: '20px',
		fontWeight: 500,
		width: 200,
		textAlign: 'center',
		cursor: 'pointer',

		[theme.breakpoints.down('sm')]: {
			borderRadius: '20px',
			width: '100%',
		},
	},
}));
