import { KeyboardArrowDown } from '@mui/icons-material';
import { useState } from 'react';
import { Box, Grid } from '@mui/material';
import { SectionBalance } from '../../../components/SectionBalance';
import { CODES, getValNumberOrNull } from '../../../utils';
import { TOOLTIPS } from '../../../utils/TooltipsUtils';
import { CardBalance } from '../../../components/CardBalance';
import { LabelCardBalance } from '../../../components/LabelCardBalance';
import { formatStringToDate } from '../../../../../utils/dates';
import ListMonMyCredit from '../../../components/ListMonMyCredit';
import { getMontoEsperado } from '../../../components/ListMonMyCredit/utils';
import { useContextMoment } from '../../../context';
import { LabelCardBalanceValues } from '../../../components/LabelCardBalanceValues';
import { useStyles } from '../styles';

const MotoEsperado = () => {
	const classes = useStyles();
	const { balance } = useContextMoment();
	const [showMonto, setShowMonto] = useState(false);
	return (
		<SectionBalance
			title="Monto esperado"
			tooltip={TOOLTIPS.MONTO_ESPERADO}
		>
			<Grid container columns={12} spacing={2} marginBottom={2}>
				{![
					CODES.RECUPERACION_BUSQUEDA_SIN_REESTRUCTURA_SIN_FPP_SIN_PRORROGA,
				].includes(balance.propiedades?.tipoCaso) &&
					balance.montoEsperado?.reestructura && (
						<Grid item xs={12} md>
							<CardBalance title="Reestructura*">
								<LabelCardBalance
									label={
										balance.montoEsperado?.reestructura ||
										''
									}
								/>
							</CardBalance>
						</Grid>
					)}
				{![
					CODES.RECUPERACION_BUSQUEDA_SIN_REESTRUCTURA_SIN_FPP_SIN_PRORROGA,
				].includes(balance.propiedades?.tipoCaso) &&
					balance.montoEsperado?.situacion && (
						<Grid item xs={12} md>
							<CardBalance title="Situación*">
								<LabelCardBalance
									label={
										balance.montoEsperado?.situacion || ''
									}
								/>
							</CardBalance>
						</Grid>
					)}
			</Grid>
			<Grid container spacing={2}>
				{balance?.montoEsperado?.formaPagoDescripcion && (
					<Grid item xs={12} sm={12} md={6} lg={4}>
						<CardBalance
							title={
								'Forma de pago: ' +
								balance.propiedades.formaPago
							}
							tooltip={
								balance.propiedades.formaPago === 'ROA'
									? TOOLTIPS.FORMA_PAGO_ROA
									: TOOLTIPS.FORMA_PAGO_REA
							}
						>
							<LabelCardBalance
								label={
									balance?.montoEsperado?.formaPagoDescripcion
								}
							/>
						</CardBalance>
					</Grid>
				)}
				<Grid item xs={12} sm={12} md={6} lg>
					<CardBalance title="Fecha límite de pago">
						<LabelCardBalance
							label={
								balance?.montoEsperado?.fechaLimitePago
									? formatStringToDate(
											balance?.montoEsperado
												?.fechaLimitePago
									  )
									: ''
							}
						/>
					</CardBalance>
				</Grid>
				<Grid item xs={12} sm={12} md={6} lg>
					<CardBalance title="¿Cuánto debo?">
						<LabelCardBalanceValues
							variant="info"
							mxnValue={Number(
								balance?.montoEsperado?.cuantoDebo || '0'
							)}
							vsmValue={getValNumberOrNull(
								balance?.montoEsperado?.cuantoDeboVsm
							)}
						/>
					</CardBalance>
				</Grid>
				{balance.montoEsperado?.mesesDisponiblesFPP &&
					(balance?.montoEsperado?.montoCubrirFPP ||
						balance?.montoEsperado?.montoCubrirFPPVsm) && (
						<Grid item xs={12}>
							<Grid container columns={12} spacing={2}>
								<Grid item xs={12} md={6}>
									<CardBalance title="Meses disponibles para usar tu FFP">
										<LabelCardBalance
											label={`${
												balance.montoEsperado
													?.mesesDisponiblesFPP || '0'
											} meses`}
										/>
									</CardBalance>
								</Grid>
								<Grid item xs={12} md={6}>
									<CardBalance
										title="Monto a cubrir si utilizas tu FFP"
										tooltip={TOOLTIPS.MONTO_ESPERADO_FFP}
									>
										<LabelCardBalanceValues
											variant="info"
											mxnValue={Number(
												balance?.montoEsperado
													?.montoCubrirFPP || '0'
											)}
											vsmValue={getValNumberOrNull(
												balance?.montoEsperado
													?.montoCubrirFPPVsm
											)}
										/>
									</CardBalance>
								</Grid>
							</Grid>
						</Grid>
					)}
				<Grid item xs={12}>
					<Box className={classes.labelList}>
						<div onClick={() => setShowMonto(!showMonto)}>
							Desglose del monto esperado{' '}
							<KeyboardArrowDown
								style={{
									transition: '0.2s',
									transform: showMonto
										? 'rotate(180deg)'
										: 'rotate(0deg)',
								}}
							/>
						</div>
					</Box>
					<ListMonMyCredit
						show={showMonto}
						items={getMontoEsperado(balance)}
					/>
				</Grid>
			</Grid>
		</SectionBalance>
	);
};

export default MotoEsperado;
