import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		borderRadius: 10,
		borderColor: theme.palette.grey[600],
		borderStyle: 'solid',
		borderWidth: 0.5,
		minHeight: 90,
		height: 'auto',
		padding: 10,
		backgroundColor: '#FFFFFF',
	},
	labelTitle: {
		fontSize: 17,
	},
	tooltipIcon: {
		fontSize: '17px !important',
		marginLeft: 4,
	},
}));
