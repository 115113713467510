import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
	containerCredito: {
		padding: 30,
	},
	containerPaper: {
		borderRadius: '0px !important',
	},
	divider: {
		marginBottom: 20,
	},
	containerFooter: {
		marginTop: 40,
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		textAlign: 'center',
	},
	labelList: {
		display: 'flex',
		justifyContent: 'space-between',
		color: '#293990',
		fontWeight: 500,

		'& > div': {
			display: 'flex',
			cursor: 'pointer',
			userSelect: 'none',
		},
	},
	labelFooter: {
		fontSize: 18,
		'& > a': {
			color: theme.palette.primary.main,
		},
	},
	btnOpcionPago: {
		display: 'flex',
		alignItems: 'center',
		borderColor: theme.palette.grey[300],
		borderRadius: 5,
		height: 50,
		width: 350,
		marginBottom: 20,
	},
	arrowForwardIosOutlined: {
		marginLeft: 80,
		fontSize: 20,
	},
	PriceChangeOutlined: {
		marginRight: 10,
	},
	diaSeleccionadoActivo: {
		border: '1px solid rgba(41, 57, 144, 0.4)',
		transition: 'all 0.6s',
		padding: '5px 10px',
		borderRadius: 10,
	},
	diaSeleccionado: {
		border: '1px solid transparent',
		transition: 'all 0.6s',
		padding: '5px 10px',
		borderRadius: 10,
	},
}));
