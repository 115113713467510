import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Grid, Link } from '@mui/material';
import { useContextMoment } from '../../../context';
import { SectionBalance } from '../../SectionBalance';
import { CardBalance } from '../../CardBalance';
import { LabelCardBalance } from '../../LabelCardBalance';
import { formatStringToDate } from '../../../../../utils/dates';

const LiquidadosOtros = () => {
	const { balance } = useContextMoment();

	return (
		<SectionBalance
			title="Crédito Liquidado"
			icon={
				<CheckCircleRoundedIcon
					style={{
						width: 20,
						height: 20,
						color: '#00C951',
						marginLeft: 10,
					}}
				/>
			}
		>
			<>
				<Grid container columns={12} spacing={2}>
					<Grid item xs={12} md={6}>
						<CardBalance title="Tipo de Crédito">
							<LabelCardBalance
								label={balance.liquidado?.tipoCredito || ''}
							/>
						</CardBalance>
					</Grid>
					<Grid item xs={12} md={6}>
						<CardBalance title="Fecha de Liquidación">
							<LabelCardBalance
								label={formatStringToDate(
									balance.liquidado?.fechaLiquidacion || ''
								)}
							/>
						</CardBalance>
					</Grid>
				</Grid>
				<Link
					href="/mi-credito/devolucion-pagos-exceso"
					underline="none"
					style={{
						marginTop: 10,
						color: '#293990',
						fontWeight: 500,
						display: 'flex',
						alignItems: 'center',
					}}
				>
					Si quieres saber si tu crédito tiene pagos en exceso
					consulta la información aquí
					<ArrowForwardIosRoundedIcon
						style={{ width: 16, height: 16 }}
					/>
				</Link>
			</>
		</SectionBalance>
	);
};

export default LiquidadosOtros;
