import CustomAlert from '../../../../../components/CustomAlert';
import { PAYMENT_TYPES } from '../../../utils/PaymentTypes';
import { usePayMyCredit } from '../hook/usePayMyCredit';
import { useStyles } from './styles/MessagePayment.style';

const MessagePayment = () => {
	const { valuePaymentType } = usePayMyCredit();
	const classes = useStyles();
	return (
		<>
			{PAYMENT_TYPES.CODI.value === valuePaymentType && (
				<CustomAlert
					message={
						<div className={classes.container}>
							<p className={classes.title}>
								Si requieres pago con CoDi.
							</p>
							<span className={classes.labelBold}>
								La cantidad máxima por operación al pagar con
								CoDi es de $8,000.00
							</span>{' '}
							y puedes hacer varios pagos, hasta cubrir el monto
							que requieres.
						</div>
					}
					severity="info"
					show={true}
				/>
			)}

			{PAYMENT_TYPES.CARD.value === valuePaymentType && (
				<CustomAlert
					message={
						<div className={classes.container}>
							<p className={classes.title}>
								Si requieres pago con Visa o MasterCard.
							</p>
							<span className={classes.labelBold}>
								La cantidad máxima por operación con tarjeta es
								de $30,000
							</span>{' '}
							y puedes hacer hasta tres pagos en el mes, los
							cuales{' '}
							<span className={classes.labelBold}>
								no deben exceder un total de $90,000.00
							</span>
							, con cualquier tarjeta Visa o MasterCard.
						</div>
					}
					severity="info"
					show={true}
				/>
			)}
		</>
	);
};

export default MessagePayment;
