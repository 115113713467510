import LabelStatus, { ILabelstatus } from './label-status.component';
import PlayButton, { IPlayButton } from './play-button.component';
import { useStyles } from './styles/mini-media-card.styles';
import { COLORS } from '../utils';
import { Elemento } from '../../../../../interfaces/tallerSaberMas';

interface IMediaCard extends IPlayButton, ILabelstatus {
	progress?: number;
	label: string;
	variant: 'green' | 'blue' | 'orange';
	isComplete?: boolean;
	isActiveCard?: boolean;
	tooltip?: JSX.Element;
	addComponent?: JSX.Element;
	elementos?: Elemento[];
}

export default function MiniMediaCard(props: IMediaCard) {
	const getColorBackground = (): string => {
		let color = '';
		switch (props.variant) {
			case 'orange':
				color = '#FFF9E5';
				break;
			case 'blue':
				color = '#EEFAFA';
				break;
			case 'green':
				color = '#F2FEF3';
				break;
			default:
				break;
		}
		return color;
	};

	const getColorLabel = (): string => {
		let color = '';
		switch (props.variant) {
			case 'blue':
				color = COLORS.BLUE;
				break;
			case 'orange':
				color = COLORS.ORANGE;
				break;
			case 'green':
				color = COLORS.GREEN;
				break;
			default:
				break;
		}
		return color;
	};

	const styles = useStyles({
		color: getColorLabel(),
		bColor: getColorBackground(),
	});

	return (
		<div
			className={`${styles.cardVideo} ${props.isPlaying || props.isComplete || props.isActiveCard
					? styles.activeCardVideo
					: ''
				}`}
		>
			<div className={styles.containerCard}>
				<div className={styles.containerHeaderCard}>
					<div className={styles.containerHeaderCardInfo}>
						<label
							style={{
								color: getColorLabel(),
							}}
							className={styles.containerHeaderCardLabelTitle}
						>
							{props.label}
							{props.tooltip}
						</label>
					</div>
				</div>
				<div className={styles.containerHeaderCard}>
					<div className={styles.containerHeaderCardButtons}>
						<PlayButton
							isPlaying={props.isPlaying}
							isComplete={props.isComplete}
							onClick={() => props.onClick()}
						/>
						<LabelStatus variantLabel={props.variantLabel} />
					</div>
				</div>
			</div>
			{props?.addComponent && (
				<div className={styles.addComponent}>{props?.addComponent}</div>
			)}
		</div>
	);
}
