import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
		margin: '25px 0px',

		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
		},
	},
	linkCustom: {
		display: 'inline-flex',
		alignItems: 'center',
		margin: '10px 10px',
		fontSize: 17,

        [theme.breakpoints.down('md')]: {
			margin: '15px 0px !important',
		},
	},
	iconPDF: {
		marginRight: 5,
		width: 22,
	},
}));
