import { Box } from '@mui/material';
import { CardBalance } from '../../../../../components/CardBalance';
import { LabelCardBalance } from '../../../../../components/LabelCardBalance';
import { SectionBalance } from '../../../../../components/SectionBalance';
import CustomButton from '../../../../../../../components/Button';
import { BALANCES_MAIN_PAGE } from '../../../../../utils';
import { useContextMoment } from '../../../../../context';

const CasosEspecialesNpEfDa = () => {
	const { balance, setPageMain } = useContextMoment();
	return (
		<>
			<SectionBalance>
				<CardBalance title="Situación">
					<LabelCardBalance
						label={`Tu crédito ${balance.datosGenerales?.tipoCredito} tiene la situación de ENTREGA DE VIVIENDA CON PODER NOTARIAL`}
					/>
				</CardBalance>
			</SectionBalance>

			<Box width={200} margin={'30px auto'}>
				<CustomButton
					variant="solid"
					label="Regresar"
					onClick={() => setPageMain(BALANCES_MAIN_PAGE.CREDIT)}
				/>
			</Box>
		</>
	);
};

export default CasosEspecialesNpEfDa;
