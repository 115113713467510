import { Paper } from '@mui/material';
import { useStyles } from './styles';
import { useContextMoment } from '../../context';
import { BALANCES_MOMENTS_PAGE, CODE_SOLITARY_MODULE } from '../../utils';
import SolitaryModule from '../../components/SolitaryModule';
import CreditBalance from './components/CreditBalance';

interface IProps {
	handleChageTab: (arg: number) => void;
}

export const CreditBalanceMovements = ({ handleChageTab }: IProps) => {
	const { balance } = useContextMoment();
	const classes = useStyles();

	return (
		<Paper className={classes.containerPaper}>
			<div className={classes.containerCredito}>
				{CODE_SOLITARY_MODULE.includes(
					balance.propiedades?.tipoCaso
				) ? (
					<SolitaryModule pageShow={BALANCES_MOMENTS_PAGE.HOME} />
				) : (
					<CreditBalance handleChageTab={handleChageTab} />
				)}
			</div>
		</Paper>
	);
};
