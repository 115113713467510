/* eslint-disable react-hooks/exhaustive-deps */
// DEPENDENCIES
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Divider, Paper } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// COMPONENTS
import ModalLoading from '../../../../components/ModalLoading';
import CustomAlert from '../../../../components/CustomAlert';
import CustomButton from '../../../../components/Button';
import TextInput from '../../../../components/TextInput';
// RESOUCES
import { encrypt } from '../../../../utils/encrypt';
import { isNumber } from '../../../../utils/expressions';
import { INTERNAL_MESSAGES } from '../../../../config/messageCatalog';
import { defaultReferenceForm, IAlert, reference_base64 } from '../utils';
// ASSETS
import { useStyles } from '../styles';
import { RootState } from '../../../../store';
import { contactRequestAPI } from '../../../../api/modules/profile';
import { useBitacora } from '../../../../hooks/useBitacora';
import { CONTACT_ACTIONS } from '../../../../config/actions-bitacora/Contact-actions';

const ContactReference = () => {
	const { user } = useSelector((state: RootState) => state.session);
	const classes = useStyles();
	const [reference, setReference] = useState(defaultReferenceForm);
	const [errors, setErrors] = useState(defaultReferenceForm);
	const [loader, setLoader] = useState(false);
	const [alert, setAlert] = useState<IAlert>({ message: '', color: 'error' });
	const navigate = useNavigate();
	const { setDataOld, regBitacora } = useBitacora();

	const fetchReferenceData = useCallback(async () => {
		try {
			setLoader(true);
			const USER_NSS_BASE64 = await encrypt(
				user?.nss?.toString() || '',
				user?.k || ''
			);

			contactRequestAPI.fetchReferenceInfo
				.post<{ nss: string }, any>('', {
					nss: USER_NSS_BASE64,
				})
				.then((res: any) => {
					if (res?.result) {
						setReference({
							...res?.result,
							name: res?.result?.name
								.replace(/(\\)/gm, '')
								.toString('ascii'),
							firstSurname: res?.result?.firstSurname
								.replace(/(\\)/gm, '')
								.toString('ascii'),
							secondSurname: res?.result?.secondSurname
								.replace(/(\\)/gm, '')
								.toString('ascii'),
						});
						setDataOld(CONTACT_ACTIONS.UPDATE_REFERENCES, {
							...res?.result,
							name: res?.result?.name
								.replace(/(\\)/gm, '')
								.toString('ascii'),
							firstSurname: res?.result?.firstSurname
								.replace(/(\\)/gm, '')
								.toString('ascii'),
							secondSurname: res?.result?.secondSurname
								.replace(/(\\)/gm, '')
								.toString('ascii'),
						});
					} else {
						setAlert({
							message: INTERNAL_MESSAGES.ERROR_MESSAGE,
							color: 'error',
						});
					}
				})
				.catch((error: any) => {
					setAlert({
						message:
							error?.description ||
							INTERNAL_MESSAGES.ERROR_MESSAGE,
						color: 'error',
					});
				})
				.finally(() => setLoader(false));
		} catch (error: any) {
			setAlert({
				message: error?.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
				color: 'error',
			});
			setLoader(false);
		}
	}, [user?.nss]);

	useEffect(() => {
		fetchReferenceData();
	}, [fetchReferenceData]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		setReference({ ...reference, [name]: value });
		if (!value) setErrors({ ...errors, [name]: 'Campo obligatorio' });
		else if (name === 'phoneNumber' && value.length !== 10) {
			setErrors({ ...errors, [name]: 'Teléfono celular a 10 números. ' });
		} else setErrors({ ...errors, [name]: '' });
	};

	const handleConfirm = async () => {
		try {
			setLoader(true);
			const dataOrigin = {
				...reference,
				nss: user?.nss?.toString() || '',
			};
			const data_base64 = await reference_base64(
				dataOrigin,
				user?.k || ''
			);
			contactRequestAPI.updateReference
				.post<
					{
						nss: string;
						name: string;
						firstSurname: string;
						secondSurname: string;
						phoneNumber: string;
					},
					any
				>('', data_base64)
				.then((res) => {
					if (res?.ok) {
						setAlert({
							message: INTERNAL_MESSAGES.APPLICATION_COMPLETED,
							color: 'success',
						});
						regBitacora({
							key: CONTACT_ACTIONS.UPDATE_REFERENCES,
							scheme: contactRequestAPI.updateReference.scheme,
							valorNuevo: reference,
						});
					} else {
						setAlert({
							message: INTERNAL_MESSAGES.ERROR_MESSAGE,
							color: 'error',
						});
					}
				})
				.catch((error) => {
					setAlert({
						message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
						color: 'error',
					});
				})
				.finally(() => setLoader(false));
		} catch (error: any) {
			setAlert({
				message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
				color: 'error',
			});
		}
	};

	const handleClose = () => {
		setAlert({ message: '', color: 'success' });
	};

	return (
		<>
			<ModalLoading loading={loader} />
			<Paper className={classes.paperContainer}>
				<Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
					<span className={classes.tituloAcordion}>
						Datos de referencia
					</span>
				</Box>
				<Divider />
				<div className={classes.mailformContainer}>
					<TextInput
						isRequired
						label="Nombre"
						id="name"
						name="name"
						value={reference.name}
						error={Boolean(errors.name)}
						helperText={errors.name}
						onChange={handleChange}
					/>
					<TextInput
						isRequired
						label="Apellido paterno"
						id="firstSurname"
						name="firstSurname"
						value={reference.firstSurname}
						error={Boolean(errors.firstSurname)}
						helperText={errors.firstSurname}
						onChange={handleChange}
					/>
					<TextInput
						isRequired
						label="Apellido materno"
						id="secondSurname"
						name="secondSurname"
						value={reference.secondSurname}
						error={Boolean(errors.secondSurname)}
						helperText={errors.secondSurname}
						onChange={handleChange}
					/>
					<TextInput
						isRequired
						label="Teléfono celular"
						id="phoneNumber"
						name="phoneNumber"
						value={reference.phoneNumber}
						error={Boolean(errors.phoneNumber)}
						helperText={errors.phoneNumber}
						onChange={(event) =>
							isNumber(event.target.value) && handleChange(event)
						}
					/>
				</div>
				<CustomAlert
					show={Boolean(alert.message)}
					severity={alert.color}
					message={alert.message}
					onClose={handleClose}
				/>
				<div className={classes.actionsContainer}>
					<CustomButton
						disabled={false}
						variant="outlined"
						onClick={() => navigate('/')}
						label="Salir"
					/>
					<CustomButton
						disabled={
							!Boolean(
								reference.name &&
								reference.firstSurname &&
								reference.secondSurname &&
								reference.phoneNumber
							) || Boolean(alert.message)
						}
						variant="solid"
						onClick={handleConfirm}
						label="Guardar cambios"
					/>
				</div>
				<div className={classes.containerRequiredFields}>
					<Divider />
					<p className={classes.requiredFieldsLabel}>
						* Datos obligatorios
					</p>
				</div>
			</Paper>
		</>
	);
};

export default ContactReference;
