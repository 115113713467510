import { useEffect, useState } from 'react';
import { IDataBalanceMoment } from '../../../interfaces/balanceMoment';
import { defaultDataContext } from '../interfaces';
import { INTERNAL_MESSAGES } from '../../../config/messageCatalog';
import { balancesMovementsAPI } from '../../../api/modules/BalancesMovements';
import { encodeAllJSONData } from '../../../utils/encrypt';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';
import { BALANCES_MAIN_PAGE } from '../utils';

export const useBalanceMovements = (initial?: boolean) => {
	const { credit } = useSelector((state: RootState) => state.credit);
	const { user } = useSelector((state: RootState) => state.session);
	const [message, setMessage] = useState('');
	const [loading, setLoading] = useState(false);
	const [pageMain, setPageMain] = useState(BALANCES_MAIN_PAGE.CREDIT);
	const [openPaperless, setOpenPaperless] = useState(false);
	const [certificado, setCertificado] = useState('');
	const [messageCreditLiquid, setMessageCreditLiquid] = useState<
		Array<{ descripcion: string }>
	>([]);
	const [modalCreditLiquid, setModalCreditLiquid] = useState(false);
	const [balance, setBalance] =
		useState<IDataBalanceMoment>(defaultDataContext);

	const getInitialData = async () => {
		try {
			setLoading(true);
			const dataOrigin = {
				credito: credit,
			};
			const dataEncrypted = await encodeAllJSONData(
				{ data: JSON.stringify(dataOrigin) },
				user?.k || ''
			);
			const { code, data, message } = await balancesMovementsAPI.getData(
				dataEncrypted
			);
			if (Number(code) === 0) {
				setBalance(data);
				return;
			}
			setMessage(message || INTERNAL_MESSAGES.ERROR_MESSAGE);
		} catch (error) {
			setMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);
		} finally {
			setLoading(false);
		}
	};

	const acceptPaperless = async () => {
		try {
			setLoading(true);
			const dataOrigin = {
				credito: credit,
			};
			const dataEncrypted = await encodeAllJSONData(
				{ data: JSON.stringify(dataOrigin) },
				user?.k || ''
			);
			await balancesMovementsAPI.acceptPaperless(dataEncrypted);
			setOpenPaperless(false);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const getPaperless = async () => {
		try {
			const dataOrigin = {
				credito: credit,
			};
			const dataEncrypted = await encodeAllJSONData(
				{ data: JSON.stringify(dataOrigin) },
				user?.k || ''
			);
			const { status } = await balancesMovementsAPI.getStatusPaperless(
				dataEncrypted
			);
			setOpenPaperless(!status);
		} catch (error) {}
	};

	const getCertificadoCalidad = async () => {
		try {
			const dataOrigin = {
				credito: credit,
			};
			const dataEncrypted = await encodeAllJSONData(
				{ data: JSON.stringify(dataOrigin) },
				user?.k || ''
			);
			const { result } = await balancesMovementsAPI.getCertificadoCalidad(
				dataEncrypted
			);
			if (Number(result.codigo) === 0) {
				setCertificado(result.urlPoliza);
			}
		} catch (error) {}
	};

	const getAlertCreditLiquid = async () => {
		try {
			const data = {
				credito: credit,
			};
			const dataEncripted = await encodeAllJSONData(
				{ data: JSON.stringify(data) },
				user?.k || ''
			);
			const { result } = await balancesMovementsAPI.getAlertCreditLiquid(
				dataEncripted
			);
			if (result && Number(result.code) === 0) {
				setMessageCreditLiquid(result.data?.avisos);
				setModalCreditLiquid(true);
			}
		} catch {}
	};

	useEffect(() => {
		if (initial) {
			const init = async () => {
				await Promise.allSettled([
					getInitialData(),
					getPaperless(),
					getCertificadoCalidad(),
					getAlertCreditLiquid(),
				]);
			};
			init();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		balance,
		message,
		loading,
		pageMain,
		openPaperless,
		certificado,
		messageCreditLiquid,
		modalCreditLiquid,
		setPageMain,
		setOpenPaperless,
		acceptPaperless,
		setModalCreditLiquid,
	};
};
