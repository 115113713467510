import { Box, Grid } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { SectionBalance } from '../../../components/SectionBalance';
import { TOOLTIPS } from '../../../utils/TooltipsUtils';
import { CardBalance } from '../../../components/CardBalance';
import { LabelCardBalance } from '../../../components/LabelCardBalance';
import { formatStringToDate } from '../../../../../utils/dates';
import { LabelCardBalanceValues } from '../../../components/LabelCardBalanceValues';
import { getValNumberOrNull } from '../../../utils';
import ListOverduePayments from '../../../components/ListOverduePayments';
import { useContextMoment } from '../../../context';
import { useStyles } from '../styles';
import { useState } from 'react';

const MensualidadesVencidas = () => {
	const classes = useStyles();
	const { balance } = useContextMoment();
	const [showMensualidades, setShowMensualidades] = useState(false);
	return (
		<>
			{balance.propiedades?.esOmiso && (
				<SectionBalance
					title="Mensualidades vencidas"
					tooltip={TOOLTIPS.MENSUALIDADES_VENCIDAS}
				>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={6} lg={4}>
							<CardBalance title="Mensualidades vencidas">
								<LabelCardBalance
									label={
										balance?.mensualidadesVencidas
											?.mensualidadesVencidas || ''
									}
								/>
							</CardBalance>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={4}>
							<CardBalance title="Fecha límite de pago">
								<LabelCardBalance
									label={
										balance?.mensualidadesVencidas
											?.mensualidadesVencidas
											? formatStringToDate(
													balance
														?.mensualidadesVencidas
														?.fechaLimitePago
											  )
											: ''
									}
								/>
							</CardBalance>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={4}>
							<CardBalance title="¿Cuánto debo?">
								<LabelCardBalanceValues
									variant="primary"
									vsmValue={getValNumberOrNull(
										balance?.mensualidadesVencidas
											?.cuantoDeboVsm
									)}
									mxnValue={Number(
										balance?.mensualidadesVencidas
											?.cuantoDebo || '0'
									)}
								/>
							</CardBalance>
						</Grid>
						{balance.mensualidadesVencidas?.desglose && (
							<Grid item xs={12}>
								<Box className={classes.labelList}>
									<div
										onClick={() =>
											setShowMensualidades(
												!showMensualidades
											)
										}
									>
										Desglose de mensualidades vencidas{' '}
										<KeyboardArrowDown
											style={{
												transition: '0.2s',
												transform: showMensualidades
													? 'rotate(180deg)'
													: 'rotate(0deg)',
											}}
										/>
									</div>
								</Box>
								<ListOverduePayments
									show={showMensualidades}
									balance={balance}
								/>
							</Grid>
						)}
					</Grid>
				</SectionBalance>
			)}
		</>
	);
};

export default MensualidadesVencidas;
