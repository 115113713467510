import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Radio } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ModalLoading from '../../../../../components/ModalLoading';
import { CODES } from '../../../utils';
import CustomAlert from '../../../../../components/CustomAlert';
import SelectCustom from '../../../../../components/SelectCustom';
import CustomButton from '../../../../../components/Button';
import { balancesMovementsAPI } from '../../../../../api/modules/BalancesMovements';
import { encodeAllJSONData } from '../../../../../utils/encrypt';
import { downloadPDF } from '../../../../../utils/downloadPDF';
import { INTERNAL_MESSAGES } from '../../../../../config/messageCatalog';
import { RootState } from '../../../../../store';
import { useStyles } from './styles/StatusAccount.style';
import { useContextMoment } from '../../../context';

const StatusComponent = () => {
	const { balance } = useContextMoment();
	const styles = useStyles();
	const { user } = useSelector((state: RootState) => state.session);
	const { credit } = useSelector((state: RootState) => state.credit);
	const [option, setOption] = useState(0);
	const [mesSelect, setMesSelect] = useState<string | number | null>(null);
	const [meses, setMeses] = useState([]);
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState('');

	const handleMensual = async () => {
		try {
			setLoading(true);
			setMessage('');
			const dataOrigin = {
				credito: credit,
				periodo: mesSelect,
			};
			const dataEncrypted = await encodeAllJSONData(
				{ data: JSON.stringify(dataOrigin) },
				user?.k || ''
			);
			const { data, message } =
				await balancesMovementsAPI.getReporteMensual(dataEncrypted);
			if (data?.pdfBase64) {
				downloadPDF({
					payload: data?.pdfBase64,
					name: 'ReporteMensual',
				});
				return;
			}
			setMessage(message || INTERNAL_MESSAGES.ERROR_MESSAGE);
		} catch (error) {
			setMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);
		} finally {
			setLoading(false);
		}
	};

	const handleHistorico = async () => {
		try {
			setLoading(true);
			setMessage('');
			const dataOrigin = {
				credito: credit,
			};
			const dataEncrypted = await encodeAllJSONData(
				{ data: JSON.stringify(dataOrigin) },
				user?.k || ''
			);
			const { data, message } =
				await balancesMovementsAPI.getReporteHistorico(dataEncrypted);
			if (data?.pdfBase64) {
				downloadPDF({
					payload: data?.pdfBase64,
					name: 'ReporteHistorico',
				});
				return;
			}
			setMessage(message || INTERNAL_MESSAGES.ERROR_MESSAGE);
		} catch (error) {
			setMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);
		} finally {
			setLoading(false);
		}
	};

	const getMeses = async () => {
		try {
			setLoading(true);
			const dataOrigin = {
				credito: credit,
			};
			const dataEncripted = await encodeAllJSONData(
				{ data: JSON.stringify(dataOrigin) },
				user?.k || ''
			);
			const { data, message } =
				await balancesMovementsAPI.getPeriodoMensual(dataEncripted);
			if (data?.periodos) {
				setMeses(
					data?.periodos.map((val: string) => {
						return {
							label: val,
							value: val,
						};
					})
				);
				return;
			}
			setMessage(message || INTERNAL_MESSAGES.ERROR_MESSAGE);
		} catch (error) {
			setMeses([]);
			setMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);
		} finally {
			setLoading(false);
		}
	};

	const handleChange = (arg: number) => {
		setOption(arg);
		setMesSelect(null);
		setMessage('');
		if (arg === 1 && meses.length === 0) {
			getMeses();
		}
	};

	return (
		<>
			<ModalLoading loading={loading} />
			<h3>
				{[CODES.LIQUIDADOS_POR_PAGO, CODES.LIQUIDADOS_OTROS].includes(
					balance.propiedades?.tipoCaso
				)
					? 'Tu crédito esta liquidado, puedes descargar tu Estado de Cuenta Historio'
					: 'Selecciona el estado de cuenta que quieres descargar'}
			</h3>
			<div className={styles.container}>
				{![CODES.LIQUIDADOS_POR_PAGO, CODES.LIQUIDADOS_OTROS].includes(
					balance.propiedades?.tipoCaso
				) && (
					<div className={styles.containerItem}>
						<div>
							<Radio
								value="1"
								checked={option === 1}
								name="option"
								onChange={() => handleChange(1)}
							/>
						</div>
						<div className={styles.containerSubItem}>
							<h3>Mensual</h3>
							<p>
								<InfoIcon
									style={{
										fontSize: 15,
										marginRight: 5,
									}}
								/>
								Movimientos de tu crédito mes con mes. Se
								actualiza el dia 10 de cada mes, pero con corte
								al ultimo dia del mes anterior
							</p>
						</div>
					</div>
				)}
				<div className={styles.containerItem}>
					<div>
						<Radio
							value="2"
							checked={option === 2}
							name="option"
							onChange={() => handleChange(2)}
						/>
					</div>
					<div className={styles.containerSubItem}>
						<h3>Histórico</h3>
						<p>
							<InfoIcon
								style={{ fontSize: 15, marginRight: 5 }}
							/>
							Movimientos desde el inicio de tu crédito. Se
							actualiza el dia 10 de cada mes.
						</p>
					</div>
				</div>
			</div>
			<CustomAlert
				message={message}
				severity="warning"
				show={Boolean(message)}
			/>
			{option === 1 && (
				<div className={styles.containerButtons}>
					<div className={styles.select}>
						<SelectCustom
							options={meses}
							label="Periodo:"
							placeholder={'Selecciona mes'}
							onChange={(arg) => setMesSelect(arg.target.value)}
						/>
					</div>
					<CustomButton
						label={'Descargar'}
						onClick={handleMensual}
						disabled={!mesSelect}
						classNames={styles.button}
					/>
				</div>
			)}
			{option === 2 && (
				<div className={styles.containerButtons}>
					<CustomButton
						label={'Descargar'}
						onClick={handleHistorico}
						classNames={styles.button}
					/>
				</div>
			)}
		</>
	);
};

export default StatusComponent;
