import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
	container: {
		textAlign: 'center',
		fontSize: 16,

		[theme.breakpoints.down('md')]: {
			fontSize: 14,
		},
	},
	title: {
		color: 'rgb(41, 57, 144)',
		fontSize: 30,
		margin: 0,
	},
	legends: {
		fontWeight: 500,
		margin: 0,
	},
	divider: {
		margin: '25px 0px !important',
	},
	containerButton: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'row',

		[theme.breakpoints.down('md')]: {
			flexDirection: 'column-reverse',
		},
	},
	buttons: {
		width: 200,
		display: 'inline-flex',
		margin: '8px 10px',

		[theme.breakpoints.down('md')]: {
			width: '100%',
			display: 'flex',
		},
	},
}));
