/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { parseISO, differenceInSeconds } from 'date-fns';

interface Props {
	signOff: () => void;
	active: () => void;
	timeToExpire: number;
	isLogged: boolean;
	expiresIn: string;
}

const timeInactividad = 1000 * 600;
const timeToModal = 1000 * 60;

export default function useIdle({
	signOff,
	active,
	timeToExpire,
	isLogged,
	expiresIn,
}: Props) {
	const timeout: number = timeToExpire > 0 ? timeToExpire : timeInactividad; //tiempo en milisegundos de espera de inactividad
	const promptBeforeIdle: number = timeToModal; //cantidad de milisegundos antes del tiempo de inactividad
	const [openModalSession, setOpenModalSession] = useState(false);
	const [remaining, setRemaining] = useState<number>(0);
	const [refreshToken, setRefreshToken] = useState<number>(0);
	const [isNearExpiration, setIsNearExpiration] = useState<boolean>(false);

	useEffect(() => {
		if (isLogged) {
			const expirationDate = parseISO(expiresIn);
			const checkExpiration = () => {
				const now = new Date();
				const diffInSeconds = differenceInSeconds(expirationDate, now);
				if (diffInSeconds <= 120) {
					// Si faltan menos de 2 minutos
					setIsNearExpiration(true);
				} else {
					setIsNearExpiration(false);
				}
			};
			const interval = setInterval(checkExpiration, 1000);
			return () => clearInterval(interval);
		}
	}, [expiresIn, isLogged]);

	useEffect(() => {
		if (isLogged) {
			const ejecute = () => {
				active();
				setRefreshToken(refreshToken + 1);
			};
			const interval = setInterval(ejecute, timeout - promptBeforeIdle);
			return () => {
				clearInterval(interval);
			};
		}
	}, [refreshToken]);

	useEffect(() => {
		active();
	}, []);

	const onPrompt = () => {
		if (isLogged) {
			setOpenModalSession(true);
			setRemaining(promptBeforeIdle);
		}
	};
	const onIdle = () => {
		if (isLogged) {
			setOpenModalSession(false);
			setRemaining(0);
			signOff();
		}
	};
	const onActive = () => {
		if (isLogged) {
			setOpenModalSession(false);
			setRemaining(0);
		}
	};
	const onAction = (event?: Event) => {
		//console.log(event);
	};
	const { getRemainingTime, isPrompted, activate } = useIdleTimer({
		onAction,
		timeout,
		promptBeforeIdle,
		onPrompt,
		onIdle,
		onActive,
	});

	useEffect(() => {
		if (isNearExpiration) {
			//renovar el token
			active();
		}
	}, [isNearExpiration]);

	return {
		getRemainingTime,
		isPrompted,
		activate,
		remaining,
		setRemaining,
		openModalSession,
		setOpenModalSession,
		onActive,
	};
}
