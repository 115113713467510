import { useState } from 'react';
import { Divider } from '@mui/material';
// COMPONENTS
import CustomAlert from '../../../components/CustomAlert';
import CustomButton from '../../../components/Button';
import TextInput from '../../../components/TextInput';
// RESOURCES
import { IAlert } from '../../../interfaces/alert';
import { useStyles } from './styles';
import { keyAPI } from '../../../api/modules/key';
import { encrypt } from '../../../utils/encrypt';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { returnOverpaymentsAPI } from '../../../api/modules/returnOverpayments';
import { IDataInfo } from '../utils';
import InfoIcon from '@mui/icons-material/Info';
import { useBitacora } from '../../../hooks/useBitacora';

interface Props {
	onContinue: any;
	onReturn: any;
	setLoading: (arg: boolean) => void;
	setDataInfo: (arg: IDataInfo) => void;
	dataInfo: IDataInfo;
}

interface ICredentials {
	nss: string;
	clabe: string;
	clabe2: string;
}

const ClabeView = ({
	onContinue,
	onReturn,
	setLoading,
	setDataInfo,
	dataInfo,
}: Props) => {
	const { regBitacoraNotOld } = useBitacora();
	const classes = useStyles();
	const { user } = useSelector((state: RootState) => state.session);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [credentials, setCredentials] = useState<ICredentials>({
		nss: '',
		clabe: '',
		clabe2: '',
	});

	const getCheckBank = async () => {
		try {
			setLoading(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(user?.nss.toString() || '', key);
			const clabe_base64 = await encrypt(credentials.clabe, key);
			returnOverpaymentsAPI
				.validClabe(nss_base64, clabe_base64)
				.then((responseClabe) => {
					const { banco, clabe, nombre, data, descripcion } =
						responseClabe.result;
					if (data && clabe === credentials.clabe) {
						regBitacoraNotOld(returnOverpaymentsAPI.schemeUrl, {
							nss: user?.nss.toString(),
							clabe: credentials.clabe,
						});
						setDataInfo({
							...dataInfo,
							banco,
							clabe,
							nombre,
						});
						onContinue();
						return;
					}
					setAlert({
						show: true,
						message:
							descripcion ||
							'Por el momento el servicio no se encuentra disponible, intenta más tarde',
						severity: 'error',
					});
				})
				.catch((errorClabe) => {
					setLoading(false);
					setAlert({
						show: true,
						message:
							errorClabe?.error?.descripcion ||
							'Por el momento el servicio no se encuentra disponible, intenta más tarde',
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setLoading(false);
			setAlert({
				show: true,
				message:
					'Ha ocurrido un error de conexión al obtener datos, intente nuevamente',
				severity: 'error',
			});
		}
	};

	return (
		<>
			<div className={classes.containerFirma}>
				<div>
					<div>
						<div className={classes.contentFile}>
							<div className={classes.contentFileData}>
								<p>
									Para hacer la devolución ingresa los
									siguientes datos bancarios
								</p>
								<br></br>
							</div>
						</div>
						<div className={classes.contentFile}>
							<div className={classes.contentFileData}>
								<h4
									className={classes.contentFileDataTitle}
									style={{ margin: '15px 0px 0px 0px' }}
								>
									Clave bancaria estandarizada (CLABE)&nbsp;
									<b style={{ color: '#DD0528' }}>*</b>
								</h4>
							</div>
						</div>
						<div
							style={{
								color: '#283990',
								marginBottom: '30px',
								display: 'flex',
								fontSize: 14,
								alignItems: 'center',
							}}
						>
							<InfoIcon
								sx={{ fontSize: 17, marginRight: '5px' }}
							/>
							Verifica que la CLABE sea igual a la de tu estado de
							cuenta bancario.
						</div>
					</div>
					<div className={classes.contentFormClabe}>
						<TextInput
							id="clabe"
							name="clabe"
							value={credentials.clabe}
							placeholder="Ingresa tu CLABE"
							onChange={(e) =>
								setCredentials({
									...credentials,
									[e.target.name]: e.target.value,
								})
							}
							InputProps={{
								sx: {
									'& input': {
										color: '#636e72',
									},
								},
							}}
						/>
						<TextInput
							id="clabe2"
							name="clabe2"
							value={credentials.clabe2}
							placeholder="Confirma tu CLABE"
							onChange={(e) =>
								setCredentials({
									...credentials,
									[e.target.name]: e.target.value,
								})
							}
							InputProps={{
								sx: {
									'& input': {
										color: '#636e72',
									},
								},
							}}
						/>
						<CustomAlert
							show={alert.show}
							severity={alert.severity}
							message={alert.message}
						/>
					</div>
					<div className={classes.contentFileDataButtons}>
						<CustomButton
							onClick={() => {
								onReturn();
							}}
							variant="outlined"
							styles={{
								marginTop: 30,
								width: 200,
								marginInline: 10,
							}}
							label="Regresar"
						/>
						<CustomButton
							onClick={() => {
								getCheckBank();
							}}
							variant="solid"
							disabled={
								credentials.clabe === '' ||
								credentials.clabe2 === '' ||
								credentials.clabe.length !== 18 ||
								credentials.clabe2.length !== 18 ||
								credentials.clabe !== credentials.clabe2
									? true
									: false
							}
							styles={{
								marginTop: 30,
								width: 200,
								marginInline: 10,
							}}
							label="Continuar"
						/>
					</div>
					<Divider style={{ marginBlock: 20 }} />
					<h3 className={classes.principalTitle}>
						* Datos obligatorios
					</h3>
				</div>
			</div>
		</>
	);
};

export default ClabeView;
