import { SvgIconComponent } from '@mui/icons-material';
import { useStyles } from './styles/play-button.styles';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';

export interface IPlayButton {
	onClick: () => void;
	isPlaying?: boolean;
	isComplete?: boolean;
	isCustom?: boolean;
	label?: string;
	IconCustom?: SvgIconComponent;
}

export default function PlayButton({
	onClick,
	isPlaying,
	isComplete,
	label,
	IconCustom,
}: IPlayButton) {
	const styles = useStyles({ isComplete, isPlaying });
	return (
		<button onClick={onClick} className={styles.buttonContainer}>
			{isPlaying ? (
				<>
					<label>En reproducción</label>
					<QueueMusicIcon
						style={{
							fontSize: 20,
							marginLeft: 10,
						}}
					/>
				</>
			) : isComplete ? (
				<>
					<label>Ver otra vez</label>
					{IconCustom ? (
						<IconCustom
							style={{
								fontSize: 20,
								marginLeft: 10,
							}}
						/>
					) : (
						<PlayCircleIcon
							style={{
								fontSize: 20,
								marginLeft: 10,
							}}
						/>
					)}
				</>
			) : (
				<>
					<label>{label || 'Ver tema'}</label>
					{IconCustom ? (
						<IconCustom
							style={{
								fontSize: 20,
								marginLeft: 10,
							}}
						/>
					) : (
						<PlayCircleIcon
							style={{
								fontSize: 20,
								marginLeft: 10,
							}}
						/>
					)}
				</>
			)}
		</button>
	);
}
