import { Badge, Link } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useStyles } from './styles';
import TooltipCustom from '../../../components/TooltipCustom';
import { Info } from '@mui/icons-material';
import tramiteLinea from '../../../assets/svg/tramiteLinea.svg';
import expediente from '../../../assets/svg/expediente.svg';
import cancela from '../../../assets/svg/cancela.svg';
import movil from '../../../assets/svg/movil.svg';
import inscribe from '../../../assets/svg/inscribe.svg';

import HelpPanel from '../../HelpPanel';
import { useController } from '../../../hooks/useController';
import { CREDIT_TYPES } from '../../../constants/products';
import { useNavigate } from 'react-router-dom';
import { ConstantSteps } from '../constantSteps/constantSteps';

export const MejoraTuHogarSteps = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const { verificaTusDatos, formalizaCredito } = ConstantSteps();

	const { dataProcessController } = useController();

	const CBPDVFile =
		dataProcessController.processInfo?.tipoMVIT ===
		CREDIT_TYPES.MEJORAVIT_REPARA
			? require('../../../../../assets/files/guia-pasos-a-seguir/CBPDV_MEJORAVIT_Repara_vf_18.04.23.pdf')
			: dataProcessController.processInfo?.tipoMVIT ===
			  CREDIT_TYPES.MEJORAVIT_RENUEVA
			? require('../../../../../assets/files/guia-pasos-a-seguir/CBPDV_MEJORAVIT_Renueva_vf_18.04.23.pdf')
			: '';

	return [
		verificaTusDatos,
		{
			paperType: 'viewMore',
			paperIcon: expediente,
			title: 'Integra tu expediente',
			description: 'Reúne tus documentos para tramitar tu crédito',
			viewMoreButton: 'Consulta la documentación',
			viewMoreContent: (
				<>
					<div className={classes.viewMoreContent}>
						<b>Reúne estos documentos.</b>
						<ul className={classes.viewMoreList}>
							<li>
								Solicitud de inscripción de crédito.{' '}
								<Link
									underline="hover"
									href={require('../../../../../assets/files/guia-pasos-a-seguir/solicitud_mejoravit_repara_renueva.pdf')}
									target="_blank"
								>
									Descárgala aquí
								</Link>
							</li>
              <li>
                Original y copia de identificación
                oficial vigente. Puede ser pasaporte
                o credencial de elector. En caso de
                presentar INE o IFE la copia debe estar
                al 200%.
              </li>
							<li>Acta de nacimiento en original y copia.</li>
							<li>Impresión o copia del CURP.</li>
							<li>Impresión o copia de la cédula fiscal RFC.</li>
							<li>
								Carta bajo protesta de decir verdad en la que
								manifiestes que te comprometes a realizar la
								mejora de tu vivienda acorde a lo establecido,
								así como a utilizar adecuadamente el importe
								destinado para pago de mano de obra.{' '}
								<Link
									underline="hover"
									href={CBPDVFile}
									target="_blank"
								>
									Descárgala aquí
								</Link>
							</li>
							<li>
								Comprobante de domicilio de la vivienda a
                reparar o mejorar en original y copia.
                Comprobantes autorizados{' '}
								<Badge
									badgeContent={
										<HelpPanel type="COMPROBANTES_AUTORIZADOS" />
									}
									className={classes.helpPanel}
								></Badge>
							</li>
							<li>
                Evidencia fotográfica del estado inicial de la vivienda a mejorar. Se solicitará una fotografía donde se muestre la habitación o espacio a mejorar de la vivienda.
							</li>
							<li>
                Presupuesto inicial de la mejora a realizar,
                en el formato de su conveniencia o descargar
                el formato a través de Mi Cuenta Infonavit.{' '}
								<Link
									underline="hover"
									href={require('../../../../../assets/files/guia-pasos-a-seguir/Formato_de_Presupuesto_Proyecto_de_Obra.pdf')}
									target="_blank"
								>
									Descárgala aquí
								</Link>
							</li>
							<li>
								Presupuesto de la Notaría para los tramites de
								regularización de vivienda, en hoja membretada,
								sellada y con CLABE para pago a la Notaría (solo
								en caso de aplicar).
							</li>
							<li>
								Copia del documento que acredita el tipo de
								vivienda donde se realizará la mejora.{' '}
								<Link
									underline="hover"
									href={require('../../../../../assets/files/guia-pasos-a-seguir/Documentos_para_acreditar_legitima_tenenci.pdf')}
									target="_blank"
								>
									Descárgala aquí
								</Link>
							</li>
						</ul>
					</div>
				</>
			),
		},
		{
			paperType: 'blue',
			paperIcon: tramiteLinea,
			title: 'Solicita tu crédito en las oficinas de Infonavit',
			description: (
				<>
					Al ingresar a Mi Cuenta Infonavit, en el menú
          del lado superior, puedes agenda tu cita para
          acudir al Centro de Servicio Infonavit (CESI)
          más cercano.
				</>
			),
			link: (
        <a
          onClick={() => navigate('/contactanos/haz-una-cita')}
          className={classes.optionEnlace}
        >
          Hacer una cita <ChevronRightIcon />{' '}
        </a>
			),
		},
		formalizaCredito,
    {
			paperType: 'simple',
			paperIcon: inscribe,
			title: 'Inscribe tu crédito en Mi Cuenta Infonavit',
			description: (
				<>
					Si cuentas con expediente completo y deseas realizar
          la inscripción de tu crédito en línea, debes acceder
          a Mi Cuenta Infonavit, realizar tu precalificación,
          seleccionar la opción “Solicitar en línea” y comenzar
          con el registro.
				</>
			),
			link: (
				<Link
					underline="none"
					onClick={() =>
						navigate('/precalificacion-puntos/credito-en-linea')
					}
					className={classes.optionEnlace}
				>
					Solicitar en línea{' '}
					<ChevronRightIcon />{' '}
				</Link>
			),
		},
    {
			paperType: 'simple',
			paperIcon: cancela,
			title: 'Cancela tu crédito',
			description: (
				<>
          En caso de que requieras cancelar el trámite en línea
          de tu crédito, deberás acceder nuevamente a Mi Cuenta
          Infonavit en la sección “Quiero un crédito”, opción
          “Precalificación y puntos” y dar clic en “Cancelar mi
          solicitud de crédito”.
				</>
			),
		},
    {
			paperType: 'simple',
			paperIcon: movil,
			title: 'Descarga la Aplicación móvil Infonavit (App MCI)',
			description: (
				<>
					Si cuentas con expediente completo y deseas enviarlo
          para su validación por parte de personal de iNFONAVIT.
          <br />
          <br />
          Una vez que el personal del Infonavit haya validado tu
          información, deberás presentarte con la documentación
          completa en original y copia mediante cita en el Centro
          de Servicio Infonavit más cercano, para inscribir tu trámite.
		  <br />
		  <b>Puedes agendar tu cita a través de Mi Cuenta Infonavit.</b>
				</>
			),
		},
	];
};
