import { useStyles } from './styles';

interface ILabelCardBalance {
	label: string;
	subTitle?: string;
}
export const LabelCardBalance = ({ label, subTitle }: ILabelCardBalance) => {
	const classes = useStyles();
	return (
		<div className={classes.container}>
			<label className={classes.label}>{label}</label>
			{subTitle && <label className={classes.tubTitle}>{subTitle}</label>}
		</div>
	);
};
