import { useContextMoment } from '../../../context';
import { TOOLTIPS } from '../../../utils/TooltipsUtils';
import { CardBalance } from '../../CardBalance';
import { LabelCardBalance } from '../../LabelCardBalance';
import { SectionBalance } from '../../SectionBalance';

const RecuperacionDemandadoSinConvenio = () => {
	const { balance } = useContextMoment();
	return (
		<>
			<SectionBalance
				title={'Monto esperado'}
				tooltip={TOOLTIPS.MONTO_ESPERADO}
			>
				<CardBalance title="Situación">
					<LabelCardBalance
						label={
							balance.propiedades.mensajes
								.find((f) => f.key === '07')
								?.value.replace(
									'%s',
									balance.montoEsperado?.despachoCobranza ||
										''
								) || ''
						}
					/>
				</CardBalance>
			</SectionBalance>
		</>
	);
};

export default RecuperacionDemandadoSinConvenio;
