import { useSelector } from 'react-redux';
import { useContext, useState } from 'react';
import { PayContext } from '../context/PayProvider';
import { useContextMoment } from '../../../context';
import { INTERNAL_MESSAGES } from '../../../../../config/messageCatalog';
import { encodeAllJSONData } from '../../../../../utils/encrypt';
import { RootState } from '../../../../../store';
import { balancesMovementsAPI } from '../../../../../api/modules/BalancesMovements';
import { PAYMENT_TYPES, PAYMENTS } from '../../../utils/PaymentTypes';
import { PAGE_PAY_CREDIT } from '../utils';

export const usePayMyCredit = () => {
	const {
		valuePayment,
		setValuePayment,
		valuePaymentType,
		setValuePaymentType,
		rejected,
		setRejected,
		valuePaymentOther,
		setValuePaymentOther,
		setPagePay,
	} = useContext(PayContext);
	const { credit } = useSelector((state: RootState) => state.credit);
	const { user } = useSelector((state: RootState) => state.session);
	const { balance, setPageMain } = useContextMoment();
	const [loading, setLoading] = useState(false);
	const [openPanel, setOpenPanel] = useState(false);

	const handleChangeValuePayment = ({
		target,
	}: React.ChangeEvent<HTMLInputElement>) => {
		setValuePayment(target.value);
		setValuePaymentOther('');
	};

	const handleChangeValuePaymentType = ({
		target,
	}: React.ChangeEvent<HTMLInputElement>) => {
		setValuePaymentType(target.value);
	};

	const getParamPayment = () => {
		let dataOrigin = {
			credito: credit,
			monto: '',
		};
		if (valuePayment === PAYMENTS.TO_BE_CURRENT.value) {
			dataOrigin.monto =
				balance.pagos?.opciones.pagoCorriente?.trim() || '0';
		}
		if (valuePayment === PAYMENTS.EXPECTED_AMOUNT.value) {
			dataOrigin.monto =
				balance.pagos?.opciones.pagoMontoEsperado?.trim() || '0';
		}
		if (valuePayment === PAYMENTS.OVERDUE_MONTHLY_INSTALLMENTS.value) {
			dataOrigin.monto =
				balance.pagos?.opciones.pagoMontoMensualidadesVencidas?.trim() ||
				'0';
		}
		if (valuePayment === PAYMENTS.ANOTHER.value) {
			dataOrigin.monto = valuePaymentOther.trim();
		}
		return dataOrigin;
	};

	const paymentCodi = async () => {
		try {
			setLoading(true);
			const dataEncrypted = await encodeAllJSONData(
				{ data: JSON.stringify(getParamPayment()) },
				user?.k || ''
			);
			const { code, data, message } = await balancesMovementsAPI.pagoCodi(
				dataEncrypted
			);
			if (code === '0000' && data?.url) {
				let html = window.atob(data?.url);
				let wnd = window.open('about:blank', '_blank');
				wnd?.document.write(`${html}`);
				wnd?.document.close();
				return;
			}
			setRejected(message || INTERNAL_MESSAGES.ERROR_MESSAGE);
			setPagePay(PAGE_PAY_CREDIT.REJECTED_PAYMENT);
		} catch (error: any) {
			setRejected(error?.description || INTERNAL_MESSAGES.ERROR_MESSAGE);
			setPagePay(PAGE_PAY_CREDIT.REJECTED_PAYMENT);
		} finally {
			setLoading(false);
		}
	};

	const paymentBBVA = async () => {
		try {
			setLoading(true);
			const dataEncrypted = await encodeAllJSONData(
				{ data: JSON.stringify(getParamPayment()) },
				user?.k || ''
			);
			const { code, data, message } = await balancesMovementsAPI.pagoBBVA(
				dataEncrypted
			);
			if (code === '0000' && data?.url) {
				let html = window.atob(data?.url);
				let wnd = window.open('about:blank', '_blank');
				wnd?.document.write(`${html}`);
				wnd?.document.close();
				return;
			}
			setRejected(message || INTERNAL_MESSAGES.ERROR_MESSAGE);
			setPagePay(PAGE_PAY_CREDIT.REJECTED_PAYMENT);
		} catch (error: any) {
			setRejected(error?.description || INTERNAL_MESSAGES.ERROR_MESSAGE);
			setPagePay(PAGE_PAY_CREDIT.REJECTED_PAYMENT);
		} finally {
			setLoading(false);
		}
	};

	const handleExecutePay = () => {
		if (valuePaymentType === PAYMENT_TYPES.CODI.value) {
			paymentCodi();
		}
		if (valuePaymentType === PAYMENT_TYPES.CARD.value) {
			paymentBBVA();
		}
	};

	const resetFormPay = () => {
		setValuePaymentOther('');
		setValuePayment('');
		setValuePaymentType('');
		setPagePay(PAGE_PAY_CREDIT.HOME);
	};

	const getValidButton = (): boolean => {
		if (!valuePayment || !valuePaymentType) {
			return true;
		}
		let monto = 0;
		if (valuePayment === PAYMENTS.TO_BE_CURRENT.value) {
			monto = Number(
				balance.pagos?.opciones.pagoCorriente?.trim() || '0'
			);
		}
		if (valuePayment === PAYMENTS.EXPECTED_AMOUNT.value) {
			monto = Number(
				balance.pagos?.opciones.pagoMontoEsperado?.trim() || '0'
			);
		}
		if (valuePayment === PAYMENTS.OVERDUE_MONTHLY_INSTALLMENTS.value) {
			monto = Number(
				balance.pagos?.opciones.pagoMontoMensualidadesVencidas?.trim() ||
					'0'
			);
		}
		if (valuePayment === PAYMENTS.ANOTHER.value) {
			monto = Number(
				valuePaymentOther
					.replaceAll('$', '')
					.replaceAll(',', '')
					.trim() || '0'
			);
		}
		if (monto <= 0) {
			return true;
		}
		if (valuePaymentType === PAYMENT_TYPES.CODI.value && monto > 8000) {
			return true;
		}
		if (valuePaymentType === PAYMENT_TYPES.CARD.value && monto > 30000) {
			return true;
		}
		return false;
	};

	return {
		balance,
		valuePayment,
		valuePaymentType,
		openPanel,
		loading,
		rejected,
		valuePaymentOther,
		setPageMain,
		setOpenPanel,
		handleChangeValuePayment,
		handleChangeValuePaymentType,
		handleExecutePay,
		setRejected,
		setValuePaymentOther,
		setPagePay,
		resetFormPay,
		getValidButton,
	};
};
