import {
	Divider,
	Link,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../../../components/Button';
import ModalLoading from '../../../../../components/ModalLoading';
import SelectCustom from '../../../../../components/SelectCustom';
import {
	MomentsAccountPaginator,
	useStyles,
} from './styles/MomentsAccount.style';
import CustomAlert from '../../../../../components/CustomAlert';
import { INTERNAL_MESSAGES } from '../../../../../config/messageCatalog';
import iconoDescarga from '../../../../../assets/img/icono-descarga.png';
import {
	getDateFormat,
	getPesoOrVSM,
	OPTIONS_MOVEMENTS,
	OPTIONS_ORIGINS,
	OPTIONS_TYPES,
	TYPE_MONEY,
} from './utils';
import { useMomentsAccount } from './hooks/useMomentsAccount';
import { CODE_TYPE_MOMENT } from '../../../utils';
import { format, parse } from 'date-fns';
import { useContextMoment } from '../../../context';

const MomentsComponent = () => {
	const styles = useStyles();
	const { balance } = useContextMoment();
	const navigate = useNavigate();
	const {
		loading,
		disabledInputs,
		disabledColumns,
		filter,
		message,
		rowsMomentShow,
		countRowsMoment,
		orderByMoments,
		orderMoments,
		pageShow,
		handleChangeFilter,
		handleChangePage,
		getReporteMovimientos,
		handleSearchMoments,
		handleSortMoments,
	} = useMomentsAccount();
	return (
		<>
			<ModalLoading loading={loading} />
			<h3>Consulta los movimientos de Mi Crédito</h3>
			<div className={styles.containerFilter}>
				<SelectCustom
					options={OPTIONS_ORIGINS}
					label="Buscar desde:"
					name="periodo"
					disabled={disabledInputs}
					value={filter.periodo}
					onChange={handleChangeFilter}
				/>
				<SelectCustom
					options={
						balance?.propiedades?.tipoMoneda === TYPE_MONEY.VSM
							? OPTIONS_TYPES
							: OPTIONS_TYPES.filter(
									(f) => f.value !== TYPE_MONEY.VSM
							  )
					}
					label="Mostrar cantidades en:"
					name="tipoMoneda"
					disabled={disabledInputs}
					value={filter.tipoMoneda}
					onChange={handleChangeFilter}
				/>
				<SelectCustom
					options={OPTIONS_MOVEMENTS}
					label="Tipo de Movimientos:"
					name="tipoMovimiento"
					value={filter.tipoMovimiento}
					onChange={handleChangeFilter}
				/>
				<div className={styles.buttonSearch}>
					<CustomButton
						label={'Buscar'}
						onClick={handleSearchMoments}
					/>
				</div>
			</div>
			<TableContainer>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell className={styles.tableCellCamp}>
								<TableSortLabel
									active={orderByMoments === 'fecha'}
									direction={
										orderByMoments === 'fecha'
											? orderMoments
											: 'asc'
									}
									onClick={() => handleSortMoments('fecha')}
								>
									Fecha
								</TableSortLabel>
							</TableCell>
							<TableCell className={styles.tableCellCamp}>
								<TableSortLabel
									active={orderByMoments === 'concepto'}
									direction={
										orderByMoments === 'concepto'
											? orderMoments
											: 'asc'
									}
									onClick={() =>
										handleSortMoments('concepto')
									}
								>
									Concepto
								</TableSortLabel>
							</TableCell>

							{filter.tipoMovimiento ===
							CODE_TYPE_MOMENT.PAGOS_PARCIALES ? (
								<TableCell
									className={styles.tableCellCamp}
									align="right"
								>
									<TableSortLabel
										active={
											orderByMoments ===
											'montoTransaccion'
										}
										direction={
											orderByMoments ===
											'montoTransaccion'
												? orderMoments
												: 'asc'
										}
										onClick={() =>
											handleSortMoments(
												'montoTransaccion'
											)
										}
									>
										Monto Transacción
									</TableSortLabel>
								</TableCell>
							) : (
								<TableCell
									className={styles.tableCellCamp}
									align="right"
								>
									<TableSortLabel
										active={orderByMoments === 'pagoTotal'}
										direction={
											orderByMoments === 'pagoTotal'
												? orderMoments
												: 'asc'
										}
										onClick={() =>
											handleSortMoments('pagoTotal')
										}
									>
										Pago Total
									</TableSortLabel>
								</TableCell>
							)}

							{!disabledColumns && (
								<>
									<TableCell
										className={styles.tableCellCamp}
										align="right"
									>
										<TableSortLabel
											active={
												orderByMoments ===
												'pagoAccesorios'
											}
											direction={
												orderByMoments ===
												'pagoAccesorios'
													? orderMoments
													: 'asc'
											}
											onClick={() =>
												handleSortMoments(
													'pagoAccesorios'
												)
											}
										>
											Pago a seguros y cuotas
										</TableSortLabel>
									</TableCell>
									<TableCell
										className={styles.tableCellCamp}
										align="right"
									>
										<TableSortLabel
											active={
												orderByMoments ===
												'pagoAIntereses'
											}
											direction={
												orderByMoments ===
												'pagoAIntereses'
													? orderMoments
													: 'asc'
											}
											onClick={() =>
												handleSortMoments(
													'pagoAIntereses'
												)
											}
										>
											Pago a intereses
										</TableSortLabel>
									</TableCell>
									<TableCell
										className={styles.tableCellCamp}
										align="right"
									>
										<TableSortLabel
											active={
												orderByMoments === 'pagoCapital'
											}
											direction={
												orderByMoments === 'pagoCapital'
													? orderMoments
													: 'asc'
											}
											onClick={() =>
												handleSortMoments('pagoCapital')
											}
										>
											Pago a capital
										</TableSortLabel>
									</TableCell>
								</>
							)}
						</TableRow>
					</TableHead>
					<TableBody>
						{rowsMomentShow.map((row, k) => (
							<TableRow
								key={k}
								sx={{
									'&:last-child td, &:last-child th': {
										border: 0,
									},
								}}
							>
								<TableCell>
									{getDateFormat(row?.fecha)}
								</TableCell>
								<TableCell>{row?.concepto}</TableCell>

								{filter.tipoMovimiento ===
								CODE_TYPE_MOMENT.PAGOS_PARCIALES ? (
									<TableCell align="right">
										{getPesoOrVSM(
											row?.montoTransaccion,
											filter.tipoMoneda
										)}
									</TableCell>
								) : (
									<TableCell align="right">
										{getPesoOrVSM(
											row?.pagoTotal,
											filter.tipoMoneda
										)}
									</TableCell>
								)}

								{!disabledColumns && (
									<>
										<TableCell align="right">
											{getPesoOrVSM(
												row?.pagoAccesorios,
												filter.tipoMoneda
											)}
										</TableCell>
										<TableCell align="right">
											{getPesoOrVSM(
												row?.pagoAIntereses,
												filter.tipoMoneda
											)}
										</TableCell>
										<TableCell align="right">
											{getPesoOrVSM(
												row?.pagoCapital,
												filter.tipoMoneda
											)}
										</TableCell>
									</>
								)}
							</TableRow>
						))}
					</TableBody>
				</Table>
				{rowsMomentShow.length === 0 && (
					<div className={styles.noHayDatosContainer}>
						{INTERNAL_MESSAGES.SEARCH_CRITERIA}
					</div>
				)}
				<div className={styles.containerPaginator}>
					<MomentsAccountPaginator
						count={countRowsMoment}
						shape="rounded"
						onChange={(_e, page) => handleChangePage(page)}
						disabled={rowsMomentShow.length === 0}
						showFirstButton
						showLastButton
						page={pageShow}
					/>
				</div>
			</TableContainer>
			<h3 className={styles.titlePdf} onClick={getReporteMovimientos}>
				Descarga tus movimientos en formato PDF
				<img
					src={iconoDescarga}
					alt="download icon"
					className={styles.iconoPdf}
				/>
			</h3>
			<CustomAlert
				message={message}
				show={Boolean(message)}
				severity="warning"
			/>
			<div className={styles.containerPdf}>
				<h4>
					Para consultar los documentos anteriores es necesario que:
				</h4>
				<p>
					Tengas instalada la ultima version de Acrobat Reader.{' '}
					<Link
						href="https://get.adobe.com/es/reader/"
						target="_blank"
					>
						Descargalo aquí.
					</Link>
				</p>
				<p>
					Permite las ventanas emergentes a tu explorador de internet.{' '}
					<Link
						href="https://support.google.com/chromebook/answer/95472?hl=es&co=GENIE.Platform%3DDesktop"
						target="_blank"
					>
						Consultar la guía.
					</Link>
				</p>
			</div>

			<Divider />

			<div className={styles.containerAclaraciones}>
				<h3>Aclaraciones de pagos</h3>
				<p>
					Si tu pago no se ve relejado en tus movimientos y ya pasaron
					mas de 10 días hábiles, haz una aclaración.
				</p>
				<div className="btnAclaracion">
					<CustomButton
						label={'Hacer aclaración'}
						onClick={() => navigate('/mi-credito/aclaracion-pagos')}
					/>
				</div>
			</div>
		</>
	);
};

export default MomentsComponent;
