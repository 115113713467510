import { useState } from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import useTallerMas from '../hooks/useElements';
import { WORKSHOP_VIEWS } from '../utils';
import iconoMedalla from '../../../../../assets/img/taller-saber-mas/medalla.png';
import iconoConstancia from '../../../../../assets/img/taller-saber-mas/constancia.png';
import ModalLoading from '../../../../../components/ModalLoading';
import { useStyles } from '../styles/style';
import { downloadPDF } from '../../../../../utils/downloadPDF';

const StepsCustom = () => {
	const styles = useStyles();
	const { elementos, getCertificate } = useTallerMas();
	const FINANTIAL_EDUCATION =
		elementos[WORKSHOP_VIEWS.FINANTIAL_EDUCATION.code];
	const stepsCount = FINANTIAL_EDUCATION?.numero_elementos || 0;
	const [loading, setLoading] = useState(false);
	const viewPLays = FINANTIAL_EDUCATION?.secciones[0]?.elementos.filter(
		(f) => f.estatus === 1
	).length;

	const downloadCertificate = async () => {
		try {
			if (viewPLays === stepsCount) {
				setLoading(true);
				const { base64PDF } = await getCertificate();
				if (base64PDF) {
					downloadPDF({
						payload: base64PDF,
						name: 'ConstanciaCursoSaberMas',
					});
					return;
				}
			}
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className={styles.mainStep}>
			{FINANTIAL_EDUCATION?.secciones[0]?.elementos.map((_step, k) => (
				<div key={k} className={styles.containerStep}>
					<div
						className={styles.pointStep}
						style={{
							opacity: viewPLays >= k + 1 ? 1 : 0.4,
						}}
					></div>
					{viewPLays >= k + 1 && (
						<div className={styles.backContainerStep}>
							<img
								src={iconoMedalla}
								alt="logoMedalla"
								className={styles.logoStep}
							/>
						</div>
					)}
					<CircleIcon
						className={styles.iconCircleStep}
						style={{
							opacity: viewPLays >= k + 1 ? 1 : 0.4,
						}}
					/>
					<label
						style={{
							opacity: viewPLays >= k + 1 ? 1 : 0.4,
						}}
					>
						{`Tema ${k + 1}`}
					</label>
				</div>
			))}
			<div className={styles.containerStep}>
				<div
					className={styles.pointStep}
					style={{
						opacity: viewPLays >= stepsCount ? 1 : 0.4,
					}}
				></div>
				<div className={styles.backContainerStep}>
					<img
						src={iconoConstancia}
						alt="logoMedalla"
						className={styles.logoStepDiploma}
					/>
				</div>
				<CircleIcon
					className={styles.iconCircleStep}
					style={{
						opacity: viewPLays >= stepsCount ? 1 : 0.4,
					}}
				/>
				<label
					onClick={downloadCertificate}
					style={{
						opacity: viewPLays >= stepsCount ? 1 : 0.4,
						cursor: viewPLays >= stepsCount ? 'pointer' : undefined,
						textDecoration:
							viewPLays >= stepsCount ? 'underline' : undefined,
						fontWeight: viewPLays >= stepsCount ? 500 : undefined,
						color: viewPLays >= stepsCount ? '#E51D76' : undefined,
					}}
				>
					Descarga tu constancia
				</label>
			</div>
			<ModalLoading loading={loading} />
		</div>
	);
};

export default StepsCustom;
