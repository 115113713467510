import { Divider, Link, useMediaQuery } from '@mui/material';
import { useStyles } from './styles/Flexipago.style';
import theme from '../../../../../config/theme';
import { useContextMoment } from '../../../context';

const Flexipago = () => {
	const styles = useStyles();
	const { balance } = useContextMoment();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	return (
		<>
			{(balance?.flexipago?.mesesDispProrroga ||
				balance?.flexipago?.mesesDispFpp) && (
				<div className={styles.container}>
					<h3>Flexipago por desempleo</h3>
					<p>
						Te proporciona la flexibilidad para que, de manera
						mensual, utilices alguna de las opciones disponibles
						para cubrir tu pago y mantener al corriente tu crédito.
						Visita <Link>El Portal</Link> para más información.
					</p>
					<div className={styles.flexipagoContainer}>
						<label>Meses disponibles</label>
						<div className={styles.flexipagoItem}>
							<p>Prórroga:</p>
							<h3>
								{balance?.flexipago?.mesesDispProrroga || '0'}{' '}
								meses
							</h3>
						</div>
						<Divider
							orientation={matches ? 'horizontal' : 'vertical'}
							flexItem
						/>
						<div className={styles.flexipagoItem}>
							<p>FPP:</p>
							<h3>
								{balance?.flexipago?.mesesDispFpp || '0'} meses
							</h3>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Flexipago;
