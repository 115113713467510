import { Info } from '@mui/icons-material';
import CustomAlert from '../../../../components/CustomAlert';
import TooltipCustom from '../../../../components/TooltipCustom';
import { TOOLTIPS } from '../../utils/TooltipsUtils';
import { useStyles } from './styles';
import { Grid } from '@mui/material';
import { CardBalance } from '../CardBalance';
import { LabelCardBalance } from '../LabelCardBalance';
import { LabelCardBalanceValues } from '../LabelCardBalanceValues';
import { useContextMoment } from '../../context';
import { getValNumberOrNull, MESSAGES } from '../../utils';
import { formatStringToDate } from '../../../../utils/dates';

export const HeaderPendingPayment = () => {
	const classes = useStyles();
	const { balance } = useContextMoment();
	const message =
		balance.propiedades?.mensajes.filter((f) =>
			MESSAGES.MAIN.includes(f.key)
		) || [];
	return (
		<>
			{balance.propiedades?.esOmiso && message.length > 0 && (
				<CustomAlert
					severity="warning"
					show
					message={message[0].value}
				/>
			)}

			{balance.propiedades?.esOmiso && (
				<>
					<p className={classes.redTitle}>
						Pago para estar al corriente{' '}
						<TooltipCustom title={TOOLTIPS.PAGO_ESTAR_CORRIENTE}>
							<Info
								color="primary"
								className={classes.tooltipIcon}
							/>
						</TooltipCustom>
					</p>
					<p className={classes.redSubTitle}>
						(Es la suma del monto esperado más las mensualidades
						vencidas)
					</p>
					<Grid container spacing={2} marginBottom={5}>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<CardBalance title="Fecha límite de pago">
								<LabelCardBalance
									label={
										balance?.pagoCorriente?.fechaLimitePago
											? formatStringToDate(
													balance?.pagoCorriente
														?.fechaLimitePago
											  )
											: ''
									}
								/>
							</CardBalance>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<CardBalance title="¿Cuánto debo?">
								<LabelCardBalanceValues
									variant="primary"
									vsmValue={getValNumberOrNull(
										balance?.pagoCorriente?.cuantoDeboVsm
									)}
									mxnValue={Number(
										balance?.pagoCorriente?.cuantoDebo ||
											'0'
									)}
								/>
							</CardBalance>
						</Grid>
					</Grid>
				</>
			)}
		</>
	);
};
