import { useContextMoment } from '../../../context';
import { CODES } from '../../../utils';
import SolitaryModule from '../../../components/SolitaryModule';
import StatusComponent from './StatusComponent';

const StatusAccount = () => {
	const { balance } = useContextMoment();

	return (
		<>
			{[
				CODES.CASOS_ESPECIALES_NP_EF_DA,
				CODES.CASOS_ESPECIALES_RENEWAL_NO_DEMANDA,
				CODES.RECUPERACION_DEMANDADO_SIN_CONVENIO_JUDICIAL,
				CODES.RECUPERACION_SENTENCIA,
			].includes(balance.propiedades?.tipoCaso) ? (
				<SolitaryModule />
			) : (
				<StatusComponent />
			)}
		</>
	);
};

export default StatusAccount;
