import React, { Fragment } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Typography, Avatar } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { MenuElements } from '../utils';
import { useNavigate } from 'react-router-dom';
import { IUser } from '../../../interfaces/user';
import { useDispatch } from 'react-redux';
import { actions } from '../../../store/modules/session';
import { actions as creditActions } from '../../../store/modules/credit';
import { actions as MiucActions } from '../../../store/modules/miuc';

import { loginAPI } from '../../../api/modules/auth';
import { actions as saldAction } from '../../../store/modules/saldo';
import { keyAPI } from '../../../api/modules/key';
import { encrypt } from '../../../utils/encrypt';

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: 'flex-end',
}));

type Props = {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	menuElements: MenuElements[];
	userInfo?: IUser | null;
};

const MovilDrawer = ({ open, setOpen, menuElements, userInfo }: Props) => {
	const theme = useTheme();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const signOff = async () => {
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const paramsData = {
				nss: userInfo?.nss?.toString(),
			};
			const dataSendEncrypt = await encrypt(
				JSON.stringify(paramsData),
				key
			);
			loginAPI
				.logout(dataSendEncrypt)
				.then(() => {
					logout();
				})
				.catch(() => {
					logout();
				})
		} catch (error) {
			logout();
		} finally {
			logout();
		}
	};

	const logout = () => {
		dispatch(saldAction.cleanSaldo());
		dispatch(actions.signOut());
		dispatch(creditActions.clearCredit());
		dispatch(MiucActions.clearData());
		setTimeout(() => {
			userInfo?.isImpersonalized ? window.location.href = 'https://micuenta.infonavit.org.mx/' : navigate('/')
		}, 100)
	};

	return (
		<Box sx={{ display: { xs: 'flex', md: 'none' } }}>
			<Drawer
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: drawerWidth,
						boxSizing: 'border-box',
					},
				}}
				variant="persistent"
				anchor="left"
				open={open}
			>
				<DrawerHeader sx={{ padding: 2, justifyContent: 'space-between', minHeight: 'auto !important' }}>
					<Box>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							{userInfo?.given_name && (
								<>
									<Avatar
										sx={{
											border: 1,
											borderColor: theme.palette.info.main,
											color: theme.palette.info.main,
											fontSize: 10,
											width: 20,
											height: 20,
											background: 'white',
										}}
									>
										{userInfo?.given_name.charAt(0)}
									</Avatar>
									<Typography
										sx={{
											paddingLeft: 0.6,
											color: theme.palette.info.main,
											fontWeight: 500,
										}}
										variant="caption"
									>
										{userInfo?.given_name.split(' ')[0]}
									</Typography>
								</>
							)}
						</Box>
						{userInfo?.nss && (
							<Typography
								sx={{ color: theme.palette.info.main, fontWeight: 500 }}
								variant="caption"
							>
								NSS: {userInfo?.nss}
							</Typography>
						)}
						{userInfo?.curp && (
							<>
								<br />
								<Typography
									sx={{ color: theme.palette.info.main, fontWeight: 500 }}
									variant="caption"
								>
									CURP: {userInfo?.curp}
								</Typography>
							</>
						)}
						{userInfo?.rfc && (
							<>
								<br />
								<Typography
									sx={{ color: theme.palette.info.main, fontWeight: 500 }}
									variant="caption"
								>
									RFC: {userInfo?.rfc}
								</Typography>
							</>
						)}
					</Box>
					<IconButton onClick={() => setOpen(!open)}>
						{theme.direction === 'ltr' ? <CloseIcon /> : <ChevronRightIcon />}
					</IconButton>
				</DrawerHeader>
				<Divider />
				{/** Aquí va la lista de items del menú */}
				<List>
					<ListItem
						button
						onClick={() => {
							navigate('/inicio');
							setOpen(!open);
						}}
					>
						<ListItemText
							style={{ color: theme.palette.primary.main }}
							primary={'Inicio'}
						/>
					</ListItem>
					{menuElements.map((item, index) => (
						<Fragment key={index}>
							<ListItem button onClick={() => navigate(`/${item.path}`)}>
								<p style={{ fontWeight: '700', margin: 0 }}>
									{item.name}
								</p>
							</ListItem>
							{item.children.map(
								(child, i) =>
									child.name.length > 0 && (
										<ListItem
											onClick={() =>
												navigate(`/${item.path}/${child.path}`)
											}
											button
											key={i}
										>
											<ListItemText secondary={child.name} />
										</ListItem>
									)
							)}
						</Fragment>
					))}
					{userInfo && (
						<>
							<Divider />
							<ListItem
								button
								style={{ color: theme.palette.info.main }}
								onClick={signOff}
							>
								Cerrar sesión{' '}
								<ArrowRightAltIcon style={{ marginLeft: 10 }} />
							</ListItem>
						</>
					)}
				</List>
			</Drawer>
		</Box>
	);
};

export default MovilDrawer;
