import { createElement } from 'react';
import DOMPurify from 'dompurify';

const createMarkup = (htmlString: string): ChildNode[] => {
	const tempDiv = document.createElement('div');
	tempDiv.innerHTML = htmlString;
	return Array.from(tempDiv.childNodes);
};

const getElementAttributes = (element: HTMLElement): Record<string, any> => {
	try {
		const attributes: Record<string, any> = {};
		Array.from(element.attributes).forEach((attr) => {
			if (attr.name === 'style') {
				const styleObject: Record<string, string> = {};
				attr.value.split(';').forEach((style) => {
					const [key, value] = style.split(':').map((s) => s.trim());
					if (key && value) {
						const camelCaseKey = key.replace(
							/-([a-z])/g,
							(_, char) => char.toUpperCase()
						);
						styleObject[camelCaseKey] = value;
					}
				});
				attributes.style = styleObject;
			} else {
				attributes[attr.name] = attr.value;
			}
		});
		return attributes;
	} catch (ex) {
		return {};
	}
};

export const renderHTML = (htmlString?: string): React.ReactNode => {
	try {
		if (!htmlString || htmlString === '') {
			return null;
		}
		const sanitizedHtml = DOMPurify.sanitize(htmlString);
		const nodes = createMarkup(sanitizedHtml);
		return nodes.map((node) => {
			if (node.nodeType === Node.TEXT_NODE) {
				return node.textContent;
			}
			if (node.nodeType === Node.ELEMENT_NODE) {
				const element = node as HTMLElement;

				const tagName =
					element.tagName.toLowerCase() === 'p'
						? 'span'
						: element.tagName.toLowerCase();

				return createElement(
					tagName,
					{ ...getElementAttributes(element) },
					renderHTML(element.innerHTML)
				);
			}
			return null;
		});
	} catch (ex) {
		return null;
	}
};
