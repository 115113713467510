import { Info } from '@mui/icons-material';
import TooltipCustom from '../../../../components/TooltipCustom';
import { useStyles } from './styles';
import { Grid } from '@mui/material';

interface IRowCreditOrigin {
	title: string;
	subTitle: string;
	tooltip?: string;
	firstRow?: boolean;
	lastRow?: boolean;
}
export const RowCreditOrigin = ({
	title,
	subTitle,
	tooltip,
	firstRow,
	lastRow,
}: IRowCreditOrigin) => {
	const classes = useStyles({ firstRow, lastRow });
	return (
		<Grid container className={classes.container}>
			<Grid item xs={12} md={8}>
				<div className={classes.containerLabelTooltip}>
					<label className={classes.labelTitle}>{title}</label>
					{tooltip && (
						<TooltipCustom title={tooltip}>
							<Info
								color="primary"
								className={classes.tooltipIcon}
							/>
						</TooltipCustom>
					)}
				</div>
			</Grid>
			<Grid item xs={12} md={4}>
				<div className={classes.containerLabelTooltip}>
					<label className={classes.labelSubTitle}>{subTitle}</label>
				</div>
			</Grid>
		</Grid>
	);
};
