import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

interface Props {
	color: string;
	bColor: string;
}
export const useStyles = makeStyles((theme: Theme) => ({
    containerCard: {
        display: 'flex',
        width: '100%',
        gap: '5px',
    },
	cardVideo: {
		display: 'flex',
		width: '100%',
		maxWidth: 350,
		height: 'auto',
		padding: 15,
		borderRadius: 10,
		boxShadow: 'rgba(0, 0, 0, 0.3) 0px 4px 10px',
		background: '#fefffe',
		marginBottom: 15,
        flexDirection: 'column',

		'&:hover': {
			borderWidth: 2,
			borderStyle: 'solid',
			borderColor: ({ color }: Props) => color,
		},

		[theme.breakpoints.down('md')]: {
            borderWidth: 2,
			borderStyle: 'solid',
			borderColor: '#e4e4e4',
			boxShadow: 'none',
			maxWidth: '100%',
			padding: '15px 25px 15px 25px',
		},
	},
	activeCardVideo: {
		borderWidth: 2,
		borderStyle: 'solid',
		borderColor: ({ color }: Props) => color,
		backgroundColor: ({ bColor }: Props) => bColor,
	},
	containerHeaderCard: {
		display: 'flex',
		width: '100%',
		height: '100%',
	},
	containerHeaderCardInfo: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
	},
	containerHeaderCardLabelTitle: {
		fontSize: 14,
		fontWeight: '500',

		[theme.breakpoints.down('md')]: {
			fontSize: 13,
		},
	},
	containerHeaderCardButtons: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		justifyContent: 'space-between',
		alignItems: 'flex-end',

		[theme.breakpoints.down('md')]: {
			fontSize: 12,
		},
	},
    addComponent: {
        display: 'block',
        width: '100%',
    }
}));
