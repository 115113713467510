import { Link } from '@mui/material';
import { useContextMoment } from '../../context';
import iconoPDF from '../../../../assets/img/icono-descarga.png';
import { useStyles } from './styles';

const CertificateQuality = () => {
	const { certificado } = useContextMoment();
	const styles = useStyles();

	if (!certificado) return null;
	return (
		<div className={styles.container}>
			<Link
				className={styles.linkCustom}
				underline="none"
				href={certificado}
				target="_blank"
			>
				<img src={iconoPDF} alt="iconoPDF" className={styles.iconPDF} />
				Descarga el certificado de Seguro de Calidad
			</Link>
			<Link
				className={styles.linkCustom}
				href={require('../../../../assets/files/GUIA_SEGURO_DE_CALIDAD_2022.pdf')}
				target="_blank"
				underline="none"
			>
				<img src={iconoPDF} alt="iconoPDF" className={styles.iconPDF} />
				Descarga la guía del Seguro de Calidad
			</Link>
		</div>
	);
};

export default CertificateQuality;
