import { useContextMoment } from '../../../context';
import { BALANCES_MOMENTS_PAGE } from '../../../utils';
import { TOOLTIPS } from '../../../utils/TooltipsUtils';
import { CardBalance } from '../../CardBalance';
import { LabelCardBalance } from '../../LabelCardBalance';
import { SectionBalance } from '../../SectionBalance';

interface IProps {
	pageShow?: Number;
}

const RecuperacionSentencia = ({ pageShow }: IProps) => {
	const { balance } = useContextMoment();
	return (
		<SectionBalance
			title={
				pageShow === BALANCES_MOMENTS_PAGE.HOME
					? 'Monto esperado'
					: undefined
			}
			tooltip={
				pageShow === BALANCES_MOMENTS_PAGE.HOME
					? TOOLTIPS.MONTO_ESPERADO
					: undefined
			}
		>
			<CardBalance title="Situación">
				<LabelCardBalance
					label={
						balance.propiedades.mensajes
							.find((f) => f.key === '07')
							?.value.replace(
								'%s',
								balance.montoEsperado?.despachoCobranza || ''
							) || ''
					}
				/>
			</CardBalance>
		</SectionBalance>
	);
};

export default RecuperacionSentencia;
