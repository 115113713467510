import { Collapse, useMediaQuery } from '@mui/material';
import { formatMoney3 } from '../../../../utils/validators';
import styles from './styles/index.module.css';
import theme from '../../../../config/theme';
import { getVSMFormat } from '../../utils';

type TOperacion = '+' | '-';

interface IDetail {
	label: string;
	monto?: string;
	montoVSM?: string;
	typeOperation?: TOperacion;
}

interface Items {
	label: string;
	monto?: string;
	montoVSM?: string;
	isBold?: boolean;
	detail?: IDetail[];
}

interface IProps {
	items: Items[];
	show: boolean;
}

const ListMonMyCredit = ({ items, show }: IProps) => {
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	return (
		<Collapse in={show}>
			<section className={styles.listCard}>
				{items.map(({ label, monto, montoVSM, detail, isBold }, k) => (
					<article style={{ fontWeight: isBold ? 500 : 400 }} key={k}>
						<div>{label}</div>
						<div
							className={styles.itemCard}
							style={{
								textAlign: isMobile ? 'left' : 'right',
							}}
						>
							<div>
								{montoVSM &&
									montoVSM !== 'N/A' &&
									getVSMFormat(montoVSM || '0')}
							</div>
							<div>{formatMoney3(monto || '0')}</div>
						</div>
						{detail && detail.length > 0 && (
							<section className={styles.listCardSub}>
								{detail?.map((d, k2) => (
									<article key={k2}>
										<div>
											[{d.typeOperation || '+'}] {d.label}
										</div>
										<div
											className={styles.itemCard}
											style={{
												textAlign: isMobile
													? 'left'
													: 'right',
											}}
										>
											<div>
												{d.montoVSM &&
													montoVSM !== 'N/A' &&
													getVSMFormat(
														d.montoVSM || '0'
													)}
											</div>
											<div>
												{formatMoney3(d.monto || '0')}
											</div>
										</div>
									</article>
								))}
							</section>
						)}
					</article>
				))}
			</section>
		</Collapse>
	);
};

export default ListMonMyCredit;
