export const PAYMENTS = {
	TO_BE_CURRENT: {
		label: 'Pago para estar al corriente',
		value: '1',
	},
	EXPECTED_AMOUNT: {
		label: 'Pago del monto esperado',
		value: '2',
	},
	OVERDUE_MONTHLY_INSTALLMENTS: {
		label: 'Pago del monto de mensualidades vencidas',
		value: '3',
	},
	ANOTHER: {
		label: 'Otro',
		value: '4',
	},
};
export const PAYMENT_TYPES = {
	CODI: {
		label: 'Pago con Codi',
		value: '1',
	},
	CARD: {
		label: 'Pago con Tarjeta',
		value: '2',
	},
};
