import { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Grid, Paper } from '@mui/material';
import { useStyles } from './styles/funancial-education.style';
import MediaCard from './media-card.component';
import { getResponse, PROFILES_VALID_CER, WORKSHOP_VIEWS } from '../utils';
import useTallerMas from '../hooks/useElements';
import CardViewContent from './cardViewContent';
import { Elemento } from '../../../../../interfaces/tallerSaberMas';
import StepsCustom from './StepsCustom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CarouselCustom from './CarouselCustom';
import { RootState } from '../../../../../store';
import { useSelector } from 'react-redux';
import ContentInfo from './ContentInfo';
import RenderHTML from '../../../../../components/RenderHTML';

export default function FinancialEducation() {
	const styles = useStyles();
	const { user } = useSelector((state: RootState) => state.session);
	const { elementos, getElementViews, setFinishLearn, matches } =
		useTallerMas();
	const FINANTIAL_EDUCATION =
		elementos[WORKSHOP_VIEWS.FINANTIAL_EDUCATION.code];
	const [allViews, setAllViews] = useState(true);
	const [elemento, setElemento] = useState<Elemento>({} as Elemento);

	useEffect(() => {
		if (
			FINANTIAL_EDUCATION &&
			FINANTIAL_EDUCATION.secciones[0].elementos.length > 0
		) {
			setElemento(FINANTIAL_EDUCATION.secciones[0].elementos[0]);
		}
	}, [FINANTIAL_EDUCATION]);

	const elementsJSX =
		FINANTIAL_EDUCATION?.secciones[0]?.elementos
			.filter(
				(f) => (!allViews && [0, 3].includes(f.estatus)) || allViews
			)
			.map((el, k) => (
				<MediaCard
					onClick={(element) => {
						setFinishLearn(false);
						setElemento(element);
					}}
					key={k}
					{...el}
					elemento={elemento}
				/>
			)) || [];

	return (
		<Paper elevation={2} className={styles.container}>
			<div className={styles.startIconAbsolute}>
				<div>Indispensable</div>
			</div>
			<Grid container columns={12} spacing={4}>
				<Grid item sm={12} md={12}>
					<div className={styles.containerHeader}>
						<label className={styles.labelHeaderTitle}>
							Podrás descargar tu constancia una vez contemplados
							todos los temas.
						</label>
						<label className={styles.labelHeaderSubTitle}>
							Vistos: {getElementViews()} de{' '}
							{FINANTIAL_EDUCATION?.numero_elementos || 0}
						</label>
					</div>
				</Grid>
				{!PROFILES_VALID_CER.includes(user?.idPerfil || '') && (
					<Grid item xs={12} md={12}>
						<StepsCustom />
					</Grid>
				)}
				<Grid item xs={12} md={12}>
					{/*	Contenido multimedia */}
					<CardViewContent
						elemento={elemento}
						setElemento={setElemento}
					/>
					<div className={styles.labelQ}>
						<RenderHTML htmlContent={elemento?.descripcion} />
						<ContentInfo elemento={elemento} />
					</div>
					<div className={styles.dividerContainer}>
						<div className={styles.dividerSubContainer}>
							<label
								className={styles.buttonDivider}
								onClick={() => setAllViews(true)}
								style={{ fontWeight: allViews ? 500 : 400 }}
							>
								Todos
							</label>

							{getElementViews() !==
								FINANTIAL_EDUCATION?.numero_elementos && (
								<>
									<div className={styles.dividerButtons} />
									<label
										className={styles.buttonDivider}
										onClick={() => setAllViews(false)}
										style={{
											fontWeight: !allViews ? 500 : 400,
										}}
									>
										Pendientes
									</label>
								</>
							)}
						</div>
					</div>
				</Grid>
			</Grid>
			<div className={styles.mainVideoContainer}>
				{!matches ? (
					<CarouselCustom items={elementsJSX} horizontal />
				) : (
					<Carousel
						customLeftArrow={
							<div className={styles.arrowVideoL}>
								<div>
									<ChevronLeftIcon />
								</div>
							</div>
						}
						customRightArrow={
							<div className={styles.arrowVideoR}>
								<div>
									<ChevronRightIcon />
								</div>
							</div>
						}
						responsive={getResponse(
							FINANTIAL_EDUCATION?.secciones[0]?.elementos.length
						)}
					>
						{elementsJSX.map((el) => el)}
					</Carousel>
				)}
			</div>
		</Paper>
	);
}
