import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		border: '1px solid rgba(0,0,0,0.1)',
		borderRadius: '10px',
		padding: '30px 20px',
		color: 'gray',
		margin: '20px 0px',

		'& > h3': {
			textAlign: 'center',
			margin: 0,
			color: 'black',
		},
		'& > p': {
			textAlign: 'center',
			margin: '10px 0px',
		},
	},
	flexipagoContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		gap: '30px',
        width: '100%',

		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
		},
	},
	flexipagoItem: {
		'& > h3': {
			textAlign: 'center',
			margin: 0,
			color: 'black',
		},
		'& > p': {
			margin: 0,
		},
	},
}));
