import { Divider } from '@mui/material';
// --------------
import { HeaderPendingPayment } from '../../../components/HeaderPendingPayment';
import { FooterCredit } from '../../../components/FooterCredit';
import MensualidadesVencidas from './MensualidadesVencidas';
import SaldoCredito from './SaldoCredito';
import Flexipago from './Flexipago';
import MotoEsperado from './MotoEsperado';
// --------------
import { useStyles } from '../styles';
// --------------
import ConsultMovements from './ConsultMovements';

interface IProps {
	handleChageTab: (arg: number) => void;
}

const CreditBalance = ({ handleChageTab }: IProps) => {
	const classes = useStyles();

	return (
		<>
			{/* Cabecera */}
			<HeaderPendingPayment />
			{/* Sección de monto esperado */}
			<MotoEsperado />
			{/* Sección de Mensualidades vencidas */}
			<MensualidadesVencidas />
			{/* Sección de Saldo de mi crédito */}
			<SaldoCredito />
			{/* Divisor */}
			<Divider className={classes.divider} />
			{/* Footer de crédito */}
			<FooterCredit />
			{/* Sección de Flexi-pago */}
			<Flexipago />
			{/* Consulta de movimientos */}
			<ConsultMovements handleChageTab={handleChageTab} />
		</>
	);
};

export default CreditBalance;
