import { Collapse } from '@mui/material';
import { useStyles } from './styles/index';
import { IDataBalanceMoment } from '../../../../interfaces/balanceMoment';
import { useState } from 'react';
import { KeyboardArrowDown } from '@mui/icons-material';
import { formatMoney3 } from '../../../../utils/validators';
import { getVSMFormat } from '../../utils';

interface IProps {
	balance: IDataBalanceMoment;
	show: boolean;
}

const ListOverduePayments = ({ show, balance }: IProps) => {
	const styles = useStyles();
	const [panel, setPanel] = useState<number | null>(null);

	const handleChange = (key: number) => {
		if (panel === key) {
			setPanel(null);
			return;
		}
		setPanel(key);
	};

	return (
		<Collapse in={show}>
			<section className={styles.listCard}>
				{balance.mensualidadesVencidas?.desglose?.mensualidades.map(
					(item, k) => (
						<article
							className={styles.listCardContent}
							key={k}
							onClick={() => handleChange(k)}
						>
							<div className={styles.subPanelContent}>
								<label className={styles.arrowIconPanel}>
									<KeyboardArrowDown
										style={{
											transition: 'all 0.3s',
											transform:
												panel === k
													? 'rotate(180deg)'
													: 'rotate(0deg)',
										}}
									/>
									{item.fecha}
								</label>
								<label className={styles.numberText}>
									{item.totalVsm &&
										item.totalVsm !== 'N/A' && (
											<>
												{getVSMFormat(
													item.totalVsm || '0'
												)}
											</>
										)}
								</label>
								<label className={styles.numberText}>
									{formatMoney3(item.total || '0')}
								</label>
							</div>
							<Collapse in={panel === k}>
								<section className={styles.subPanel}>
									{/* Capital */}
									<div className={styles.subPanelContent}>
										<label
											className={styles.arrowIconSpacing}
										>
											Capital
										</label>
										<label className={styles.numberText}>
											{item.capitalVsm &&
												item.capitalVsm !== 'N/A' && (
													<>
														{getVSMFormat(
															item.capitalVsm ||
																'0'
														)}
													</>
												)}
										</label>
										<label className={styles.numberText}>
											{formatMoney3(item.capital || '0')}
										</label>
									</div>

									{/* Intereses */}
									<div className={styles.subPanelContent}>
										<label
											className={styles.arrowIconSpacing}
										>
											Intereses
										</label>
										<label className={styles.numberText}>
											{item.interesesVsm &&
												item.interesesVsm !== 'N/A' && (
													<>
														{getVSMFormat(
															item.interesesVsm ||
																'0'
														)}
													</>
												)}
										</label>
										<label className={styles.numberText}>
											{formatMoney3(
												item.intereses || '0'
											)}
										</label>
									</div>

									{/* Cuota de Administración */}
									<div className={styles.subPanelContent}>
										<label
											className={styles.arrowIconSpacing}
										>
											Cuota de administración
										</label>
										<label className={styles.numberText}>
											{item.ctaAdminVsm &&
												item.ctaAdminVsm !== 'N/A' && (
													<>
														{getVSMFormat(
															item.ctaAdminVsm ||
																'0'
														)}
													</>
												)}
										</label>
										<label className={styles.numberText}>
											{formatMoney3(item.ctaAdmin || '0')}
										</label>
									</div>

									{/* Fondo de Protección de Pagos */}
									<div className={styles.subPanelContent}>
										<label
											className={styles.arrowIconSpacing}
										>
											Fondo de Protección de Pagos
										</label>
										<label className={styles.numberText}>
											{item.fondoProteccionVsm &&
												item.fondoProteccionVsm !==
													'N/A' && (
													<>
														{getVSMFormat(
															item.fondoProteccionVsm ||
																'0'
														)}
													</>
												)}
										</label>
										<label className={styles.numberText}>
											{formatMoney3(
												item.fondoProteccion || '0'
											)}
										</label>
									</div>

									{/* Seguro de Daños */}
									<div className={styles.subPanelContent}>
										<label
											className={styles.arrowIconSpacing}
										>
											Seguro de daños
										</label>
										<label className={styles.numberText}>
											{item.seguroDanosVsm &&
												item.seguroDanosVsm !==
													'N/A' && (
													<>
														{getVSMFormat(
															item.seguroDanosVsm ||
																'0'
														)}
													</>
												)}
										</label>
										<label className={styles.numberText}>
											{formatMoney3(
												item.seguroDanos || '0'
											)}
										</label>
									</div>
								</section>
							</Collapse>
						</article>
					)
				)}
			</section>
		</Collapse>
	);
};

export default ListOverduePayments;
