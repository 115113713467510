import { createContext, useContext } from 'react';
import { IContextPropsMoments } from '../interfaces';

export const ContextMoment = createContext<IContextPropsMoments | undefined>(
	undefined
);

export const useContextMoment = () => {
	const context = useContext(ContextMoment);
	if (context === undefined) {
		throw new Error('Error Context');
	}
	return context;
};