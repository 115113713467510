import { Elemento } from '../../../../../interfaces/tallerSaberMas';
import { useStyles } from './styles/content-info.style';
import CalculateIcon from '@mui/icons-material/Calculate';

interface IProps {
	elemento: Elemento;
}

const ContentInfo = ({ elemento }: IProps) => {
	const styles = useStyles();

	const redirectCalculadora = () => {
		const urlBase = elemento.direccionUrl.split('*||*');
		window.open(urlBase[1], '_blank');
	};

	return (
		<>
			{elemento.idElemento === 2 && (
				<div className={styles.container}>
					<label>
						Consulta el simulador y conoce más sobre tu situación
						financiera
					</label>
					<div>
						<div
							className={styles.btnPresupuesto}
							onClick={redirectCalculadora}
						>
							<div>
								<label>Simulador de presupuesto</label>
								<p>NO OBLIGATORIO</p>
							</div>
							<div>
								<CalculateIcon style={{ fontSize: 40 }} />
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default ContentInfo;
