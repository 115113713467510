import { useTabs } from '../../../../../hooks/useTabs';
import { TABS_BALANCE_MOVEMENTS } from '../../../utils/TabsUtils';

export const useBalance = () => {
	const { containerPosition, tabActive, tabsEle, changeTabCustom } = useTabs(
		TABS_BALANCE_MOVEMENTS,
		1,
		0
	);
	const handleChageTab = (arg: number) => {
		changeTabCustom(arg);
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};
	return {
		containerPosition,
		tabActive,
		tabsEle,
		handleChageTab,
		changeTabCustom,
	};
};
