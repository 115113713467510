import { useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import { SimCardDownload } from '@mui/icons-material';
import ReactPlayer from 'react-player';
import useTallerMas from '../hooks/useElements';
import CustomAlert from '../../../../../components/CustomAlert';
import { INTERNAL_MESSAGES } from '../../../../../config/messageCatalog';
import ModalLoading from '../../../../../components/ModalLoading';
import { useStyles } from './styles/card-view-content.style';
import iconoMedalla from '../../../../../assets/img/taller-saber-mas/medalla.png';
import { PROFILES_VALID_CER, WORKSHOP_VIEWS } from '../utils';
import { useContextTaller } from '../context';
import { Elemento } from '../../../../../interfaces/tallerSaberMas';
import { downloadPDF } from '../../../../../utils/downloadPDF';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';

interface IProps {
	elemento: Elemento;
	setElemento: (arg: Elemento) => void;
}

const CardViewContent = ({ elemento, setElemento }: IProps) => {
	const { user } = useSelector((state: RootState) => state.session);
	const { updateElement, getElements, getCertificate } = useTallerMas();
	const { activeView, elementos, finishLearn, setElementos, setFinishLearn } =
		useContextTaller();
	const [message, setMessage] = useState('');
	const [loading, setLoading] = useState(false);
	const styles = useStyles();

	const handleUpdateElement = async () => {
		if (elemento && [0, 3].includes(elemento?.estatus)) {
			try {
				setLoading(true);
				setMessage('');
				const rsUpdate = await updateElement(elemento, activeView);
				if (rsUpdate.success) {
					const rsElements = await getElements(activeView.destino);
					setElementos({
						...elementos,
						[activeView.code]: {
							...rsElements,
						},
					});
					setElemento({
						...elemento,
						estatus: 1,
					});
					if (
						activeView.code ===
							WORKSHOP_VIEWS.FINANTIAL_EDUCATION.code &&
						rsElements.secciones.length > 0
					) {
						const validElement =
							rsElements.secciones[0].elementos.every(
								(el) => el.estatus === 1
							) || false;
						if (validElement) {
							setFinishLearn(true);
						}
					}
					return;
				}
				setMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);
			} catch (error) {
				setMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);
			} finally {
				setLoading(false);
			}
		}
	};

	const downloadCertificate = async () => {
		try {
			setLoading(true);
			setMessage('');
			const { base64PDF } = await getCertificate();
			if (base64PDF) {
				downloadPDF({
					payload: base64PDF,
					name: 'ConstanciaCursoSaberMas',
				});
				return;
			}
		} catch (error) {
			setMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);
		} finally {
			setLoading(false);
		}
	};

	const handleComplete = async ({ data, message }: any) => {
		if (data === 'finalizarCurso' || message === 'finalizarCurso') {
			if (elemento && [0, 3].includes(elemento?.estatus)) {
				try {
					setLoading(true);
					setMessage('');
					const rsUpdate = await updateElement(elemento, activeView);
					if (rsUpdate.success) {
						const rsElements = await getElements(
							activeView.destino
						);
						setElementos({
							...elementos,
							[activeView.code]: {
								...rsElements,
							},
						});
						setElemento({
							...elemento,
							estatus: 1,
						});
						if (
							activeView.code ===
								WORKSHOP_VIEWS.FINANTIAL_EDUCATION.code &&
							rsElements.secciones.length > 0
						) {
							const validElement =
								rsElements.secciones[0].elementos.every(
									(el) => el.estatus === 1
								) || false;
							if (validElement) {
								setFinishLearn(true);
							}
						}
						return new Promise((resolve) => {
							setTimeout(resolve, 1000);
						});
					}
					setMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);
				} catch {
					setMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);
				} finally {
					setLoading(false);
				}
			}
		}
		return;
	};

	const getUrlBase = (direccionUrl: string): string => {
		try {
			if (direccionUrl.includes('*||*')) {
				const urlBase = direccionUrl.split('*||*');
				return urlBase[0];
			}
			return direccionUrl;
		} catch (error) {
			return direccionUrl;
		}
	};

	useEffect(() => {
		window.addEventListener('message', handleComplete);
		return () => {
			window.removeEventListener('message', handleComplete);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [elemento]);

	return (
		<>
			<ModalLoading loading={loading} />
			<Paper elevation={2} className={styles.paperContainer}>
				{!PROFILES_VALID_CER.includes(user?.idPerfil || '') &&
					finishLearn && (
						<div className={styles.finishLearn}>
							<h1>
								<img src={iconoMedalla} alt="IconoMedalla" />{' '}
								¡Felicidades!
							</h1>
							<p>
								Has terminado el módulo de{' '}
								<b>Educación financiera</b>.
							</p>
							<p>
								Lo lograste, ahora ya puedes descargar tu
								constancia, solo tienes que dar click en el
								siguiente botón.
							</p>
							<button
								onClick={downloadCertificate}
								className={styles.buttonContainer}
							>
								Descargar constancia{' '}
								<SimCardDownload
									style={{ marginLeft: '10px' }}
								/>
							</button>
						</div>
					)}
				{Object.keys(elemento).length > 0 &&
					((elemento?.direccionUrl || '').includes(
						'www.youtube.com'
					) ? (
						<ReactPlayer
							url={elemento.direccionUrl}
							controls
							width={'100%'}
							height={'100%'}
							onEnded={handleUpdateElement}
						/>
					) : (
						<iframe
							allowFullScreen
							id={String(elemento.idElemento)}
							title={elemento.nombre}
							src={getUrlBase(elemento.direccionUrl)}
							className={styles.iframePlayer}
						/>
					))}
			</Paper>
			<CustomAlert
				message={message}
				severity="warning"
				show={Boolean(message)}
			/>
		</>
	);
};

export default CardViewContent;
