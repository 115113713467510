import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	tableHeader: {
		background: 'rgba(0,0,0,0.1)',
	},
	iconInfo: {
		fontSize: '18px !important',
		margin: 0,
		padding: 0,
		marginLeft: 5,
		color: '#D1001F',
	},
	buttonCerrar: {
		maxWidth: 260,
		margin: 'auto',
		marginTop: 30,
		marginBottom: 20,
	},
	tableCell: {
		display: 'flex',
		alignItems: 'center',
	},
}));
