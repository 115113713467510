import { Grid, Divider } from '@mui/material';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { UseStyle } from '../Style';
import CustomButton from '../../../../../components/Button';
import { BANK_LIST } from '../utils';

interface Props {
	setOpenModal: (avg: boolean) => void;
}

const PayBaks = ({ setOpenModal }: Props) => {
	const classes = UseStyle();

	return (
		<Grid container columns={12} spacing={1} style={{ marginTop: 50 }}>
			<Grid item xs={12} md={3}>
				<label style={{ fontWeight: 500 }}>Pago en bancos</label>
			</Grid>
			<Grid item xs={12} md={9}>
				<label style={{ fontWeight: 500 }}>Todos los bancos</label>
				<p>
					En ventanilla o a través de la Banca Electrónica, vía SPEI,{' '}
					<strong>
						haz una transferencia electrónica, con los siguientes datos:
					</strong>
				</p>
				<div className={classes.PayStateUniteItem}>
					<CheckCircleOutlineRoundedIcon
						style={{
							fontSize: 18,
							color: '#29398E',
							borderColor: '#EDF4FF',
							marginTop: 2,
							marginRight: 5,
						}}
					/>
					<label>CLABE 021180550300050811</label>
				</div>
				<div className={classes.PayStateUniteItem}>
					<CheckCircleOutlineRoundedIcon
						style={{
							fontSize: 18,
							color: '#29398E',
							borderColor: '#EDF4FF',
							marginTop: 2,
							marginRight: 5,
						}}
					/>
					<label>Banco destino: HSBC</label>
				</div>
				<div className={classes.PayStateUniteItem}>
					<CheckCircleOutlineRoundedIcon
						style={{
							fontSize: 18,
							color: '#29398E',
							borderColor: '#EDF4FF',
							marginTop: 2,
							marginRight: 5,
						}}
					/>
					<label>
						El número 5503, en el campo que pide la referencia o referencia
						numérica y que puedes distinguir porque no admite más de 7
						dígitos.
					</label>
				</div>
				<div className={classes.PayStateUniteItem}>
					<CheckCircleOutlineRoundedIcon
						style={{
							fontSize: 18,
							color: '#29398E',
							borderColor: '#EDF4FF',
							marginTop: 2,
							marginRight: 5,
						}}
					/>
					<label>
						Tu número de crédito que consta de 10 dígitos, en el campo
						nombrado como: <br />· Concepto de pago <br />· Motivo de pago{' '}
						<br />· Referencia alfanumérica entre otros
					</label>
				</div>

				<Divider style={{ margin: '10px 0px' }} />

				<label style={{ fontWeight: 500 }}>Todos los bancos</label>
				<p>
					En ventanilla o a través de la banca electrónica, vía SPEI,{' '}
					<strong>
						haz una transferencia electrónica, con los siguientes datos:
					</strong>
				</p>
				<div className={classes.PayStateUniteItem}>
					<CheckCircleOutlineRoundedIcon
						style={{
							fontSize: 18,
							color: '#29398E',
							borderColor: '#EDF4FF',
							marginTop: 2,
							marginRight: 5,
						}}
					/>
					<label>CLABE 012914002008282892</label>
				</div>
				<div className={classes.PayStateUniteItem}>
					<CheckCircleOutlineRoundedIcon
						style={{
							fontSize: 18,
							color: '#29398E',
							borderColor: '#EDF4FF',
							marginTop: 2,
							marginRight: 5,
						}}
					/>
					<label>Banco destino: BBVA</label>
				</div>
				<div className={classes.PayStateUniteItem}>
					<CheckCircleOutlineRoundedIcon
						style={{
							fontSize: 18,
							color: '#29398E',
							borderColor: '#EDF4FF',
							marginTop: 2,
							marginRight: 5,
						}}
					/>
					<label>Convenio CIE: 828289</label>
				</div>
				<div className={classes.PayStateUniteItem}>
					<CheckCircleOutlineRoundedIcon
						style={{
							fontSize: 18,
							color: '#29398E',
							borderColor: '#EDF4FF',
							marginTop: 2,
							marginRight: 5,
						}}
					/>
					<label>
						Referencia: únicamente los 10 dígitos de tu número de crédito
					</label>
				</div>

				<p style={{ color: '#000' }}>
					Al hacer tu pago por banca electrónica, por el mismo medio se te
					enviará si tu pago fue aceptado o rechazado, así como la clave de
					rastreo correspondiente.
				</p>

				{/* Lista de bancos */}
				<div className={classes.PayBaksItem}>
					<label style={{ fontWeight: 500, color: '#000' }}>Banco</label>
					<label style={{ fontWeight: 500, color: '#000' }}>
						¿Qué debo hacer?
					</label>
				</div>
				{BANK_LIST.map((elemnt, index) => (
					<div
						key={index}
						className={
							index === 0 ? classes.PayBaksItemFirts : classes.PayBaksItem
						}
					>
						<label>{elemnt.nombre}</label>
						{elemnt.incisos}
					</div>
				))}
			</Grid>
			<Grid item xs={12} md={12}>
				<Divider style={{ margin: '20px 0px' }} />
			</Grid>
			<Grid item xs={12} md={12}>
				<div style={{ maxWidth: 250, margin: 'auto', marginBottom: 40 }}>
					<CustomButton
						variant="solid"
						onClick={() => setOpenModal(false)}
						label="Regresar"
					/>
				</div>
			</Grid>
		</Grid>
	);
};

export default PayBaks;
