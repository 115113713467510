import { makeStyles, styled } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Pagination, colors } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
	containerFilter: {
		display: 'grid',
		gridTemplateColumns: '250px 250px 250px 100px',
		gap: 10,
		marginBottom: 30,

		[theme.breakpoints.down('md')]: {
			display: 'flex',
			flexDirection: 'column',
		},
	},
	buttonSearch: {
		width: 200,
		display: 'flex',
		alignItems: 'end',

		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	containerPaginator: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: 10,
	},
	tableCellCamp: {
		background: colors.grey[100],
		fontSize: '15px !important',
	},
	titlePdf: {
		color: '#DD0528',
		textAlign: 'center',
		margin: '30px 0px 0px 0px',
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	containerPdf: {
		display: 'flex',
		justifyContent: 'center',
		textAlign: 'center',
		flexDirection: 'column',
		marginTop: 30,
		marginBottom: 30,

		'& > h4': {
			margin: '0px 0px 10px 0px',
			color: colors.grey[600],
		},
		'& > p': {
			margin: 0,
		},
	},
	containerAclaraciones: {
		display: 'flex',
		justifyContent: 'center',
		textAlign: 'center',
		flexDirection: 'column',
		marginTop: 30,
		marginBottom: 20,

		'& > h3': {
			margin: '0px 0px 10px 0px',
			color: colors.grey[800],
		},
		'& > p': {
			margin: 0,
		},
		'& > div.btnAclaracion': {
			margin: '30px auto auto auto',
			width: 200,
		},
	},
	noHayDatosContainer: {
		display: 'flex',
		justifyContent: 'center',
        padding: '20px 10px',
	},
	iconoPdf: {
		display: 'flex',
		justifyContent: 'center',
        padding: '20px 10px',
	},
}));

export const MomentsAccountPaginator = styled(Pagination)({
	'& .Mui-selected': {
		backgroundColor: '#DD0528 !important',
		border: '1px solid #DD0528',
		color: '#fff !important',
	},
	'& .MuiPaginationItem-icon': {
		color: '#fff',
		backgroundColor: colors.grey[500],
		height: 30,
		width: 30,
		borderRadius: 5,
	},
});
