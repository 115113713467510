import API from "..";
import { BalanceMomentAPI, IMovimientosAPI } from "../../interfaces/balanceMoment";

class BalancesMovementsAPI extends API {
    getData(data: string): Promise<BalanceMomentAPI> {
		return this.requestWrapper(
			this.request({
				url: `/v1/saldos-y-movimientos/obtener-saldos-y-movimientos`,
				method: 'POST',
				data,
			})
		);
	}
	getMovimientos(data: string): Promise<IMovimientosAPI> {
		return this.requestWrapper(
			this.request({
				url: `/v1/saldos-y-movimientos/obtener-movimientos-credito`,
				method: 'POST',
				data,
			})
		);
	}
    getMovimientosPDF(data: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/saldos-y-movimientos/obtener-reporte-movimientos-credito`,
				method: 'POST',
				data,
			})
		);
	}
	getPeriodoMensual(data: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/saldos-y-movimientos/obtener-periodos-reporte-mensual`,
				method: 'POST',
				data,
			})
		);
	}
	getReporteMensual(data: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/saldos-y-movimientos/obtener-reporte-mensual`,
				method: 'POST',
				data,
			})
		);
	}
	getReporteHistorico(data: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/saldos-y-movimientos/obtener-reporte-historico`,
				method: 'POST',
				data,
			})
		);
	}
    getCertificadoCalidad(data: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/saldos-y-movimientos/obtener-certificado-calidad`,
				method: 'POST',
				data,
			})
		);
	}
    getStatusPaperless(data: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/saldos-y-movimientos/paperless/consultar-estatus`,
				method: 'POST',
				data,
			})
		);
	}
    acceptPaperless(data: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/saldos-y-movimientos/paperless/registrar`,
				method: 'POST',
				data,
			})
		);
	}
	pagoCodi(data: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/saldos-y-movimientos/generar-pago-codi`,
				method: 'POST',
				data,
			})
		);
	}
	pagoBBVA(data: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/saldos-y-movimientos/generar-pago-bancomer`,
				method: 'POST',
				data,
			})
		);
	}
	getAlertCreditLiquid(data: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/saldos-movimientos/alertamiento-creditos-liquidados`,
				method: 'POST',
				data,
			})
		);
	}
}

export const balancesMovementsAPI = new BalancesMovementsAPI('');