import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: 16,
	},
    subTitle: {
		color: theme.palette.info.main,
		fontWeight: 500,
	},
	containerButton: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',

		[theme.breakpoints.down('md')]: {
			flexDirection: 'column-reverse',
		},
	},
    button: {
		maxWidth: 200,
		margin: 10,

		[theme.breakpoints.down('md')]: {
			maxWidth: '100%',
		},
	},
}));
