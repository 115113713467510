export const TOOLTIPS = {
	MONTO_ESPERADO:
		'Monto a pagar del mes en curso. Incluye intereses, capital, seguros y cuotas.',
	MENSUALIDADES_VENCIDAS:
		'Monto de las mensualidades que no se realizaron en el tiempo esperado.',
	SALDO_DE_CREDITO: 'Monto total del crédito pendiente por pagar.',
	FORMA_PAGO_ROA:
		'Cuando tu crédito esta en Régimen Ordinario de Amortización(ROA) significa que tu empresa entrega al Infonavit el pago mensual que te descuenta de tu nómina.',
	FORMA_PAGO_REA:
		'Cuando tu crédito esta en Régimen Ordinario de Amortización(ROA) significa que tu empresa entrega al Infonavit el pago mensual que te descuenta de tu nómina.',
	PAGO_ESTAR_CORRIENTE:
		'Importe que incluye el pago del mes, los pagos no realizados, o los que se hicieron de manera incompleta, más seguros y cuotas.',
	MONTO_ESPERADO_FFP:
		'Es el complemento que se hace el pago de FFP para cubrir la mensualidad en pesos',
};
