import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    container: {
		fontSize: 17
	},
    title: {
		margin: 0, 
        fontWeight: 500
	},
    labelBold: {
        fontWeight: 500
	},
}));
