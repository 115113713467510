import { CODES } from '../../../utils';
import { useContextMoment } from '../../../context';
import SolitaryModule from '../../../components/SolitaryModule';
import MomentsComponent from './MomentsComponent';

const MomentsAccount = () => {
	const { balance } = useContextMoment();

	return (
		<>
			{[
				CODES.LIQUIDADOS_POR_PAGO,
				CODES.LIQUIDADOS_OTROS,
				CODES.CASOS_ESPECIALES_NP_EF_DA,
				CODES.CASOS_ESPECIALES_RENEWAL_NO_DEMANDA,
				CODES.RECUPERACION_DEMANDADO_SIN_CONVENIO_JUDICIAL,
				CODES.RECUPERACION_SENTENCIA,
			].includes(balance.propiedades?.tipoCaso) ? (
				<SolitaryModule />
			) : (
				<MomentsComponent />
			)}
		</>
	);
};

export default MomentsAccount;
