import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	containerLabel: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: '#FFE2E5',
		borderRadius: 20,
		padding: 5,
		paddingInline: 10,
		minWidth: 100,
		width: 'auto',
		height: 30,
		fontSize: 12,
        marginTop: 6,
	},
}));
