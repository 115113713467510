import { BALANCES_MOMENTS_PAGE } from '../../utils';
import { CreditBalanceMovements } from '../Credit';
import MovementsAccount from '../MovementsAccount';
import { CreditOrigin } from '../CreditOrigin';
import Tabs from '../../../../components/Tabs';
import CardTopInfo from '../../components/CardTopInfo';
import { useBalance } from './hooks/useBalance';

const BalanceMoment = () => {
	const {
		tabsEle,
		containerPosition,
		tabActive,
		handleChageTab,
		changeTabCustom,
	} = useBalance();

	return (
		<>
			<CardTopInfo />
			<Tabs
				tabs={tabsEle}
				cardsType
				containerPosition={containerPosition}
				active={tabActive}
				onChangeTab={(indexTab: number) => changeTabCustom(indexTab)}
			>
				<>
					{tabActive === BALANCES_MOMENTS_PAGE.HOME && (
						<CreditBalanceMovements
							handleChageTab={handleChageTab}
						/>
					)}
					{tabActive === BALANCES_MOMENTS_PAGE.MOMENT_ACCOUNT && (
						<MovementsAccount />
					)}
					{tabActive === BALANCES_MOMENTS_PAGE.CREDIT_ORIGIN && (
						<CreditOrigin />
					)}
				</>
			</Tabs>
		</>
	);
};

export default BalanceMoment;
