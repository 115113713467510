import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	tooltipIcon: {
		fontSize: '18px !important',
	},
	redTitle: {
		fontSize: 20,
		color: theme.palette.primary.main,
		fontWeight: '500',
		marginBottom: 0,
	},
	redSubTitle: {
		fontSize: 18,
		marginTop: 0,
	},
}));
