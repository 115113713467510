import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		borderRadius: 10,
		borderColor: theme.palette.grey[300],
		borderStyle: 'solid',
		borderWidth: 0.5,
		padding: 20,
	},
	labelImportant: {
		fontSize: 17,
		'& > label': {
			color: theme.palette.primary.main,
			fontWeight: '500',
		},
	},
	labelTitle: {
		fontSize: 17,
		fontWeight: '500',
		textAlign: 'center',
	},
	sectionInfoPrices: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	labelTitleInfo: {
		color: theme.palette.grey[500],
		marginTop: 5,
		marginBottom: 5,
	},
	labelTitleInfoPrice: {
		color: theme.palette.grey[500],
		fontWeight: '500',
	},
	labelSubTitleInfoPrice: {
		fontWeight: '500',
	},
	divider: {
		width: '100%',
		backgroundColor: theme.palette.grey[300],
		height: 1,
		marginBottom: 10,
		marginTop: 10,
	},
	containerAlert: {
		backgroundColor: theme.palette.grey[200],
		borderRadius: 2,
		padding: 10,
		marginTop: 10,
	},
	labelAlert: {
		margin: 0,
		fontSize: 15,
		'& > label': {
			marginLeft: 10,
			color: theme.palette.info.main,
		},
		'& > p': {
			margin: 0,
			marginLeft: 10,
			textAlign: 'center',
		},
	},
	tooltipIcon: {
		fontSize: '18px !important',
		marginBottom: -5,
	},
	pInfoReaPayment: {
		fontSize: 15,
		'& > strong': {
			fontWeight: '500',
		},
	},
}));
