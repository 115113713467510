import API from '..';

class ProfileAPI extends API {
	fetchAddressInfo(data: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: '/address-info',
				method: `POST`,
				data,
			})
		);
	}
	fetchAddressCatalog(data: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: '/address-catalog',
				method: `POST`,
				data,
			})
		);
	}
}
export const profileAPI = new ProfileAPI('');

export const contactRequestAPI = {
	confirmPhoneNumber: new API('/confirm-phoneNumber-code'),
	updatePhoneNumber: new API('/update-phoneNumber'),
	updateMainEmail: new API('/update-email'),
	resendUpdateMainEmail: new API('/contact-info/resend/email-confirmation'),
	updateAltEmail: new API('/update-alternate-email'),
	resendUpdateAltEmail: new API(
		'/contact-info/resend/alternate-email-confirmation'
	),
	updateAddress: new API('/update-address'),
	updateReference: new API('/update-reference-info'),
	validateEmail: new API('/validate-email'),
	validationEmail: new API('/validation-email'),
	validationPhone: new API('/validation-mobile'),

	fetchReferenceInfo: new API('/reference-info'),
	fetchAddressInfo: new API('/address-info'),
	fetchContactInfo: new API('/contact-info'),
	fetchAddressCatalog: new API('/address-catalog'),

	getRedirect: new API('/pass-session'),

	validateDatos: new API('/validation/datos-contacto'),
	getDatos: new API('/consulta/datos-contacto'),
	updateDatos: new API('/actualiza/datos-contacto'),
};
