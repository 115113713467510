import Layout from '../../components/Layout';
import { ContextMoment } from './context';
import ModalLoading from '../../components/ModalLoading';
import { useBalanceMovements } from './hooks/useBalanceMovements';
import CustomAlert from '../../components/CustomAlert';
import BalanceMoment from './views/BalanceMoment';
import PayCredit from './views/PayMyCredit';
import { BALANCES_MAIN_PAGE } from './utils';
import { Box, Paper } from '@mui/material';
import CardTopInfo from './components/CardTopInfo';
import Paperless from './components/Paperless';
import ModalAlertCredit from './components/ModalAlertCredit';

const BalancesMoments = () => {
	const {
		balance,
		pageMain,
		loading,
		message,
		openPaperless,
		certificado,
		modalCreditLiquid,
		messageCreditLiquid,
		setOpenPaperless,
		setPageMain,
		acceptPaperless,
		setModalCreditLiquid,
	} = useBalanceMovements(true);

	return (
		<Layout>
			<ContextMoment.Provider
				value={{ balance, pageMain, certificado, setPageMain }}
			>
				<ModalLoading loading={loading} />
				<Paperless
					showModal={openPaperless}
					setShowModal={setOpenPaperless}
					setAccept={acceptPaperless}
				/>
				<ModalAlertCredit
					show={modalCreditLiquid}
					setShow={setModalCreditLiquid}
					message={messageCreditLiquid}
				/>
				{message ? (
					<>
						<CardTopInfo onlyHeader />
						<Paper>
							<Box padding={2}>
								<CustomAlert
									message={message}
									severity="warning"
									show
								/>
							</Box>
						</Paper>
					</>
				) : (
					<>
						{pageMain === BALANCES_MAIN_PAGE.CREDIT && (
							<BalanceMoment />
						)}
						{pageMain === BALANCES_MAIN_PAGE.PAYMENT && (
							<PayCredit />
						)}
					</>
				)}
			</ContextMoment.Provider>
		</Layout>
	);
};

export default BalancesMoments;
