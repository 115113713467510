import { Grid } from '@mui/material';
import { SectionBalance } from '../../../components/SectionBalance';
import { CardBalance } from '../../../components/CardBalance';
import { LabelCardBalanceValues } from '../../../components/LabelCardBalanceValues';
import { Info } from '@mui/icons-material';
import { useStyles } from '../styles';
import { useContextMoment } from '../../../context';

const HeaderTotalMoney = () => {
	const { balance } = useContextMoment();
	const classes = useStyles();
	return (
		<>
			<SectionBalance>
				<Grid container spacing={2}>
					{Number(balance.pagos?.saldoALiquidar || '0') > 0 && (
						<Grid item xs={12} md>
							<CardBalance title="Saldo para liquidar tu crédito">
								<LabelCardBalanceValues
									variant="primary"
									mxnValue={Number(
										balance.pagos?.saldoALiquidar || '0'
									)}
								/>
							</CardBalance>
						</Grid>
					)}
					{Number(balance.pagos?.saldoALiquidarDescuento || '0') >
						0 && (
						<Grid item xs={12} md>
							<CardBalance title="Saldo a liquidar con descuento">
								<LabelCardBalanceValues
									variant="info"
									mxnValue={Number(
										balance.pagos
											?.saldoALiquidarDescuento || '0'
									)}
								/>
							</CardBalance>
						</Grid>
					)}
					<Grid item xs={12}>
						<div>
							<Info
								color="info"
								className={classes.tooltipIcon}
							/>
							<label className={classes.labelTooltipTitle}>
								Si vas a liquidar tu crédito con descuento, paga
								en una exhibición directamente en cualquier
								banco autorizado.
							</label>
						</div>
					</Grid>
				</Grid>
			</SectionBalance>
		</>
	);
};

export default HeaderTotalMoney;
