import CustomButton from '../../../../../components/Button';
import { usePayMyCredit } from '../hook/usePayMyCredit';
import { useStyles } from './styles/PaymentRejected.style';

const PaymentRejected = () => {
	const { rejected, resetFormPay } = usePayMyCredit();
	const classes = useStyles();
	return (
		<>
			<div className={classes.container}>
				<p className={classes.subTitle}>{rejected}</p>
			</div>
			<div className={classes.containerButton}>
				<CustomButton
					label={'Regresar'}
					variant="outlined"
					onClick={resetFormPay}
					classNames={classes.button}
				/>
			</div>
		</>
	);
};

export default PaymentRejected;
