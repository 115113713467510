import { IDataBalanceMoment } from '../../../../interfaces/balanceMoment';

export const TYPES_BREAKDOWNS = {
	MONTH: 1,
	DAY: 2,
}

export const getMontoEsperado = (item: IDataBalanceMoment) => {
	return [
		{
			label: 'Factor de pago',
			monto: item.montoEsperado?.desglose?.factorPago,
			montoVSM: item.montoEsperado?.desglose?.factorPagoVsm,
		},
		{
			label: 'Seguros y cuotas del periodo',
			monto: item.montoEsperado?.desglose?.segurosCuotasPeriodo,
			montoVSM: item.montoEsperado?.desglose?.segurosCuotasPeriodoVsm,
			detail: [
				{
					label: 'Seguro de daños',
					monto: item.montoEsperado?.desglose?.segurosCuotas
						?.seguroDanos,
					montoVSM:
						item.montoEsperado?.desglose?.segurosCuotas
							?.seguroDanosVsm,
				},
				{
					label: 'Cuota de administración',
					monto: item.montoEsperado?.desglose?.segurosCuotas.ctaAdmin,
					montoVSM:
						item.montoEsperado?.desglose?.segurosCuotas.ctaAdminVsm,
				},
				{
					label: 'Fondo de protección de pagos',
					monto: item.montoEsperado?.desglose?.segurosCuotas
						.fondoProtec,
					montoVSM:
						item.montoEsperado?.desglose?.segurosCuotas
							.fondoProtecVsm,
				},
				{
					label: 'Cuota de conservación',
					monto: item.montoEsperado?.desglose?.segurosCuotas.ctaConser,
					montoVSM:
						item.montoEsperado?.desglose?.segurosCuotas.ctaConserVsm,
				},
			],
		},
		{
			label: 'Monto total del pago',
			monto: item.montoEsperado?.desglose?.segurosCuotas?.montoTotalPago,
			montoVSM:
				item.montoEsperado?.desglose?.segurosCuotas?.montoTotalPagoVsm,
			isBold: true,
		},
	];
};

export const getSaldoInicioMes = (item: IDataBalanceMoment) => {
	return [
		{
			label: 'Saldo al inicio del mes',
			monto: item.saldoCredito?.saldoInicioMesDesglose?.saldoInicioMes,
			montoVSM: item.saldoCredito?.saldoInicioMesDesglose?.saldoInicioMesVsm,
		},
		{
			label: 'Capital',
			monto: item.saldoCredito?.saldoInicioMesDesglose?.capital,
			montoVSM: item.saldoCredito?.saldoInicioMesDesglose?.capitalVsm,
		},
		{
			label: 'Intereses devengados no pagados',
			monto: item.saldoCredito?.saldoInicioMesDesglose?.interesesDevengados,
			montoVSM: item.saldoCredito?.saldoInicioMesDesglose?.interesesDevengadosVsm,
		},
		{
			label: 'Accesorios devengados no pagados',
			monto: item.saldoCredito?.saldoInicioMesDesglose?.accesoriosDevengados?.total,
			montoVSM:
				item.saldoCredito?.saldoInicioMesDesglose?.accesoriosDevengados?.totalVsm,
			detail: [
				{
					label: 'Cuota de administración',
					monto: item.saldoCredito?.saldoInicioMesDesglose?.accesoriosDevengados?.ctaAdmin,
					montoVSM: item.saldoCredito?.saldoInicioMesDesglose?.accesoriosDevengados?.ctaAdminVsm,
				},
				{
					label: 'Fondo de protección de pagos',
					monto: item.saldoCredito?.saldoInicioMesDesglose?.accesoriosDevengados?.fondoProtec,
					montoVSM: item.saldoCredito?.saldoInicioMesDesglose?.accesoriosDevengados?.fondoProtecVsm,
				},
				{
					label: 'Seguro de daños',
					monto: item.saldoCredito?.saldoInicioMesDesglose?.accesoriosDevengados?.seguroDanos,
					montoVSM: item.saldoCredito?.saldoInicioMesDesglose?.accesoriosDevengados?.seguroDanosVsm,
				},
			],
		},
		{
			label: 'Saldo actual',
			monto: item.saldoCredito?.saldoInicioMes,
			montoVSM: item.saldoCredito?.saldoInicioMesVsm,
			isBold: true,
		},
	];
};

export const getSaldoAlDia = (item: IDataBalanceMoment) => {
	return [
		{
			label: 'Saldo al dia',
			monto: item.saldoCredito?.saldoDiaDesglose?.saldoAlDia,
			montoVSM: item.saldoCredito?.saldoDiaDesglose?.saldoAlDiaVsm,
		},
		{
			label: 'Capital',
			monto: item.saldoCredito?.saldoDiaDesglose?.capital,
			montoVSM: item.saldoCredito?.saldoDiaDesglose?.capitalVsm,
		},
		{
			label: 'Intereses devengados no pagados',
			monto: item.saldoCredito?.saldoDiaDesglose?.interesesDevengados,
			montoVSM: item.saldoCredito?.saldoDiaDesglose?.interesesDevengadosVsm,
		},
		{
			label: 'Accesorios devengados no pagados',
			monto: item.saldoCredito?.saldoDiaDesglose?.accesoriosDevengados?.total,
			montoVSM:
				item.saldoCredito?.saldoDiaDesglose?.accesoriosDevengados?.totalVsm,
			detail: [
				{
					label: 'Cuota de administración',
					monto: item.saldoCredito?.saldoDiaDesglose?.accesoriosDevengados?.ctaAdmin,
					montoVSM: item.saldoCredito?.saldoDiaDesglose?.accesoriosDevengados?.ctaAdminVsm,
				},
				{
					label: 'Fondo de protección de pagos',
					monto: item.saldoCredito?.saldoDiaDesglose?.accesoriosDevengados?.fondoProtec,
					montoVSM: item.saldoCredito?.saldoDiaDesglose?.accesoriosDevengados?.fondoProtecVsm,
				},
				{
					label: 'Seguro de daños',
					monto: item.saldoCredito?.saldoDiaDesglose?.accesoriosDevengados?.seguroDanos,
					montoVSM: item.saldoCredito?.saldoDiaDesglose?.accesoriosDevengados?.seguroDanosVsm,
				},
			],
		},
		{
			label: 'Saldo actual',
			monto: item.saldoCredito?.saldoDia,
			montoVSM: item.saldoCredito?.saldoDiaVsm,
			isBold: true,
		},
	];
};