import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		width: 'auto',

		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	label: {
        display: 'block',
        textAlign: 'center',
		fontSize: 17,
		fontWeight: 'bold',
	},
	tubTitle: {
		fontSize: 17,
		textAlign: 'center',
	},
}));
