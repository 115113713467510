import { useEffect, useState } from 'react';
import { Grid, Paper } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Carousel from 'react-multi-carousel';
import { useStyles } from './styles/my-sub-account.styles';
import MiniMediaCard from './mini-media-card.component';
import useTallerMas from '../hooks/useElements';
import { getResponse, WORKSHOP_VIEWS } from '../utils';
import CardViewContent from './cardViewContent';
import { Elemento } from '../../../../../interfaces/tallerSaberMas';
import CarouselCustom from './CarouselCustom';
import RenderHTML from '../../../../../components/RenderHTML';

export default function MySubAccount() {
	const styles = useStyles();
	const { elementos, matches } = useTallerMas();
	const MY_SUB_ACCOUNT = elementos[WORKSHOP_VIEWS.MY_SUB_ACCOUNT.code];
	const [elemento, setElemento] = useState<Elemento>({} as Elemento);

	useEffect(() => {
		if (
			MY_SUB_ACCOUNT &&
			MY_SUB_ACCOUNT.secciones[0].elementos.length > 0
		) {
			setElemento(MY_SUB_ACCOUNT.secciones[0].elementos[0]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const elementosJSX =
		MY_SUB_ACCOUNT?.secciones[0]?.elementos.map((el, k) => (
			<MiniMediaCard
				variant="blue"
				variantLabel={el.estatus === 0 ? 'pending' : 'completed'}
				label={el.nombre}
				onClick={() => setElemento(el)}
				isPlaying={el.idElemento === elemento.idElemento}
				isComplete={el.estatus === 1}
				key={k}
			/>
		)) || [];

	return (
		<Paper elevation={2} className={styles.container}>
			<Grid container columns={16} spacing={2}>
				<Grid item sm={16} md={10} lg={10}>
					<CardViewContent
						elemento={elemento}
						setElemento={setElemento}
					/>
					<RenderHTML
						className={styles.labelLegend}
						htmlContent={elemento?.descripcion}
					/>
				</Grid>
				<Grid item sm={16} md={6} lg={6}>
					{!matches && <CarouselCustom items={elementosJSX} />}
				</Grid>
			</Grid>
			{matches && (
				<Carousel
					customLeftArrow={
						<div className={styles.arrowVideoL}>
							<div>
								<ChevronLeftIcon />
							</div>
						</div>
					}
					customRightArrow={
						<div className={styles.arrowVideoR}>
							<div>
								<ChevronRightIcon />
							</div>
						</div>
					}
					responsive={getResponse(
						MY_SUB_ACCOUNT?.secciones[0]?.elementos.length
					)}
				>
					{elementosJSX.map((el) => el)}
				</Carousel>
			)}
		</Paper>
	);
}
