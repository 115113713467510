import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.palette.grey[200],
		height: 'auto',
		padding: 20,
		marginBottom: 20,
	},
	containerTitle: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		marginBottom: 20,
	},
	labelTitle: {
		display: 'flex',
		alignItems: 'center',
		fontSize: 19,
		fontWeight: '500',
	},
	tooltipIcon: {
		fontSize: '18px !important',
		marginLeft: 4,
	},
}));
