import { Link } from '@mui/material';

interface ITiendaList {
	nombre: string | JSX.Element | JSX.Element[];
	list1?: Array<string | JSX.Element | JSX.Element[]>;
	list2?: Array<string | JSX.Element | JSX.Element[]>;
	list1Url?: Array<{ title: string; url: string; text?: boolean }>;
}

export const BANK_LIST_ENTITY: Array<{ entidad: string; clave: string; viaPago: string }> = [
	{ entidad: 'Banco Azteca', clave: 'Infonavit', viaPago: 'Efectivo' },
	{ entidad: 'Banco del Bienestar', clave: 'Infonavit', viaPago: 'Efectivo o Cheque' },
	{ entidad: 'BanCoppel', clave: 'Infonavit', viaPago: 'Efectivo o Cheque' },
	{ entidad: 'Banorte', clave: 'Infonavit', viaPago: 'Banca Móvil Transferencia' },
	{ entidad: 'BanRegio', clave: 'Infonavit', viaPago: 'Banca Móvil Transferencia' },
	{ entidad: 'BBVA', clave: 'CIE 828289', viaPago: 'Efectivo o Transferencia' },
	{ entidad: 'Citibanamex', clave: 'Infonavit', viaPago: 'Efectivo o Cheque' },
	{ entidad: 'HSBC', clave: 'RAP 5081', viaPago: 'Efectivo o Cheque' },
	{ entidad: 'Santander', clave: 'CUENTA 1234', viaPago: 'Efectivo o Cheque' },
	{ entidad: 'Scotiabank', clave: 'Infonavit', viaPago: 'Efectivo o Cheque' },
];

export const BANK_LIST = [
	{
		nombre: 'Scotiabank',
		incisos: (
			<label>
				I) Paga en línea a través de ScotiaWeb <br />
				II) Acude a la sucursal de tu preferencia y dile al cajero la cantidad a
				pagar y los 10 dígitos de tu número de crédito.
			</label>
		),
	},
	{
		nombre: 'Banco Bienestar',
		incisos: (
			<label>
				I) Acude a la sucursal de tu preferencia, dale al cajero los 10 dígitos de
				tu número de crédito e indícale la cantidad a pagar.
			</label>
		),
	},
	{
		nombre: 'BanCoppel',
		incisos: (
			<label>
				I) Acude a la sucursal de tu preferencia, dale al cajero los 10 dígitos de
				tu número de crédito e indícale la cantidad a pagar.
			</label>
		),
	},
	{
		nombre: 'Citibanamex',
		incisos: (
			<label>
				I) Paga en línea en BancaNet Banamex. <br />
				II) Acude a la sucursal de tu preferencia y dile al cajero la cantidad a
				pagar y los 10 dígitos de tu número de crédito.
			</label>
		),
	},
	{
		nombre: 'BBVA',
		incisos: (
			<label>
				I) Pagar en su Banca en línea. <br />
				II) Cajeros automáticos las 24 horas de los 365 días del año. Si quieres
				hacerlo sólo necesitas los 10 dígitos de tu número de crédito; la cantidad
				a pagar se descontará del saldo de tu tarjeta de débito BBVA Bancomer.{' '}
				<br />
				III) Practicajas (cajeros receptores) de este banco las 24 horas de los
				365 días del año. Si quieres hacerlo sólo necesitas los 10 dígitos de tu
				número de crédito. <br />
				IV) Acude a la sucursal de tu preferencia dale al cajero los 10 dígitos de
				tu número de crédito e indícale la cantidad a pagar así como el número de
				convenio con el Infonavit CIE 828289.
			</label>
		),
	},
	{
		nombre: 'Santander',
		incisos: (
			<label>
				I) Paga en línea a través de Supernet. <br />
				II) Acude a la sucursal de tu preferencia y dile al cajero que deseas
				depositar en la cuenta 1234 a nombre del Infonavit. Indícale la cantidad a
				pagar y los 10 dígitos de tu número de crédito.
			</label>
		),
	},
	{
		nombre: 'HSBC',
		incisos: (
			<label>
				I) Si lo prefieres paga en Banca personal por Internet. <br />
				II) Cajeros automáticos las 24 horas de los 365 días del año. Si quieres
				hacerlo sólo necesitas los 10 dígitos de tu número de crédito, la cantidad
				a pagar se descontará del saldo de tu tarjeta de débito HSBC. <br />
				III) Acude a la sucursal de tu preferencia y dile al cajero que deseas
				depositar en el RAP 5081 a nombre de Infonavit. Indícale la cantidad a
				pagar, y cuando te pida el número de referencia, dale los 10 dígitos de tu
				número de crédito.
			</label>
		),
	},
	{
		nombre: 'Banorte',
		incisos: <label>I) Recepción en Banco en Línea o Banca Móvil Banorte.</label>,
	},
	{
		nombre: 'Banco Azteca',
		incisos: (
			<label>
				I) Acude a la sucursal de tu preferencia y dile al cajero la cantidad a
				pagar y los 10 dígitos de tu número de crédito.
			</label>
		),
	},
	{
		nombre: 'BanRegio',
		incisos: (
			<label>
				I) Acude a la sucursal de tu preferencia y dile al cajero la cantidad a
				pagar y los 10 dígitos de tu número de crédito.
			</label>
		),
	},
];

export const LinkCustom = ({ title, url, setUrlOut }: any) => (
	<Link underline="none" onClick={() => setUrlOut(url)}>
		{title}
	</Link>
);

export const TIENDA_LIST: ITiendaList[] = [
	// {
	// 	nombre: 'Chedraui',
	// 	list1: [
	// 		'Almacenes Chedraui',
	// 		'Selecto Chedraui',
	// 		'Super City',
	// 		'Superché',
	// 		'Super Chedraui',
	// 	],
	// },
	// {
	// 	nombre: 'Soriana',
	// 	list1: [
	// 		'Soriana Express',
	// 		'Soriana Hiper',
	// 		'Soriana Mercado',
	// 		'Soriana Súper',
	// 		'Bodega Comercial Mexicana',
	// 		'City Club',
	// 		'Mega',
	// 		'Alprecio',
	// 	],
	// },
	// {
	// 	nombre: 'La Comer (City Fresko)',
	// 	list1: ['Fresko', 'City Market', 'La Comer', 'Sumesa'],
	// },
	// {
	// 	nombre: 'Elektra',
	// 	list1: ['Banco Azteca', 'Modatelas', 'Prestaprenda', 'Tiendas Neto'],
	// },
	{
		nombre: 'Telecomm',
		list1Url: [
			{ title: 'Telecomm', url: 'https://telecomm.gob.mx/gobmx/sgst/' },
		],
	},
	// {
	// 	nombre: 'Famsa',
	// 	list1: ['Famsa'],
	// },
	// {
	// 	nombre: 'Gesto Pago - Voltra',
	// 	list1: [
	// 		'Aceros Murillo',
	// 		'Avanza Solido',
	// 		'Banco Inmobiliario Mexicano, Institución de Banca Múltiple',
	// 		'Best Cafetería',
	// 		'Botica Central',
	// 		'BPINK',
	// 		'Cadena',
	// 		'Caja Hipódromo',
	// 		'Caja inmaculada',
	// 		'Caja Morelia Valladolid',
	// 		'Caja Popular Cristóbal Colón',
	// 		'Caja Solidaria Tamaulipas',
	// 		'Caja Tepic',
	// 		'Casa Cravioto',
	// 		'Cocdep',
	// 		'Colto Money',
	// 		'Comercial Lahuretana',
	// 		'Con ser tu amigo',
	// 		'Consol Negocios',
	// 		'Crediavance',
	// 		'Credicapital',
	// 		'CrediConfia',
	// 		'DigitalShop',
	// 		'Disensa',
	// 		'DLI',
	// 		'Emprende',
	// 		'Farma y más',
	// 		'Farmacias Gi',
	// 		'Ferretería Calzada',
	// 		'Financiera Coincidir',
	// 		'Financiera Súmate',
	// 	],
	// 	list2: [
	// 		'Financiera SumeMas',
	// 		'FinReal',
	// 		'Fipabide',
	// 		'FlashMarket',
	// 		'Grupo Lumma',
	// 		'Intrabienestar',
	// 		'La Ferre',
	// 		'Ladies',
	// 		'Las 7 Vidas Empeños',
	// 		'Maskapital',
	// 		'Mercado de Importaciones',
	// 		'MexPei',
	// 		'Multiva',
	// 		'PAGOS DIGITALES.COM',
	// 		'PrestoCash',
	// 		'Sale Vale',
	// 		'Santandreu',
	// 		'Solución ASEA',
	// 		'Star Alai',
	// 		'SuFirma',
	// 		'Super Papelera',
	// 		'SuperRED',
	// 		'Tamex',
	// 		'Ventanilla Fácil',
	// 		'MercadoPago',
	// 		'Albo.Mx',
	// 		'Hola Ca$h',
	// 		'Enso',
	// 		'Ta3 (Pagaqui)',
	// 	],
	// },
	// {
	// 	nombre: 'Regalii',
	// 	list1: [
	// 		'Antad',
	// 		'Barared (Yomp)',
	// 		'BanRegio',
	// 		'Servytae',
	// 		'Logical Telecom',
	// 		'Yastas-pds',
	// 		'Igt / Vía Servicios',
	// 		'Claropay',
	// 		'AirpakMX',
	// 		'rappi',
	// 		'Nelo-tae',
	// 		'Actinver',
	// 		'Solopin',
	// 		'bnext',
	// 		'Nelo',
	// 		'uala',
	// 		'vepormas',
	// 		'undostres',
	// 		'oyster',
	// 		'wii',
	// 		'ums-pds (Virtual Market)',
	// 		'Bambu',
	// 	],
	// },
	// {
	// 	nombre: 'Datalogic',
	// 	list1: [
	// 		'Antad',
	// 		'Movivendor - New Vision',
	// 		'Indigo - Zion Transaccional',
	// 		'Grupo Walmart (Walmart, Sams, Bodega Aurrerá, Walmart Express)',
	// 		'7 eleven',
	// 		'Farmacias del ahorro',
	// 		'Puntos recarga',
	// 		'Circle k, Extra y K',
	// 		'Farmacias Benavides',
	// 		'Fundación Dondé Banco y Rafael',
	// 		'Multired',
	// 		'Gestopago',
	// 		'Waldo´s y Eleczion',
	// 		'Igt / via servicios',
	// 		'Ganamás',
	// 		'Super kiosko',
	// 		'Multirecargas',
	// 		'Te creemos',
	// 		'El asturiano',
	// 		'Pago rapido',
	// 		'Suburbia',
	// 		'Muebles América',
	// 		'Super fasti',
	// 		'Equipat',
	// 		'Aeroflash (briks epago)',
	// 		'Maxilana',
	// 		'Farmacias bazar',
	// 		'Al Super',
	// 		'Netpay',
	// 		'Quantum capital / epesos',
	// 	],
	// 	list2: [
	// 		'Akala',
	// 		'Abarrotes cordobeses - Super iberia',
	// 		'Farmacia zamora',
	// 		'DNU',
	// 		'ISSSTEZAC',
	// 		'LDI',
	// 		'La argentina',
	// 		'Bancrea',
	// 		'Omer',
	// 		'La mision',
	// 		'Supertiendas rico',
	// 		'Bison / Paynom',
	// 		'Yastás',
	// 		'Banco Base',
	// 		'Insoluciones',
	// 		'TUDI',
	// 		'Unipagos',
	// 		'Diestel corporativos',
	// 		'Fruteria zamora',
	// 		'Mr. Ayudon',
	// 		'Torcam',
	// 		'Petroplus -Yanda',
	// 	],
	// },
	{
		nombre: (
			<>
				Tiendas de autoservicio <br /> y de conveniencia
			</>
		),
		list1Url: [
			{ title: 'Super Fasti', url: 'https://superfasti.com/' },
			{ title: 'Super Kiosko', url: 'https://www.mikiosko.mx/' },
			{ title: 'Merza', url: 'https://merza.com.mx/sucursales/' },
			{ title: 'Alsuper', url: 'https://www.alsuper.com/tiendas' },
			{
				title: 'Abarrotes Cordobeses - Super Iberia',
				url: 'https://www.superiberia.com/sucursales/',
			},
			{
				title: 'Super Aki',
				url: 'https://www.superaki.mx/',
			},
			{
				title: 'Super del Norte',
				url: 'https://www.superdelnorte.com.mx/sucursales',
			},
			{
				title: 'Super Willys',
				url: 'https://www.superwillys.com/sucursales.php',
			},
			{
				title: 'Almacenes Chedraui',
				url: 'https://www.chedraui.com.mx/stores',
			},
			{
				title: 'Selecto Chedraui',
				url: 'https://www.chedraui.com.mx/stores',
			},
			{
				title: 'Súper City',
				url: 'https://www.chedraui.com.mx/stores',
			},
			{
				title: 'Superché',
				url: 'https://www.chedraui.com.mx/stores',
			},
			{
				title: 'Superché',
				url: 'https://www.chedraui.com.mx/stores',
			},
			{
				title: 'Súper Chedraui',
				url: 'https://www.chedraui.com.mx/stores',
			},
			{
				title: 'Soriana Express',
				url: 'https://www.soriana.com/',
			},
			{
				title: 'Soriana Híper',
				url: 'https://www.soriana.com/',
			},
			{
				title: 'Soriana Mercado',
				url: 'https://www.soriana.com/',
			},
			{
				title: 'Soriana Súper',
				url: 'https://www.soriana.com/',
			},
			{
				title: 'Bodega Comercial Mexicana',
				url: 'https://www.soriana.com/',
			},
			{
				title: 'Alprecio',
				url: 'https://www.soriana.com/',
			},
			{
				title: 'City Club',
				url: 'https://www.cityclub.com.mx/',
			},
			{
				title: 'Mega Soriana',
				url: 'https://www.soriana.com/',
			},
			{
				title: 'Fresko',
				url: 'https://www.lacomer.com.mx/',
			},
			{
				title: 'City Market',
				url: 'https://www.lacomer.com.mx/',
			},
			{
				title: 'La Comer',
				url: 'https://www.lacomer.com.mx/',
			},
			{
				title: 'Sumesa',
				url: 'http://sumesa.com.mx/sucursales.php',
			},
			{
				title: 'Tiendas Neto',
				url: 'https://tiendasneto.com.mx/',
			},
			{
				title: 'Walmart',
				url: 'https://www.walmart.com.mx/?gclid=CjwKCAiA7IGcBhA8EiwAFfUDsciHKeNqexR-wAmToqOnoXnselIlc1xQCdeZZ7EFtJSH6OmuG0Pw2xoCZ0IQAvD_BwE',
			},
			{
				title: 'Walmart Express',
				url: 'https://www.walmart.com.mx/?gclid=CjwKCAiA7IGcBhA8EiwAFfUDsciHKeNqexR-wAmToqOnoXnselIlc1xQCdeZZ7EFtJSH6OmuG0Pw2xoCZ0IQAvD_BwE',
			},
			{
				title: 'Bodega Aurrera',
				url: 'https://www.bodegaaurrera.com.mx/inicio',
			},
			{
				title: 'Sams',
				url: 'https://www.sams.com.mx/',
			},
			{
				title: 'El Asturiano',
				url: 'https://www.tiendaselasturiano.com/tiendas/',
			},
			{
				title: 'La Argentina',
				url: 'http://laargentina.mx/sucursales/',
			},
			{
				title: 'Merco',
				url: 'https://www.merco.mx/',
			},
			{
				title: 'La Misión',
				url: 'https://www.lamisionsuper.com/sucursales.php',
			},
			{
				title: 'Circle K',
				url: 'https://www.circlek.com.mx/',
			},
			{
				title: 'Extra',
				url: 'https://www.circlek.com.mx/',
			},
			{
				title: 'K',
				url: 'https://www.circlek.com.mx/',
			},
			{
				title: '7 Eleven',
				url: 'https://www.7-eleven.com.mx/buscador-tiendas/#/stores',
			},
			{
				title: 'Supertiendas Rico',
				url: '#',
				text: true,
			},
		],
	},
	{
		nombre: (
			<>
				Tiendas departamentales y <br /> otras tiendas de cadena
			</>
		),
		list1Url: [
			{
				title: 'Suburbia',
				url: 'https://www.suburbia.com.mx/tienda/browse/storelocator',
			},
			{
				title: 'Famsa',
				url: 'https://www.famsa.com/sucursales',
			},
			{
				title: 'Almacenes Anfora',
				url: 'https://www.almacenesanfora.com/stores/',
			},
			{
				title: 'Office Max',
				url: 'https://www.google.com/maps/d/u/0/viewer?mid=1zg_vtoxbGRpEb5y3TAelC1XfBvAr3rCP&ll=24.69919057681412%2C-101.91799495000002&z=5',
			},
			{
				title: 'Muebles América',
				url: 'https://www.mueblesamerica.mx/tiendas',
			},
			{
				title: 'Modatelas',
				url: 'https://www.modatelas.com.mx/',
			},
			{
				title: 'Waldos y Eleczion',
				url: 'https://waldos.com.mx/#',
			},
		],
	},
	{
		nombre: 'Farmacias',
		list1Url: [
			{
				title: 'Farmacias Bazar',
				url: 'https://farmaciasbazar.com/lista-de-sucursales/',
			},
			{
				title: 'Farmacias Benavides',
				url: 'https://www.benavides.com.mx/sucursales',
			},
			{
				title: 'Farmacias del Ahorro',
				url: 'https://www.fahorro.com/find-a-store.html/',
			},
			{
				title: 'Farmacias Zamora',
				url: 'https://farmaciazamora.com.mx/pages/sucursales',
			},
		],
	},
	{
		nombre: (
			<>
				Instituciones Financieras <br /> y casas de empeño
			</>
		),
		list1Url: [
			{
				title: 'Prestaprenda',
				url: 'https://www.prestaprenda.com/#sucursales',
			},
			{ title: 'Bancrea', url: 'https://www.bancrea.com/' },
			{ title: 'Actinver', url: 'https://www.actinver.com/' },
			{ title: 'Banregio', url: 'https://www.banregio.com/' },
			{
				title: 'Intercam',
				url: 'https://www.intercam.com.mx/personas/banca-personal/pagos/pago-de-servicios-e-impuestos/',
			},
			{
				title: 'Fundación Dondé Banco y Rafael',
				url: 'https://www.dondebanco.com/',
			},
			{ title: 'Banco Base', url: 'https://www.bancobase.com/' },
			{ title: 'Mr. Ayudon', url: 'https://www.mrayudon.mx/' },
			{
				title: 'Vepormas',
				url: 'https://www.vepormas.com/fwpf/portal/documents/sucursales',
			},
			{ title: 'Maxilana', url: 'https://maxilana.com/sucursales' },
			{ title: 'Equipat', url: 'http://www.equipat.com.mx/' },
			{ title: 'Te Creemos', url: 'https://tecreemos.com/' },
			{ title: 'Pago Rápido', url: 'http://www.pagorapido.mx/' },
			{
				title: 'Bankaool',
				url: 'https://www.bankaool.com/sucursales-cajeros.aspx',
			},
			{
				title: 'FINABIEN (antes Telecomm)',
				url: 'https://telecomm.gob.mx/gobmx/sgst/',
			},
		],
	},
	{
		nombre: (
			<>
				Pagos electrónicos <br /> (Internet y APPS)
			</>
		),
		list1Url: [
			{
				title: 'Airpak',
				url: 'https://airpak.com/mx/sucursales-airpak/',
			},
			{
				title: 'Didi',
				url: 'https://web.didiglobal.com/mx/didipay/',
			},
			{
				title: 'Mercado-pago',
				url: 'https://www.mercadopago.com.mx/',
			},
			{
				title: 'Claropay',
				url: 'https://mx.claropay.com/',
			},
			{
				title: 'Unipagos',
				url: 'https://unipagos.com.mx/',
			},
			{
				title: 'Rappi',
				url: 'https://www.rappi.com.mx/',
			},
			{
				title: 'Minu',
				url: 'https://www.minu.mx/',
			},
			{
				title: 'Nelo',
				url: 'https://www.nelo.mx/',
			},
			{
				title: 'Bison / Paynom',
				url: 'https://www.paynom.mx/',
			},
			{
				title: 'Stori',
				url: 'https://www.storicard.com/',
			},
			{
				title: 'TUDI',
				url: 'https://www.tudiapp.com.mx/',
			},
		],
	},
	{
		nombre: 'Plataformas de pago',
		list1Url: [
			{ title: 'Ganamás', url: 'https://ganamas.net/' },
			{ title: 'Antad', url: 'https://antad.net/' },
			{ title: 'Yastas', url: 'https://www.yastas.com/' },
			{ title: 'Gestopago', url: 'https://www.gestopago.com/' },
			{ title: 'MTCenter', url: 'https://www.mtcenter.com.mx/' },
			{ title: 'Netpay', url: 'https://netpay.mx/' },
			{
				title: 'IGT/ Vía Servicios',
				url: 'https://viaservicios.com.mx/',
			},
			{
				title: 'Movivendor - New Visión',
				url: 'https://movivendor.com/',
			},
			{
				title: 'Indigo - Zion Transaccional',
				url: 'https://www.ziontransaccional.com/',
			},
			{
				title: 'Logical Telecom',
				url: 'http://www.logicaltelecom.com/',
			},
			{
				title: 'Barared (Yomp)',
				url: 'https://www.yomp.lat/',
			},
			{
				title: 'Quantum Capital / Epesos',
				url: 'https://www.epesos.com/',
			},
		],
	},
];
