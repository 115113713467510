import { CardBalance } from '../../CardBalance';
import { LabelCardBalance } from '../../LabelCardBalance';
import { SectionBalance } from '../../SectionBalance';

const CasosEspecialesProrrogas = () => {
	return (
		<SectionBalance>
			<CardBalance title="Situación">
				<LabelCardBalance
					label="Durante el periodo que abarca no se exige el pago de
						mensualidad. Recuerda realizar tus pagos al termino de este
						apoyo para que tu deuda no se incremente."
				/>
			</CardBalance>
		</SectionBalance>
	);
};

export default CasosEspecialesProrrogas;
