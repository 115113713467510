import { useState } from 'react';
import CustomButton from '../../../../../components/Button';
import OtherPays from '../../../components/OtherPays';
import { ArrowForwardIosOutlined, PriceChangeOutlined } from '@mui/icons-material';
import { useContextMoment } from '../../../context';
import { BALANCES_MAIN_PAGE } from '../../../utils';
import { useStyles } from '../styles';
import { Link } from '@mui/material';

interface IProps {
	handleChageTab: (arg: number) => void;
}

const ConsultMovements = ({ handleChageTab }: IProps) => {
    const classes = useStyles();
	const [showModalPagos, setShowModalPagos] = useState(false);
    const { setPageMain } = useContextMoment();

	return (
		<>
			{/* Modal de otros pagos */}
			<OtherPays open={showModalPagos} setOpen={setShowModalPagos} />

			<div className={classes.containerFooter}>
				<CustomButton
					variant="outlined"
					classNames={classes.btnOpcionPago}
					label="Otras opciones de pago"
					onClick={() => setShowModalPagos(true)}
					icon={
						<PriceChangeOutlined
							className={classes.PriceChangeOutlined}
						/>
					}
					endIcon={
						<ArrowForwardIosOutlined
							className={classes.arrowForwardIosOutlined}
						/>
					}
				/>
				<div className={classes.labelFooter}>
					Recuerda que tu pago se ve reflejado de 3 a 5 días hábiles
					posteriores a la fecha en que lo efectuaste; consúltalo en
					la sección{' '}
					<Link onClick={() => handleChageTab(1)}>
						Movimientos y estado de cuenta.
					</Link>
				</div>
				<div className={classes.labelFooter}>
					<Link
						onClick={() => setPageMain(BALANCES_MAIN_PAGE.PAYMENT)}
					>
						Consulta los establecimientos en los que puedes realizar
						tus pagos
					</Link>
				</div>
			</div>
		</>
	);
};

export default ConsultMovements;
