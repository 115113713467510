import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface StylesProps {
	firstRow?: boolean;
	lastRow?: boolean;
}

export const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
	container: {
		borderTopLeftRadius: ({ firstRow }) => (firstRow ? 10 : 0),
		borderTopRightRadius: ({ firstRow }) => (firstRow ? 10 : 0),
		borderBottomLeftRadius: ({ lastRow }) => (lastRow ? 10 : 0),
		borderBottomRightRadius: ({ lastRow }) => (lastRow ? 10 : 0),
		height: 60,
		backgroundColor: '#FFFFFF',
		marginBottom: 3,
	},
	labelTitle: {
		fontSize: 18,
		fontWeight: 'bold',
	},
	labelSubTitle: {
		fontSize: 18,
	},
	tooltipIcon: {
		fontSize: '18px !important',
		marginLeft: 4,
	},
	containerLabelTooltip: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		width: '70%',
		justifyContent: 'space-between',
		paddingInline: 15,

		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	containerSubTitle: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		width: '100%',
		paddingInline: 15,
	},
}));
