import { Box } from '@mui/material';
import CustomButton from '../../../../../components/Button';
import CustomAlert from '../../../../../components/CustomAlert';
import { useContextMoment } from '../../../context';
import { BALANCES_MAIN_PAGE, MESSAGES } from '../../../utils';
import HeaderTotalMoney from './HeaderTotalMoney';

const MessagesCode = () => {
	const { balance, setPageMain } = useContextMoment();
	const message =
		balance.propiedades?.mensajes.filter((f) =>
			MESSAGES.MAIN.includes(f.key)
		) || [];
	return (
		<>
			<HeaderTotalMoney />

			<CustomAlert severity="warning" show message={message[0].value} />

			<Box width={200} margin={'30px auto'}>
				<CustomButton
					variant="solid"
					label="Regresar"
					onClick={() => setPageMain(BALANCES_MAIN_PAGE.CREDIT)}
				/>
			</Box>
		</>
	);
};

export default MessagesCode;
