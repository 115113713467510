import {
	Grid,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableBody,
} from '@mui/material';
import { StyledTableCell, StyledTableRow } from '../Style';
import CustomButton from '../../../../../components/Button';
import { BANK_LIST_ENTITY } from '../utils';

interface Props {
	setOpenModal: (avg: boolean) => void;
}

const CardSald = ({ setOpenModal }: Props) => {
	return (
		<Grid container columns={12} spacing={1} style={{ marginTop: 50 }}>
			<Grid item xs={12} md={12}>
				<TableContainer>
					<Table
						sx={{ minWidth: 650, maxWidth: 850 }}
						style={{
							margin: 'auto',
							marginBottom: 20,
							boxShadow: '1px 1px 5px #888888',
						}}
					>
						<TableHead>
							<TableRow>
								<StyledTableCell>Entidad recaudadora</StyledTableCell>
								<StyledTableCell>Clave de Servicio</StyledTableCell>
								<StyledTableCell>Via de Pago</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{BANK_LIST_ENTITY.map((row, index) => (
								<StyledTableRow key={index}>
									<StyledTableCell>{row.entidad}</StyledTableCell>
									<StyledTableCell>{row.clave}</StyledTableCell>
									<StyledTableCell>{row.viaPago}</StyledTableCell>
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
			<Grid item xs={12} md={12}>
				<div style={{ maxWidth: 250, margin: 'auto', marginBottom: 40 }}>
					<CustomButton
						variant="solid"
						onClick={() => setOpenModal(false)}
						label="Regresar"
					/>
				</div>
			</Grid>
		</Grid>
	);
};

export default CardSald;
