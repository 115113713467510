import {
	Dialog,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import TooltipCustom from '../../../../components/TooltipCustom';
import CustomButton from '../../../../components/Button';
import { useContextMoment } from '../../context';
import { useStyles } from './styles';
import { formatStringToDate } from '../../../../utils/dates';
import { formatMoney3 } from '../../../../utils/validators';
import { getVSMFormat } from '../../utils';

interface IProps {
	open: boolean;
	setOpen: (arg: boolean) => void;
}

const OtherPays = ({ open, setOpen }: IProps) => {
	const { balance } = useContextMoment();
	const styles = useStyles();
	return (
		<Dialog open={open} maxWidth={'md'} fullWidth>
			<TableContainer>
				<Table sx={{ minWidth: 650 }} aria-label="TablaDePagos">
					<TableHead className={styles.tableHeader}>
						<TableRow>
							<TableCell width={250}></TableCell>
							<TableCell>Mensualidades</TableCell>
							<TableCell>¿Cuánto debo?</TableCell>
							{balance?.propiedades?.tipoMoneda === 'VSM' && (
								<TableCell>
									VSM
									<TooltipCustom
										title="Es el monto de cuánto debo pero en Veces Salario Mínimo. El valor de VSM es actualizado cada año y entra en vigor en el mes de enero."
										placement="right"
										arrow
									>
										<InfoRoundedIcon
											className={styles.iconInfo}
										/>
									</TooltipCustom>
								</TableCell>
							)}
							<TableCell>¿Cuándo pago?</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>
								<div className={styles.tableCell}>
									Pago para estar al corriente
									<TooltipCustom
										title="Monto de dinero que incluye la mensualidad, pagos atrasados o incompletos y seguros y cuotas."
										placement="right"
										arrow
									>
										<InfoRoundedIcon
											className={styles.iconInfo}
										/>
									</TooltipCustom>
								</div>
							</TableCell>
							<TableCell>
								{balance.modalOpcionesPago?.corriente
									?.mensualidades || '0'}
							</TableCell>
							<TableCell>
								{formatMoney3(
									balance.modalOpcionesPago?.corriente
										?.cuantoDebo || '0'
								)}
							</TableCell>
							{balance?.propiedades?.tipoMoneda === 'VSM' && (
								<TableCell>
									{getVSMFormat(
										balance.modalOpcionesPago?.corriente
											?.cuantoDeboVsm || '0'
									)}
								</TableCell>
							)}
							<TableCell>
								{formatStringToDate(
									balance.modalOpcionesPago?.corriente
										?.cuandoPago || ''
								)}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								<div className={styles.tableCell}>
									Pago de mi mensualidad
									<TooltipCustom
										title="Monto de dinero para pagar el mes actual; incluye seguros y cuotas."
										placement="right"
										arrow
									>
										<InfoRoundedIcon
											className={styles.iconInfo}
										/>
									</TooltipCustom>
								</div>
							</TableCell>
							<TableCell>
								{balance.modalOpcionesPago?.mensualidad
									?.mensualidades || '0'}
							</TableCell>
							<TableCell>
								{formatMoney3(
									balance.modalOpcionesPago?.mensualidad
										?.cuantoDebo || '0'
								)}
							</TableCell>
							{balance?.propiedades?.tipoMoneda === 'VSM' && (
								<TableCell>
									{getVSMFormat(
										balance.modalOpcionesPago?.mensualidad
											?.cuantoDeboVsm || '0'
									)}
								</TableCell>
							)}
							<TableCell>
								{formatStringToDate(
									balance.modalOpcionesPago?.mensualidad
										?.cuandoPago || ''
								)}
							</TableCell>
						</TableRow>
						{balance.modalOpcionesPago?.formaPago && (
							<TableRow>
								<TableCell>
									<div className={styles.tableCell}>
										Forma de pago
										<TooltipCustom
											title="Medio por el cual el acreditado hace sus pagos. Sin relación laboral lo puede hacer en bancos y establecimientos autorizados por el Infonavit. Cuando sí hay una relación de trabajo formal, el patrón hace la retención y la entrega al Instituto."
											placement="right"
											arrow
										>
											<InfoRoundedIcon
												className={styles.iconInfo}
											/>
										</TooltipCustom>
									</div>
								</TableCell>
								<TableCell colSpan={4} align="center">
									{balance.modalOpcionesPago?.formaPago || ''}
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<CustomButton
				label={'Cerrar'}
				onClick={() => setOpen(false)}
				variant="solid"
				classNames={styles.buttonCerrar}
			/>
		</Dialog>
	);
};

export default OtherPays;
