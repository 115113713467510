import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',

		'& > label': {
			textAlign: 'center',
			width: 200,
			color: '#E51D76',

			[theme.breakpoints.down('md')]: {
				width: 'auto',
			},
		},

		[theme.breakpoints.down('md')]: {
			marginTop: 10,
			flexDirection: 'column',
			justifyContent: 'center',
		},

		[theme.breakpoints.down('sm')]: {
			marginTop: 20,
		},
	},

	btnPresupuesto: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		gap: '10px',
		color: '#fff',
		background: '#E51D76',
		padding: '5px 0px',
		borderRadius: '120px',
        cursor: 'pointer',
        width: 280,

        '& > div': {
            display: 'flex',
            flexDirection: 'column',
        },
		'& > div > label': {
			margin: 0,
            fontWeight: 500,
            fontSize: 15,
            cursor: 'pointer',
		},
		'& > div > p': {
			margin: 0,
			fontWeight: 300,
            textAlign: 'center',
            fontSize: 15,
		},
	},
}));
