import LabelStatus from './label-status.component';
import PlayButton from './play-button.component';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import { useStyles } from './styles/card-video-game.style';
import { Elemento } from '../../../../../interfaces/tallerSaberMas';

interface IProps {
	isActive?: boolean;
	elemento?: Elemento;
	setElemento?: (arg: Elemento) => void;
}

const CardVideoGame = ({ elemento, isActive, setElemento }: IProps) => {
	const styles = useStyles();
	return (
		<div className={styles.containerCard}>
			<div className={styles.containerHeaderCard}>
				<div className={styles.containerHeaderCardInfo}>
					<label className={styles.containerHeaderCardLabelTitle}>
						Videojuego Recomendaciones para elegir vivienda
					</label>
				</div>
			</div>
			<div className={styles.containerHeaderCard}>
				<div className={styles.containerHeaderCardButtons}>
					<PlayButton
						isComplete={elemento?.estatus === 1}
						label="Jugar"
						isPlaying={isActive}
						IconCustom={SportsEsportsIcon}
						onClick={() =>
							setElemento && elemento && setElemento(elemento)
						}
					/>
					<LabelStatus
						variantLabel={
							elemento?.estatus === 0
								? 'pending'
								: elemento?.estatus === 1
								? 'completed'
								: 'updated'
						}
					/>
				</div>
			</div>
		</div>
	);
};

export default CardVideoGame;
